import { useState, useEffect } from 'react'
import './ErrorMessage.scss'
import Modal from '../Modal/Modal.js'
import Button from '../Button/Button.js'

const ErrorMessage = () => {
  const [isOpen, setOpen] = useState(false)
  const [message, setMessage] = useState('')

  useEffect(() => {
    function handleEvent (e) {
      setMessage(e.detail)
      setOpen(true)
    }
    window.addEventListener('showErrorMessage', handleEvent)
    return () => window.removeEventListener('showErrorMessage', handleEvent)
  }, [])

  function handleModalClose () {
    setOpen(false)
  }

  return (
    <Modal open={isOpen} closeModal={handleModalClose} title="Foutmelding">
      <div className="ErrorMessage">
        <div className="ErrorMessage-modal-error"><p>{message || ''}</p></div>
        <p>Contacteer Quid Pro Quo indien u verder geholpen wenst te worden.</p>
        <div className="ErrorMessage-modal-buttons">
          <Button onClick={handleModalClose}>Sluiten</Button>
        </div>
      </div>
    </Modal>
  )
}

export default ErrorMessage
