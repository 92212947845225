import getBrands from './getBrands.js'
import getFuels from './getFuels.js'
import getModels from './getModels.js'
import getVersions from './getVersions.js'
import getResidualvalue from './getResidualvalue.js'
import postResidualvalue from './postResidualvalue.js'
import postResidualvalueAnalyse from './postResidualvalueAnalyse.js'
import postResidualvalueById from './postResidualvalueById.js'
import login from './login.js'
import getCarInformation from './getCarInformation.js'
import getCataloguePrice from './getCataloguePrice.js'
import getVersionPrice from './getVersionPrice.js'
import getMarketAnalysis from './getMarketAnalysis.js'
import getMarketpricehistory from './getMarketpricehistory.js'
import getJatoBrands from './getJatoBrands.js'
import getJatoModels from './getJatoModels.js'
import getJatoFacets from './getJatoFacets.js'

const api = {
  getBrands,
  getFuels,
  getModels,
  getVersions,
  getResidualvalue,
  postResidualvalue,
  postResidualvalueAnalyse,
  postResidualvalueById,
  login,
  getCarInformation,
  getCataloguePrice,
  getVersionPrice,
  getMarketAnalysis,
  getMarketpricehistory,
  getJatoBrands,
  getJatoModels,
  getJatoFacets
}

export default api
