import { useEffect, useRef } from 'react'

function useHasUnmounted (initial) {
  const hasUnmountedRef = useRef(initial)

  useEffect(() => {
    hasUnmountedRef.current = false
    return () => {
      hasUnmountedRef.current = true
    }
  }, [])

  return hasUnmountedRef
}

export default useHasUnmounted
