import PropTypes from 'prop-types'

const icons = {
  expand: props => <svg width="1em" height="1em" viewBox="0 0 15.053 14.874" {...props}>
    <path d="M14.402 0a.583.583 0 00-.071.006H9.347a.632.632 0 000 1.265h3.541l-3.979 3.98a.633.633 0 00.894.895l3.979-3.979v3.54a.632.632 0 001.265 0V.717a.622.622 0 00.006-.084A.632.632 0 0014.42 0h-.017zM.623 8.545A.633.633 0 000 9.177V14.3a.064.064 0 010 .01c0 .019 0 .035.007.051a.2.2 0 000 .021.638.638 0 00.06.166l.01.017.026.043.01.015a.65.65 0 00.122.131h.006a.629.629 0 00.376.135H5.7a.632.632 0 100-1.265H2.159L6.138 9.63a.632.632 0 00-.441-1.085h-.018a.631.631 0 00-.435.19l-3.979 3.98v-3.54a.633.633 0 00-.632-.632H.624z" />
  </svg>,
  shrink: props => <svg width="1em" height="1em" viewBox="0 0 15.401 15.586" {...props}>
    <path d="M5.918 8.992a.606.606 0 00-.073.006H.659a.658.658 0 00-.658.658.658.658 0 00.658.658h3.684l-4.14 4.14a.655.655 0 00-.192.464.658.658 0 00.658.658.657.657 0 00.464-.192l4.14-4.14v3.684a.658.658 0 00.658.658.658.658 0 00.658-.658V9.736a.648.648 0 00.006-.087.658.658 0 00-.658-.658h-.018zM9.461.002a.659.659 0 00-.649.658v5.329a.066.066 0 010 .011c0 .019 0 .036.007.053a.206.206 0 000 .022.664.664 0 00.063.173l.01.018.027.045.01.015a.676.676 0 00.127.137l.006.005a.654.654 0 00.391.14h5.288a.658.658 0 00.658-.658.658.658 0 00-.658-.658h-3.684l4.139-4.14a.657.657 0 00.2-.471.658.658 0 00-.658-.658h-.018a.657.657 0 00-.452.2l-4.14 4.141V.658A.658.658 0 009.47 0h-.009z" />
  </svg>,
  belgium: props => <svg width="1em" height="1em" viewBox="0 0 35 23" {...props}>
    <path d="M0 0h35v23H0z" />
    <path fill="#fae042" d="M12 0h11v23H12z" />
    <path fill="#ed2939" d="M23 0h12v23H23z" />
  </svg>,
  netherlands: props => <svg width="1em" height="1em" viewBox="0 0 35 23" {...props}>
    <path fill="#fff" d="M0 0h35v23H0z" />
    <path fill="#087cc4" d="M35 15v8H0v-8z" />
    <path fill="#ed2939" d="M35 0v8H0V0z" />
  </svg>,
  phone: props => <svg width="1em" height="1em" viewBox="0 0 18 18" {...props}>
    <path d="M.801 2.067a3.169 3.169 0 00-.26 2.6 16.4 16.4 0 004.312 7.04 16.4 16.4 0 007.04 4.312 3.172 3.172 0 002.6-.26 5.842 5.842 0 001.854-2.5c.278-.718-1.831-1.963-2.189-2.126a11.842 11.842 0 00-2.055-.85.794.794 0 00-.846.39c-.2.327-.442 1.072-.667 1.383a.881.881 0 01-1.251.184 15.358 15.358 0 01-2.735-2.283A15.4 15.4 0 014.326 7.22a.881.881 0 01.183-1.25c.31-.226 1.057-.463 1.382-.667a.793.793 0 00.39-.846 11.838 11.838 0 00-.849-2.055C5.27 2.042 4.024-.067 3.305.212a5.859 5.859 0 00-2.5 1.855H.801z" />
  </svg>,
  info: props => <svg width="1em" height="1em" viewBox="0 0 28 28" {...props}>
    <path d="M17.832 11.366c0 2.084-1.653 3.583-2.448 4.311l-.23.206a1.532 1.532 0 00-.2.766.958.958 0 01-1.916 0 3.2 3.2 0 01.762-2.112c.072-.077.172-.168.292-.278.594-.532 1.82-1.643 1.82-2.874A1.8 1.8 0 0014 9.471a1.8 1.8 0 00-1.916 1.916.958.958 0 01-1.916 0A3.711 3.711 0 0114 7.555a3.712 3.712 0 013.832 3.813zm-3.3 7.348a1.087 1.087 0 00-.163-.091 1.057 1.057 0 00-.182-.053.957.957 0 00-1.145.958.812.812 0 000 .187 1.057 1.057 0 00.053.182 1.086 1.086 0 00.091.163 1.144 1.144 0 00.115.149.957.957 0 00.7.259.67.67 0 00.369-.053.776.776 0 00.163-.091.731.731 0 00.263-.263.776.776 0 00.091-.163.67.67 0 00.053-.369.957.957 0 00-.278-.68 1.141 1.141 0 00-.129-.134zm11.922-4.713a12.454 12.454 0 11-3.648-8.807 12.458 12.458 0 013.648 8.807zm-1.916 0a10.536 10.536 0 10-3.086 7.453 10.543 10.543 0 003.086-7.453z" />
  </svg>,
  cross: props => <svg width="1em" height="1em" viewBox="0 0 32.331 32.331" {...props}>
    <path d="M23.005 6.84l-6.839 6.839-6.84-6.84L6.84 9.326l6.84 6.84-6.84 6.839 2.487 2.487 6.839-6.84 6.84 6.84 2.486-2.487-6.84-6.84 6.84-6.839z" />
  </svg>,
  caretDown: props => <svg width="1em" height="1em" viewBox="0 0 7.173 4" {...props}>
    <path d="M3.586 4l.394-.337L7.173.927 6.384.001l-2.8 2.4-2.8-2.4-.789.926 3.198 2.736z" />
  </svg>,
  caretRight: props => <svg width="1em" height="1em" viewBox="0 0 8.875 13.916" {...props}>
    <path d="M8.875 6.957l-.748-.875L2.057 0 .002 1.75l5.323 5.208-5.323 5.208 2.055 1.749 6.07-6.083z" />
  </svg>,
  check: props => <svg width="1em" height="1em" viewBox="0 0 16.369 12.549" {...props}>
    <path d="M0 6.912l1.818-2.365 4.729 4 7.639-8.548 2.182 2-9.639 10.55z" />
  </svg>,
  danger: props => <svg width="1em" height="1em" viewBox="0 0 21.984 20.627" {...props}>
    <path d="M21.653 16.627l-8.3-15.215a2.716 2.716 0 00-4.764 0L.333 16.627a2.71 2.71 0 00.046 2.683 2.668 2.668 0 002.336 1.318h16.533a2.721 2.721 0 002.405-4zm-11.677-9.9a1.368 1.368 0 01.994-.439 1.343 1.343 0 011.341 1.341v.185l-.694 5.55a1.99 1.99 0 00-.648-.092 1.876 1.876 0 00-.624.092l-.694-5.55a1.487 1.487 0 01.323-1.088zm.994 10.314a1.341 1.341 0 111.341-1.339 1.313 1.313 0 01-1.342 1.341z" />
  </svg>,
  download: props => <svg width="1em" height="1em" viewBox="0 0 17.972 17.972" {...props}>
    <path d="M2.061 11.965v3.948h13.851v-3.948a1.03 1.03 0 112.06 0v4.082a1.927 1.927 0 01-1.926 1.926H1.926A1.928 1.928 0 010 16.047v-4.082a1.03 1.03 0 012.06 0z" />
    <path d="M9.714 12.173l4.226-4.226a1.03 1.03 0 00-1.456-1.456l-2.433 2.433V1.03a1.03 1.03 0 00-2.06 0v7.963l-2.5-2.5a1.031 1.031 0 10-1.459 1.456l4.226 4.226a1.029 1.029 0 001.457 0z" />
  </svg>,
  upload: props => <svg width="1em" height="1em" viewBox="0 0 38.802 38.803" {...props}>
    <path d="M4.449 25.833v8.523h29.906v-8.523a2.224 2.224 0 014.447 0v8.813a4.161 4.161 0 01-4.158 4.158H4.159A4.163 4.163 0 010 34.646v-8.813a2.224 2.224 0 014.449 0z" />
    <path d="M17.829.653L8.706 9.776a2.223 2.223 0 003.143 3.146l5.253-5.252v17.042a2.224 2.224 0 004.447 0V7.519l5.4 5.4a2.225 2.225 0 103.148-3.144L20.974.652a2.221 2.221 0 00-3.145 0z" />
  </svg>,
  mail: props => <svg width="1em" height="1em" viewBox="0 0 20.342 15.256" {...props}>
    <path d="M10.864 11.221a1.219 1.219 0 01-.243.116 1.251 1.251 0 01-.214.072 1.234 1.234 0 01-.249.013 1.281 1.281 0 01-.234-.014 1.191 1.191 0 01-.224-.078 1.248 1.248 0 01-.231-.114.658.658 0 01-.072-.035L0 3.995v9.99a1.271 1.271 0 001.271 1.273h17.8a1.271 1.271 0 001.271-1.271V4.123l-9.409 7.065c-.021.016-.047.018-.068.033z" />
    <path d="M20.342 1.271A1.271 1.271 0 0019.07 0H1.271A1.271 1.271 0 000 1.271l10.17 7.84z" />
  </svg>,
  priceSearch: props => <svg width="1em" height="1em" viewBox="0 0 30 30" {...props}>
    <g>
      <path d="M29.478 27.043l-9.042-9.044a11.025 11.025 0 002.173-6.7A11.259 11.259 0 1018 20.431l9.044 9.044a1.682 1.682 0 002.436 0 1.683 1.683 0 000-2.436zM11.305 19.13a7.826 7.826 0 117.826-7.826 7.793 7.793 0 01-7.826 7.826z" />
      <path
        d="M11.838 7.244a4.125 4.125 0 012.131.591.659.659 0 00.346.083.671.671 0 00.589-.324.682.682 0 00-.239-.936 5.56 5.56 0 00-2.827-.781 5.642 5.642 0 00-5.156 3.371h-1a.683.683 0 000 1.366h.606a5.634 5.634 0 00-.092.9 5.541 5.541 0 00.067.666h-.581a.683.683 0 100 1.366h.907a5.638 5.638 0 005.248 3.611 5.561 5.561 0 002.788-.757.685.685 0 00.249-.935.719.719 0 00-.934-.247 4.122 4.122 0 01-2.1.573 4.261 4.261 0 01-3.741-2.24h2.981a.683.683 0 000-1.366H7.631a3.743 3.743 0 01.033-1.569h3.418a.683.683 0 100-1.366H8.231a4.265 4.265 0 013.607-2"
        fillRule="evenodd"
      />
    </g>
  </svg>,
  sortDown: props => <svg width="1em" height="1em" viewBox="0 0 24 24" {...props}>
    <path d="M6.5 19.7499C6.31 19.7499 6.12 19.6799 5.97 19.5299L3.47 17.0299C3.18 16.7399 3.18 16.2599 3.47 15.9699C3.76 15.6799 4.24 15.6799 4.53 15.9699L6.5 17.9399L8.47 15.9699C8.76 15.6799 9.24 15.6799 9.53 15.9699C9.82 16.2599 9.82 16.7399 9.53 17.0299L7.03 19.5299C6.88 19.6799 6.69 19.7499 6.5 19.7499Z"/>
    <path d="M6.5 19.75C6.09 19.75 5.75 19.41 5.75 19V5C5.75 4.59 6.09 4.25 6.5 4.25C6.91 4.25 7.25 4.59 7.25 5V19C7.25 19.41 6.91 19.75 6.5 19.75Z"/>
    <path d="M20 17.25H12C11.59 17.25 11.25 16.91 11.25 16.5C11.25 16.09 11.59 15.75 12 15.75H20C20.41 15.75 20.75 16.09 20.75 16.5C20.75 16.91 20.41 17.25 20 17.25Z"/>
    <path d="M16 11.25H12C11.59 11.25 11.25 10.91 11.25 10.5C11.25 10.09 11.59 9.75 12 9.75H16C16.41 9.75 16.75 10.09 16.75 10.5C16.75 10.91 16.41 11.25 16 11.25Z"/>
    <path d="M14 8.25H12C11.59 8.25 11.25 7.91 11.25 7.5C11.25 7.09 11.59 6.75 12 6.75H14C14.41 6.75 14.75 7.09 14.75 7.5C14.75 7.91 14.41 8.25 14 8.25Z"/>
    <path d="M18 14.25H12C11.59 14.25 11.25 13.91 11.25 13.5C11.25 13.09 11.59 12.75 12 12.75H18C18.41 12.75 18.75 13.09 18.75 13.5C18.75 13.91 18.41 14.25 18 14.25Z"/>
  </svg>,
  sortUp: props => <svg width="1em" height="1em" viewBox="0 0 24 24" {...props}>
    <path d="M9 8.24994C8.81 8.24994 8.62 8.17994 8.47 8.02994L6.5 6.05994L4.53 8.02994C4.24 8.31994 3.76 8.31994 3.47 8.02994C3.18 7.73994 3.18 7.25994 3.47 6.96994L5.97 4.46994C6.26 4.17994 6.74 4.17994 7.03 4.46994L9.53 6.96994C9.82 7.25994 9.82 7.73994 9.53 8.02994C9.38 8.17994 9.19 8.24994 9 8.24994Z"/>
    <path d="M6.5 19.75C6.09 19.75 5.75 19.41 5.75 19V5C5.75 4.59 6.09 4.25 6.5 4.25C6.91 4.25 7.25 4.59 7.25 5V19C7.25 19.41 6.91 19.75 6.5 19.75Z"/>
    <path d="M20 17.25H12C11.59 17.25 11.25 16.91 11.25 16.5C11.25 16.09 11.59 15.75 12 15.75H20C20.41 15.75 20.75 16.09 20.75 16.5C20.75 16.91 20.41 17.25 20 17.25Z"/>
    <path d="M16 11.25H12C11.59 11.25 11.25 10.91 11.25 10.5C11.25 10.09 11.59 9.75 12 9.75H16C16.41 9.75 16.75 10.09 16.75 10.5C16.75 10.91 16.41 11.25 16 11.25Z"/>
    <path d="M14 8.25H12C11.59 8.25 11.25 7.91 11.25 7.5C11.25 7.09 11.59 6.75 12 6.75H14C14.41 6.75 14.75 7.09 14.75 7.5C14.75 7.91 14.41 8.25 14 8.25Z"/>
    <path d="M18 14.25H12C11.59 14.25 11.25 13.91 11.25 13.5C11.25 13.09 11.59 12.75 12 12.75H18C18.41 12.75 18.75 13.09 18.75 13.5C18.75 13.91 18.41 14.25 18 14.25Z"/>
  </svg>,
  sort: props => <svg width="1em" height="1em" viewBox="0 0 24 24" {...props}>
    <path d="M10.5 8.24994C10.31 8.24994 10.12 8.17994 9.97 8.02994L8 6.05994L6.03 8.02994C5.74 8.31994 5.26 8.31994 4.97 8.02994C4.68 7.73994 4.68 7.25994 4.97 6.96994L7.47 4.46994C7.76 4.17994 8.24 4.17994 8.53 4.46994L11.03 6.96994C11.32 7.25994 11.32 7.73994 11.03 8.02994C10.88 8.17994 10.69 8.24994 10.5 8.24994Z"/>
    <path d="M8 19.75C7.59 19.75 7.25 19.41 7.25 19V5C7.25 4.59 7.59 4.25 8 4.25C8.41 4.25 8.75 4.59 8.75 5V19C8.75 19.41 8.41 19.75 8 19.75Z"/>
    <path d="M16 19.7499C15.81 19.7499 15.62 19.6799 15.47 19.5299L12.97 17.0299C12.68 16.7399 12.68 16.2599 12.97 15.9699C13.26 15.6799 13.74 15.6799 14.03 15.9699L16 17.9399L17.97 15.9699C18.26 15.6799 18.74 15.6799 19.03 15.9699C19.32 16.2599 19.32 16.7399 19.03 17.0299L16.53 19.5299C16.38 19.6799 16.19 19.7499 16 19.7499Z"/>
    <path d="M16 19.75C15.59 19.75 15.25 19.41 15.25 19V5C15.25 4.59 15.59 4.25 16 4.25C16.41 4.25 16.75 4.59 16.75 5V19C16.75 19.41 16.41 19.75 16 19.75Z"/>
  </svg>
}

const Icon = ({ name, ...props }) => {
  const Component = icons[name]
  if (!Component) return null
  return <Component {...props} />
}

Icon.propTypes = {
  name: PropTypes.oneOf(['expand', 'shrink', 'belgium', 'netherlands', 'phone', 'info', 'cross', 'caretDown', 'caretRight', 'check', 'danger', 'download', 'upload', 'mail', 'priceSearch', 'sort', 'sortUp', 'sortDown'])
}

export default Icon
