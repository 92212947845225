import './PriceDifferenceInPercentage.scss'

const getSign = (number) => {
  if (number > 0) {
    return '+'
  } else {
    return ''
  }
}
const getColor = (number) => {
  if (number === 0) {
    return ''
  } else if (number > 0) {
    return 'red'
  } else {
    return 'green'
  }
}

const PriceDifferenceInPercentage = ({ price, advicedPrice, className = '' }) => {
  if (!price || !advicedPrice || advicedPrice === 0 || price === 0) return ''
  const priceDiff = Math.round(((price * 100) / advicedPrice - 100) * 100) / 100
  const priceDiffSign = getSign(priceDiff)
  const priceDiffColor = getColor(priceDiff)

  return (<span className={`PriceDifferenceInPercentage ${className} ${priceDiffColor}`}>{priceDiffSign}{priceDiff}%</span>)
}

export default PriceDifferenceInPercentage
