class Store {
  constructor (initialState) {
    this.state = initialState || null
    this.subscriptions = new Set()
  }

  subscribe (callback) {
    const currentSubscriptions = new Set(this.subscriptions)
    currentSubscriptions.add(callback)
    this.subscriptions = currentSubscriptions
    return () => this.unsubscribe(callback)
  }

  unsubscribe (callback) {
    const currentSubscriptions = new Set(this.subscriptions)
    currentSubscriptions.delete(callback)
    this.subscriptions = currentSubscriptions
  }

  emitUpdateEvent () {
    [...this.subscriptions].forEach(fn => fn())
  }

  getState () {
    return this.state
  }

  updateState (newValues) {
    this.state = {
      ...this.state,
      ...newValues
    }
    this.emitUpdateEvent()
  }
}

export default Store
