/* eslint-disable no-unused-vars */
import { useState, useEffect } from 'react'
import './InmotivModal.scss'
import Button from '../../../../components/Button/Button.js'
import Modal from '../../../../components/Modal/Modal.js'
import Input from '../../../../components/Input/Input.js'
import SearchSelect from '../../../../components/SearchSelect/SearchSelect.js'
import { getInitialDate } from '../../AdHoc.js'
import api from '../../../../api/api.js'

function translateFuel (name) {
  switch (name) {
    case 'BENZINE': {
      return 'Benzine'
    }
    case 'DIESEL': {
      return 'Diesel'
    }
    case 'ELEKTRISCH': {
      return 'Electrisch'
    }
    case 'GAS': {
      return 'Gas'
    }
    default:
      return name
  }
}

const InmotivModal = ({ isOpen, onCloseModal, setValues, currentValues }) => {
  const [brands, setBrands] = useState([])
  const [models, setModels] = useState([])
  const [versions, setVersions] = useState([])
  const [selection, setSelection] = useState({})
  const [exsid, setExsid] = useState()

  // reset to default values
  useEffect(() => {
    if (!isOpen) {
      setSelection({
        modelMonth: 1
      })
      setBrands([])
      setModels([])
      setVersions([])
      setExsid()
    }
  }, [isOpen])

  useEffect(() => {
    const getBrands = async function () {
      try {
        const { data: inmotivBrands, exsid: newExsid } = await api.getBrands({
          country: 'BE',
          modelyear: selection.modelYear,
          modelmonth: selection.modelMonth,
          source: 'inmotiv'
        }).json()
        if (inmotivBrands && Array.isArray(inmotivBrands)) {
          const cleanedInmotivBrands = inmotivBrands.map(brand => brand.name)
          setBrands(inmotivBrands)
        }
        if (newExsid) {
          setExsid(newExsid)
        }
      } catch (error) {
        console.error(error)
      }
    }

    const getModels = async function () {
      try {
        const { data: inmotivModels } = await api.getModels({
          country: 'BE',
          modelyear: selection.modelYear,
          modelmonth: selection.modelMonth,
          source: 'inmotiv',
          brand: selection.brand.id,
          exsid
        }).json()
        if (inmotivModels && Array.isArray(inmotivModels)) {
          setModels(inmotivModels)
        }
      } catch (error) {
        console.error(error)
      }
    }

    const getVersions = async function () {
      try {
        const { data: inmotivVersions } = await api.getVersions({
          country: 'BE',
          modelyear: selection.modelYear,
          modelmonth: selection.modelMonth,
          source: 'inmotiv',
          brand: selection.brand.id,
          model: selection.model.id,
          exsid
        }).json()
        if (inmotivVersions && Array.isArray(inmotivVersions)) {
          setVersions(inmotivVersions)
        }
      } catch (error) {
        console.error(error)
      }
    }
    if (selection.modelYear && selection.modelMonth && !selection.brand && !exsid) {
      getBrands()
    } else if (selection.brand && !selection.model) {
      getModels()
    } else if (selection.brand && selection.model && !selection.version) {
      getVersions()
    }
  }, [selection, exsid])

  function handleModelyearChange (e) {
    const value = e.target.value
    const newSelection = {
      ...(selection.modelMonth && { modelMonth: selection.modelMonth }),
      modelYear: value
    }
    setBrands([])
    setModels([])
    setVersions([])
    setSelection(newSelection)
  }

  function handleModelmonthChange (e) {
    const value = e.target.value
    const newSelection = {
      ...(selection.modelYear && { modelYear: selection.modelYear }),
      modelMonth: value
    }
    setBrands([])
    setModels([])
    setVersions([])
    setSelection(newSelection)
  }

  function handleBrandChange (value) {
    const brand = brands.find(b => b.name === value)
    const newSelection = {
      ...(selection.modelYear && { modelYear: selection.modelYear }),
      ...(selection.modelMonth && { modelMonth: selection.modelMonth }),
      brand
    }
    setVersions([])
    setSelection(newSelection)
  }

  function handleModelChange (value) {
    const model = models.find(b => b.name === value)
    const newSelection = {
      ...(selection.modelYear && { modelYear: selection.modelYear }),
      ...(selection.modelMonth && { modelMonth: selection.modelMonth }),
      ...(selection.brand && { brand: selection.brand }),
      model
    }
    setSelection(newSelection)
  }

  function handleVersionClick (version) {
    const newSelection = {
      ...(selection.modelYear && { modelYear: selection.modelYear }),
      ...(selection.modelMonth && { modelMonth: selection.modelMonth }),
      ...(selection.brand && { brand: selection.brand }),
      ...(selection.model && { model: selection.model }),
      version
    }
    setSelection(newSelection)
  }

  function handleConfirmClick () {
    if (!selection.version) return

    const getVersionPrice = async () => {
      const { price } = await api.getVersionPrice({
        country: 'BE',
        modelyear: selection.modelYear,
        modelmonth: selection.modelMonth,
        source: 'inmotiv',
        brand: selection.brand.id,
        model: selection.model.id,
        version: selection.version.id,
        exsid
      }).json()

      let cc = selection.version?.cilinderCapacityL ? parseFloat(selection.version.cilinderCapacityL) : ''
      cc = !isNaN(cc) ? (cc * 1000).toFixed(0) : ''

      const prijs = price ? Math.round(price * 1.21) : ''
      const vat = 'in'
      const opties = (prijs !== '') ? Math.round(prijs * 0.08) : ''
      const totaalPrijs = (opties !== '') ? prijs + opties : ''
      const brandstof = selection.version?.fuel?.name || ''

      setValues({
        merk: selection.brand?.name || '',
        model: selection.model?.name || '',
        uitvoering: selection.version?.name || '',
        brandstof: translateFuel(brandstof),
        registratie: '',
        km: '',
        cc: cc + '',
        pk: (selection.version?.hp || '') + '',
        co2: '',
        modelJaarMonth: selection.modelMonth || '',
        modelJaarYear: selection.modelYear || '',
        modelJaar: selection.modelYear && selection.modelMonth ? `${selection.modelMonth}-${selection.modelYear}` : '',
        prijs: prijs + '',
        opties: opties + '',
        totaalPrijs: totaalPrijs + '',
        vat,
        transmissie: '',
        aanvang: getInitialDate(),
        duur: '',
        kmAanvang: '',
        kmJaar: '',
        periode: '2',
        pabw: 'passengercar',
        exsid,
        exs: 'INMOTIVMMU'
      })
      onCloseModal()
    }
    getVersionPrice()
  }

  return (
    <Modal open={isOpen} closeModal={onCloseModal} title="Autoconnect CARWIZARD">
        <div className="InmotivModal">
          <div className="InmotivModal-form">
            {/* <Input enableNumberFormat={false} style={{ minWidth: 68, maxWidth: 120 }} label="Modeljaar maand" placeholder="mm" type="number" min={1} max={12} value={selection.modelMonth} onChange={handleModelmonthChange} valid={!!selection.modelMonth} /> */}
            <Input enableNumberFormat={false} style={{ minWidth: 68, maxWidth: 120 }} label="Modeljaar" placeholder="jjjj" type="number" min={1900} max={2100} value={selection.modelYear} onChange={handleModelyearChange} valid={!!selection.modelYear} />
            <hr/>
            <SearchSelect label="Merk" placeholder="Merk" options={brands.map(b => b.name)} value={selection.brand?.name} onChange={handleBrandChange} disabled={!selection.modelMonth && !selection.modelYear}/>
            <hr/>
            <SearchSelect label="Model" placeholder="Model" options={models.map(m => m.name)} value={selection.model?.name} onChange={handleModelChange} disabled={!selection.modelMonth && !selection.modelYear && selection.brand !== ''}/>
          </div>
          <div className="InmotivModal-results">
            <div id="list-scroll">
              <div className="InmotivModal-results-list">
                {
                  versions.map(item => (
                    <div className={`InmotivModal-result-item ${item.id === selection.version?.id ? 'InmotivModal-result-item-selected' : ''}`} key={item.id} onClick={() => handleVersionClick(item)}>
                      <div>{selection.brand?.name} - {selection.model?.name} - {item.modelYear} - {item.name} - {item.doors} doors - {translateFuel(item.fuel.name)} - {item.hp} hp</div>
                    </div>
                  ))
                }
              </div>
            </div>
            <div className="InmotivModal-results-buttons">
              <Button ghost={true} onClick={onCloseModal}>Annuleren</Button>
              <Button onClick={handleConfirmClick}>Kies</Button>
            </div>
          </div>
        </div>
    </Modal>
  )
}

export default InmotivModal
