import Header from '../../components/Header/Header.js'
import getBaseUrl from '../../utils/getBaseUrl.js'
import './Faq.scss'

const Faq = () => {
  return (
    <div className='Faq'>
      <Header page='Faq' />
      <div className='Faq-body'>
        <div className='Faq-body-section'>
          <div className='Faq-body-center'>
            <h2>faq</h2>
            <ul>
              <li>
                <a href={getBaseUrl() + '/files/FVS_Manual_2024.pdf'} target='_blank' rel='noreferrer'>FVS Manual: Algemene werking FVS</a>
              </li>
              <li>
                <a href={getBaseUrl() + '/files/FVS_Manual_2024_EXS04.pdf'} target='_blank' rel='noreferrer'>EXS04: voertuigherkenning obv Nederlands kenteken via RDW</a>
              </li>
              <li>
                <a href={getBaseUrl() + '/files/FVS_Manual_2024_EXS05.pdf'} target='_blank' rel='noreferrer'>EXS05:voertuigherkenning obv VIN van beschikbare voertuigen binnen RWB</a>
              </li>
              <li>
                <a href={getBaseUrl() + '/files/FVS_Manual_2024_EXS07.pdf'} target='_blank' rel='noreferrer'>EXS07: Inschatting consumentenprijs obv voertuigspecificaties</a>
              </li>
              <li>
                <a href={getBaseUrl() + '/files/FVS_Manual_2024_EXS11.pdf'} target='_blank' rel='noreferrer'>EXS11: Inschatting gemiddelde prijs opties obv voertuigspecificaties</a>
              </li>
              <li>
                <a href={getBaseUrl() + '/files/RV Matrix API F03092024.pdf'} target='_blank' rel='noreferrer'>RWB API Matrix Solution: Matrix & Allocatie-Offset calculaties</a>
              </li>
            </ul>
          </div>
        </div>
      </div>

    </div>
  )
}

export default Faq
