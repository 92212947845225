import Home from './pages/Home/Home.js'
import AdHoc from './pages/AdHoc/AdHoc.js'
import Batch from './pages/Batch/Batch.js'
import Faq from './pages/Faq/Faq.js'

const routes = [
  {
    path: '/',
    action: () => <Home />
  },
  {
    path: '/adhoc',
    action: () => <AdHoc />
  },
  {
    path: '/batch',
    action: () => <Batch />
  }, {
    path: '/faq',
    action: () => <Faq />
  }
]

export default routes
