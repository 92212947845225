/* eslint-disable no-unused-vars */
import { useState } from 'react'
import Spinner from '../../../../components/Spinner/Spinner.js'
import formatNumber from '../../../../utils/formatNumber.js'
import convertStringToDate from '../convertStringToDate.js'
import getAge from '../getAge.js'
import useCountry from '../../../../hooks/useCountry.js'
import MarketList from './MarketList.js'
import PriceHistory from './PriceHistory/PriceHistory.js'
import PriceAnalytics from './PriceAnalytics/PriceAnalytics.js'
import MarketLocation from './MarketLocation/MarketLocation.js'
import './MarketAnalysis.scss'

const MarketAnalysis = ({ results, error, fleetStatus }) => {
  const [country] = useCountry('be')
  const userInput = results.userinput || {}
  const data = results.market || {}
  const age = getAge(convertStringToDate(userInput.firstregistrationdate), new Date(), 1)
  const [view, setView] = useState('list')
  if (results.loading) return <Spinner />
  if (error.message || error.error) return <p className='AdhocResult-Results-error'>{error.message}</p>

  const handleChangeView = (event) => {
    console.log(event.currentTarget.value)
    return setView(event.currentTarget.value)
  }

  return (
    <div className='AdhocResult-Results'>
      <div className='AdhocResult-Results-Market-3grid'>
        <div className='AdhocResult-Results-details AdhocResult-Results-Market-3grid-Col'>
          <h5>{userInput.brand || ''} {userInput.model || ''} {userInput.version || ''}</h5>
          <p>Datum eerste registratie: {userInput.firstregistrationdate || ''}{country === 'be' && userInput.vin && ` - VIN: ${userInput.vin}`}{country === 'nl' && userInput.licenseplate && ` - Kenteken: ${userInput.licenseplate}`}</p>
          <p>Leeftijd voertuig: {formatNumber(age)} jaar - Brandstof: {userInput.fuel || ''} - Kilometers: {formatNumber(userInput.mileage) || ''}</p>
        </div>
        <div className='AdhocResult-Results-Market-3grid-Col' />
        <div className='AdhocResult-Results-Market-3grid-Col'>
          {
            !results.priceHistoryLoaded
              ? (
                <div className='AdhocResult-Results-Market-View'>
                  <button value='list' className={view === 'list' ? 'active toggle' : 'toggle'} onClick={handleChangeView}>Overzicht</button>
                  <button value='graph' disabled className='toggle'><Spinner /></button>
                  <button value='price' disabled className='toggle'><Spinner /></button>
                  <button value='location' disabled className='toggle'><Spinner /></button>
                </div>)
              : (
                <div className='AdhocResult-Results-Market-View'>
                  <button value='list' className={view === 'list' ? 'active toggle' : 'toggle'} onClick={handleChangeView}>Overzicht</button>
                  <button value='graph' className={view === 'graph' ? 'active toggle' : 'toggle'} onClick={handleChangeView}>Prijshistorie</button>
                  <button value='price' className={view === 'price' ? 'active toggle' : 'toggle'} onClick={handleChangeView}>Prijsanalyse</button>
                  <button value='location' className={view === 'location' ? 'active toggle' : 'toggle'} onClick={handleChangeView}>Geografisch</button>
                </div>
                )
          }

        </div>
      </div>
      {
        {
          list: <MarketList data={data} fleetStatus={fleetStatus} />,
          graph: <PriceHistory results={results} />,
          price: <PriceAnalytics results={results} />,
          location: <MarketLocation results={results} />
        }[view]
      }
    </div>

  )
}

export default MarketAnalysis
