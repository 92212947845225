import useCountry from '../../../../hooks/useCountry.js'

const StatusMessage = ({ results }) => {
  const [country] = useCountry('be')

  const technicalInformation = results.technicalInformation
  const statusMessage = results.statusmessage

  return (
    <div className="AdhocResult-Results-status">
      <h2>Status bericht</h2>
      { country === 'be' &&
        <p>{!!results.passengerlcvadvice && results.passengerlcvadvice}</p>
      }
      { country === 'nl' && <>
        <p>{!!results.passengerlcvadvice && results.passengerlcvadvice}</p>
        <p>{!!results.personalprofessionaladvice && results.personalprofessionaladvice}</p>
      </>
      }
      { !!technicalInformation &&
        <div className="AdhocResult-Results-status-details">
          <p>Technische informatie: {technicalInformation.timeofrequest || ''} - Level: {technicalInformation.level || ''}</p>
        </div>
      }
      { statusMessage !== 'Succes' &&
        <div>
          <p>{statusMessage}</p>
        </div>
      }
    </div>
  )
}

export default StatusMessage
