/* eslint-disable no-unused-vars */
import { useState, useEffect } from 'react'
import './AdhocInput.scss'
import SearchSelect from '../../../components/SearchSelect/SearchSelect.js'
import Input from '../../../components/Input/Input.js'
import InputRadio from '../../../components/InputRadio/InputRadio.js'
import InputToggle from '../../../components/InputToggle/InputToggle.js'
import Select from '../../../components/Select/Select.js'
import Icon from '../../../components/Icon/Icon.js'
import useCountry from '../../../hooks/useCountry.js'
import useBrands from '../../../hooks/useBrands.js'
import useFuels from '../../../hooks/useFuels.js'
import useModels from '../../../hooks/useModels.js'
import useVersions from '../../../hooks/useVersions.js'
import { getInitialDate } from '../AdHoc.js'
import VinModal from './VinModal/VinModal.js'
import SignModal from './SignModal/SignModal.js'
import GetPriceModal from './GetPriceModal/GetPriceModal.js'
import getUser from '../../../utils/getUser.js'
import InmotivModal from './InmotivModal/InmotivModal'
import BrandModelModal from './BrandModelModal/BrandModelModal.js'

function translateFuel (fuel) {
  const fuels = {
    B: 'Benzine',
    D: 'Diesel',
    H: 'Hybride',
    E: 'Electrisch',
    G: 'Gas',
    BE: 'Benzine/Electrisch',
    DE: 'Diesel/Electrisch',
    BG: 'Benzine/Gas'
  }
  return fuels[fuel] || fuel
}

function translateFuelBack (fuel) {
  const fuels = {
    BENZINE: 'B',
    DIESEL: 'D',
    HYBRIDE: 'H',
    ELECTRISCH: 'E',
    GAS: 'G',
    'BENZINE/ELECTRISCH': 'BE',
    'DIESEL/ELECTRISCH': 'DE',
    'BENZINE/GAS': 'BG'
  }
  return fuels[(fuel || '').trim().toUpperCase()] || fuel
}

const AdhocInput = ({ store }) => {
  const [values, setFormValues] = useState(store.getState().formState)
  const [size, setSize] = useState('large')
  const [country] = useCountry('be')
  const [vinModalOpen, setVinModalOpen] = useState(false)
  const [signVWEModalOpen, setSignVWEModalOpen] = useState(false)
  const [signRDWModalOpen, setSignRDWModalOpen] = useState(false)
  const [modelModalOpen, setModelModalOpen] = useState(false)
  const [inmotivModalOpen, setInmotivModalOpen] = useState(false)
  const [vinValue, setVinValue] = useState('WBAKS210700H96470')
  const [signValue, setSignValue] = useState('93GDV9')
  const [, , brands, brandsSet] = useBrands([], { country })
  const [, , fuels] = useFuels([], { country })
  const [, , models, modelsSet] = useModels(
    [],
    {
      country,
      brand: brandsSet.has((values.merk || '').trim().toUpperCase()) ? (values.merk || '').trim() : ''
    }
  )
  const [, , versions] = useVersions(
    [],
    {
      country,
      brand: brandsSet.has((values.merk || '').trim().toUpperCase()) ? (values.merk || '').trim() : '',
      model: modelsSet.has((values.model || '').trim().toUpperCase()) ? (values.model || '').trim() : ''
    }
  )
  // const [formChanged, setFormChanged] = useState(false)
  const [getPriceModalOpen, setGetPriceModalOpen] = useState(false)
  const userServices = getUser().exservices

  function setValues (values) {
    const updates = {
      formState: values
    }

    const hasResults = !!store.getState().results.size

    if (hasResults) {
      const previousValues = store.getState().formState
      const futureKeys = new Set(['aanvang', 'duur', 'kmAanvang', 'kmJaar', 'periode'])

      // have any future values changed?
      for (const key of [...futureKeys]) {
        if (previousValues[key] !== values[key]) {
          updates.futureChanged = true
          break
        }
      }

      // have any non-future values changed?
      for (const key of Object.keys(values)) {
        if (!futureKeys.has(key) && previousValues[key] !== values[key]) {
          updates.formChangedSinceLastResultsFetched = true
          break
        }
      }
    }

    // if vin was just set...
    if (values._isNewVin) {
      delete values._isNewVin
      updates.isNewVinLookup = true
    }

    // if sign was just set...
    if (values._isNewSign) {
      delete values._isNewSign
      updates.isNewSignLookup = true
    }

    // clear vin if there are no results OR when anything NOT in future is changed
    if (!(updates.isNewVinLookup || store.getState().isNewVinLookup) && (hasResults || updates.formChangedSinceLastResultsFetched === true)) {
      updates.formState.vin = ''
      updates.formState.exservice = ''
    }

    // clear licenseplate if there are no results OR when anything NOT in future is changed
    if (!(updates.isNewSignLookup || store.getState().isNewSignLookup) && (hasResults || updates.formChangedSinceLastResultsFetched === true)) {
      updates.formState.licenseplate = ''
      updates.formState.exservice = ''
    }

    store.updateState(updates)
  }

  useEffect(() => {
    if (store) {
      const unsubscribe = store.subscribe(() => {
        const state = store.getState()
        setFormValues(state.formState)
        setSize(state.showInputOrResults === 'input' ? 'large' : 'small')
        // setFormChanged(state.formChangedSinceLastResultsFetched)
      })
      return () => unsubscribe()
    }
  }, [store])

  function handleValueChange (name) {
    const numbers = new Set(['km', 'cc', 'pk', 'co2', 'batterycapacity', 'prijs', 'opties', 'totaalPrijs', 'herberekendNieuw', 'herberekendNieuwEx', 'nieuwRWB', 'BPM', 'duur', 'kmAanvang', 'kmJaar', 'periode'])
    return e => {
      let newValue = e.target.value
      if (numbers.has(name)) {
        if (newValue < 0 || !newValue) {
          newValue = '0'
        }
      }

      const extraValues = {}
      if (name === 'prijs') {
        // update totaalPrijs
        extraValues.totaalPrijs = parseFloat(newValue) + (parseFloat(values.opties) || 0) + ''
      } else if (name === 'opties') {
        // update totaalPrijs
        extraValues.totaalPrijs = parseFloat(values.prijs) + (parseFloat(newValue) || 0) + ''
      } else if (name === 'totaalPrijs') {
        if (!values.prijs || values.prijs === '0') {
          // update prijs
          extraValues.prijs = parseFloat(newValue) - (parseFloat(values.opties) || 0) + ''
        } else {
          // update opties
          extraValues.opties = parseFloat(newValue) - (parseFloat(values.prijs) || 0) + ''
        }
      } else if (name === 'modelJaarMonth') {
        if (newValue) {
          newValue = Math.max(~~newValue, 1)
          newValue = Math.min(~~newValue, 12)
          newValue += ''
          if (values.modelJaarYear) {
            extraValues.modelJaar = `${newValue}-${values.modelJaarYear}`
          }
        } else {
          if (values.modelJaarYear) {
            extraValues.modelJaar = `7-${values.modelJaarYear}`
          }
        }
      } else if (name === 'modelJaarYear') {
        if (newValue) {
          newValue = Math.max(~~newValue, 1900)
          newValue = Math.min(~~newValue, 2100)
          newValue += ''
          extraValues.modelJaar = `${values.modelJaarMonth || 7}-${newValue}`
        } else {
          extraValues.modelJaar = ''
        }
      }

      setValues({
        ...values,
        ...extraValues,
        [name]: newValue
      })
    }
  }

  function handleVatChange (value) {
    let newValue = ''
    if (value === true) {
      newValue = 'in'
    } else if (value === false) {
      newValue = 'ex'
    }
    setValues({
      ...values,
      vat: newValue
    })
  }

  function handleDateChange (name) {
    return newDate => {
      setValues({
        ...values,
        [name]: newDate ? `${newDate.getFullYear()}-${newDate.getMonth() + 1}-${newDate.getDate()}` : ''
      })
    }
  }

  function handleSelectChange (name) {
    return newValue => {
      if (name === 'brandstof') {
        setValues({
          ...values,
          brandstof: translateFuelBack(newValue)
        })
      } else {
        setValues({
          ...values,
          [name]: newValue
        })
      }
    }
  }

  function handleResizeClick () {
    store.updateState({
      showInputOrResults: 'input'
    })
  }

  function handleClearClick () {
    setValues({
      merk: '',
      model: '',
      uitvoering: '',
      brandstof: '',
      registratie: '',
      km: '',
      pabw: '',
      cc: '',
      pk: '',
      co2: '',
      batterycapacity: '',
      modelJaar: '',
      prijs: '',
      opties: '',
      totaalPrijs: '',
      vat: '',
      herberekendNieuw: '',
      herberekendNieuwEx: '',
      nieuwRWB: '',
      BPM: '',
      transmissie: 'manual',
      aanvang: getInitialDate(),
      duur: '',
      kmAanvang: '',
      kmJaar: '',
      periode: '2'
    })
    setVinValue('WBAKS210700H96470')
    setSignValue('93GDV9')
  }

  function handleDefaultClick () {
    setValues({
      merk: 'BMW',
      model: 'X1',
      uitvoering: 'X1 sDrive18d',
      brandstof: 'D',
      registratie: '2019-04-19',
      km: '90000',
      pabw: 'passengercar',
      cc: '1995',
      pk: '149',
      co2: '114',
      batterycapacity: '',
      modelJaarMonth: '7',
      modelJaarYear: '2017',
      modelJaar: '7-2017',
      prijs: '33200',
      opties: '3500',
      totaalPrijs: '45700',
      vat: 'in',
      herberekendNieuw: '',
      herberekendNieuwEx: '',
      nieuwRWB: '45700',
      BPM: '8240',
      transmissie: 'manual',
      aanvang: getInitialDate(),
      duur: '24',
      kmAanvang: '90000',
      kmJaar: '20000',
      periode: '2'
    })
    setVinValue('WBAKS210700H96470')
    setSignValue('93GDV9')
  }

  function handleGetPriceClick () {
    setGetPriceModalOpen(true)
  }

  return (
    <div className={`AdhocInput AdhocInput-${size}`}>
      <div className='AdhocInput-title' onClick={size === 'small' ? handleResizeClick : () => { }}>
        <div className='AdhocInput-title-number'>1</div>
        <div className='AdhocInput-title-content'>
          <div className='AdhocInput-title-title'>
            <h1>Input</h1>
            <div className='AdhocInput-title-line' />
          </div>
          <div className='AdhocInput-title-buttons'>
            <div className='AdhocInput-title-buttons-group'>
              Vul in of zoek op&nbsp;
              {country !== 'nl' &&
                <>
                  <span onClick={() => setModelModalOpen(true)}>Merk/model selectie</span>
                &nbsp;of&nbsp;
                </>}
              {country === 'be' && <span onClick={() => setVinModalOpen(true)}>VIN nummer</span>}
              {country === 'be' && userServices.includes('INMOTIV') &&
                <>
                &nbsp;of&nbsp;
                  <span onClick={() => setInmotivModalOpen(true)}>Autoconnect CARWIZARD</span>
                </>}
              {country === 'nl' &&
                <>
                  <span onClick={() => setSignVWEModalOpen(true)}>Kenteken (VWE)</span>
                &nbsp;of&nbsp;
                  <span onClick={() => setSignRDWModalOpen(true)}>Kenteken (RDW)</span>
                </>}
            </div>
            <div className='AdhocInput-title-buttons-group AdhocInput-title-buttons-group-right'>
              <span onClick={handleClearClick}>Alles wissen</span>&nbsp;<span onClick={handleDefaultClick}>Default invullen</span>
            </div>
          </div>
        </div>
      </div>
      <div className='AdhocInput-body'>
        <div className='AdhocInput-form'>
          {/* formChanged && <div className="AdhocInput-changed-warning">Voertuig aangepast!</div> */}
          <div className='AdhocInput-group'>
            <h2 className='AdhocInput-group-title'>Voertuig</h2>
            <div className='AdhocInput-group-inputs AdhocInput-group-inputs-grid'>
              <SearchSelect label='Merk' placeholder='Merk' options={brands} value={values.merk} onChange={handleSelectChange('merk')} />
              <SearchSelect label='Model' placeholder='Model' options={models} value={values.model} onChange={handleSelectChange('model')} />
              <SearchSelect label='Uitvoering' placeholder='Uitvoering' options={versions} value={values.uitvoering} onChange={handleSelectChange('uitvoering')} valid={!!values.uitvoering} />
              <SearchSelect label='Brandstof' placeholder='Kies brandstof' options={fuels.map(fuel => translateFuel(fuel))} value={translateFuel(values.brandstof)} onChange={handleSelectChange('brandstof')} />
              <Input label='Eerste registratie' placeholder='dd-mm-jjjj' type='date' value={values.registratie} onChange={handleDateChange('registratie')} valid={!!values.registratie} />
              {country === 'be' &&
                <Input label='Kilometerstand' type='number' value={values.km} onChange={handleValueChange('km')} valid={!!values.km} />}
              {country === 'nl' &&
                <div className='AdhocInput-group-inputs-grid-nl'>
                  <Input label='Km stand' type='number' value={values.km} onChange={handleValueChange('km')} valid={!!values.km} />
                  <Select label='PA/BW' value={values.pabw} options={[{ value: 'passengercar', label: 'personenauto' }, { value: 'lcv', label: 'bedrijfswagen' }]} onChange={handleSelectChange('pabw')} />
                </div>}
            </div>
          </div>
          <div className='AdhocInput-group'>
            <h2 className='AdhocInput-group-title'>Motorisatie</h2>
            <div className='AdhocInput-group-inputs AdhocInput-group-inputs-motorisatie'>
              <div className='AdhocInput-group-inputs AdhocInput-group-inputs-grid'>
                <Input label='CC' type='number' value={values.cc} onChange={handleValueChange('cc')} valid={!!values.cc} />
                <Input label='PK' type='number' value={values.pk} onChange={handleValueChange('pk')} valid={!!values.pk} />
                <Input label='CO2' type='number' value={values.co2} onChange={handleValueChange('co2')} valid={!!values.co2} />
                {/* <Input label='kWh' type='number' value={values.batterycapacity} onChange={handleValueChange('batterycapacity')} valid={!!values.batterycapacity} /> */}
              </div>
              <div className='AdhocInput-group-inputs-transmissie'>
                <div className='AdhocInput-group-radio'>
                  <h4>Transmissie</h4>
                  <div className='AdhocInput-group-radios'>
                    <InputRadio name='transmission-radio' value='manual' label='Manueel' checked={values.transmissie === 'manual'} onChange={handleValueChange('transmissie')} />
                    <InputRadio name='transmission-radio' value='automatic' label='Automaat' checked={values.transmissie === 'automatic'} onChange={handleValueChange('transmissie')} />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className='AdhocInput-group'>
            <h2 className='AdhocInput-group-title'>Life Cycle</h2>
            <div className='AdhocInput-group-inputs AdhocInput-group-inputs-grid'>
              <Input enableNumberFormat={false} style={{ minWidth: 68, maxWidth: 110 }} label='Model maand' placeholder='mm' type='number' min={1} max={12} value={values.modelJaarMonth} onChange={handleValueChange('modelJaarMonth')} valid={!!values.modelJaarYear} />
              <Input enableNumberFormat={false} style={{ minWidth: 110, maxWidth: 140 }} label='Model jaar' placeholder='jjjj' type='number' min={1900} max={2100} value={values.modelJaarYear} onChange={handleValueChange('modelJaarYear')} valid={!!values.modelJaarYear} />
            </div>
          </div>
          <div className='AdhocInput-group'>
            <h2 className='AdhocInput-group-title AdhocInput-title-price'>
              Prijs
              {country === 'be' &&
                <button onClick={handleGetPriceClick} title='Consumentenprijs opzoeken'>
                  <Icon fill='var(--blue)' width={30} height={30} name='priceSearch' />
                </button>}
            </h2>
            <div className='AdhocInput-group-inputs AdhocInput-group-inputs-price'>
              <Input label='Consumentenprijs' type='number' value={values.prijs} onChange={handleValueChange('prijs')} valid={!!values.prijs} />
              {country === 'be' &&
                <>
                  <Input label='Opties & accessoires' type='number' value={values.opties} onChange={handleValueChange('opties')} valid={!!values.opties} />
                  <Input label='Totaal consumentenprijs' type='number' value={values.totaalPrijs} onChange={handleValueChange('totaalPrijs')} valid={!!values.totaalPrijs} />
                  <div className='AdhocInput-group-radio'>
                    <h4>BTW</h4>
                    <InputToggle
                      value={values.vat === 'in' ? true : (values.vat === 'ex' ? false : null)}
                      onChange={handleVatChange}
                      labelLeft='Exclusief'
                      labelRight='Inclusief'
                    />
                  </div>
                </>}
              {country === 'nl' &&
                <>
                  <Input label='Herberekende nieuwprijs' type='number' value={values.herberekendNieuw} onChange={handleValueChange('herberekendNieuw')} valid={!!values.herberekendNieuw} />
                  <Input label='Herberekende nieuwprijs ex' type='number' value={values.herberekendNieuwEx} onChange={handleValueChange('herberekendNieuwEx')} valid={!!values.herberekendNieuwEx} />
                  <Input label='Nieuwprijs RDW' type='number' value={values.nieuwRWB} onChange={handleValueChange('nieuwRWB')} valid={!!values.nieuwRWB} />
                  <Input label='BPM' type='number' value={values.BPM} onChange={handleValueChange('BPM')} valid={!!values.BPM} />
                </>}
            </div>
          </div>
          <div className='AdhocInput-group'>
            <h2 className='AdhocInput-group-title'>Toekomst</h2>
            <div className='AdhocInput-group-inputs'>
              <div className='AdhocInput-group-radio'>
                <h3>Contract</h3>
                <div className='AdhocInput-group-radios'>
                  <Input label='Datum aanvang' placeholder='dd-mm-jjjj' type='date' value={values.aanvang} onChange={handleDateChange('aanvang')} valid={!!values.aanvang} style={{ minWidth: 128, maxWidth: 180 }} />
                  <Input label='Duur' type='number' value={values.duur} onChange={handleValueChange('duur')} valid={!!values.duur} style={{ minWidth: 68, maxWidth: 100 }} />
                  <Input label='km bij aanvang' type='number' value={values.kmAanvang} onChange={handleValueChange('kmAanvang')} valid={!!values.kmAanvang} style={{ minWidth: 110, maxWidth: 180 }} />
                  <Input label='km/jaar' type='number' value={values.kmJaar} onChange={handleValueChange('kmJaar')} valid={!!values.kmJaar} style={{ minWidth: 110, maxWidth: 180 }} />
                  <Input label='Periodiciteit' type='number' value={values.periode} onChange={handleValueChange('periode')} valid={!!values.periode} style={{ maxWidth: 100 }} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <VinModal isOpen={vinModalOpen} onCloseModal={() => setVinModalOpen(false)} setValues={setValues} vinValue={vinValue} setVinValue={setVinValue} />
      <SignModal isOpen={signVWEModalOpen} onCloseModal={() => setSignVWEModalOpen(false)} setValues={setValues} type='VWE' signValue={signValue} setSignValue={setSignValue} />
      <SignModal isOpen={signRDWModalOpen} onCloseModal={() => setSignRDWModalOpen(false)} setValues={setValues} type='RDW' signValue={signValue} setSignValue={setSignValue} />
      <BrandModelModal isOpen={modelModalOpen} onCloseModal={() => setModelModalOpen(false)} setValues={setValues} />
      <GetPriceModal isOpen={getPriceModalOpen} onCloseModal={() => setGetPriceModalOpen(false)} setValues={setValues} currentValues={values} />
      <InmotivModal isOpen={inmotivModalOpen} onCloseModal={() => setInmotivModalOpen(false)} setValues={setValues} />
    </div>
  )
}

export default AdhocInput
