import { useState, useEffect } from 'react'
import api from '../api/api.js'

function useFuels (initialValue, { country }) {
  const [fuels, setFuels] = useState(initialValue)
  const [error, setError] = useState(null)
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    let unmounted = false
    async function getFuels () {
      setLoading(true)
      try {
        const fuels = await api.getFuels({ country }).json()
        if (!unmounted) {
          setFuels(fuels)
        }
      } catch (error) {
        if (!unmounted) {
          setError(error)
        }
      } finally {
        if (!unmounted) {
          setLoading(false)
        }
      }
    }
    getFuels()
    return () => {
      unmounted = true
    }
  }, [country])

  return [error, loading, fuels]
}

export default useFuels
