/* eslint-disable no-unused-vars */
import pkg from '../../../package.json'
import Icon from '../Icon/Icon.js'
import Dropdown, { DropdownButton, DropdownOptions, DropdownOptionText } from '../Dropdown/Dropdown.js'

const { version } = pkg
const Help = () => {
  return (
    <div className='Header-help'>
      <Dropdown>
        <DropdownButton>
          <Icon name='info' width={28} height={28} fill='white' />
        </DropdownButton>
        <DropdownOptions>
          <DropdownOptionText>{version ? `v${version}` : ''}</DropdownOptionText>
        </DropdownOptions>
      </Dropdown>
    </div>
  )
}

export default Help
