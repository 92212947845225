/* eslint-disable no-unused-vars */
import { useEffect, useRef } from 'react'
import './AdHoc.scss'
import Header from '../../components/Header/Header.js'
import AdhocInput from './AdhocInput/AdhocInput.js'
import AdhocChoose from './AdhocChoose/AdhocChoose.js'
import AdhocResult from './AdhocResult/AdhocResult.js'
import useCountry from '../../hooks/useCountry.js'
import useStore from '../../hooks/useStore.js'

function getInitialDate () {
  const date = new Date()
  return `${date.getFullYear()}-${(date.getMonth() + 1 + '').padStart(2, '0')}-${(date.getDate() + '').padStart(2, '0')}`
}

const initialState = {
  showInputOrResults: 'input',
  resultsType: null,
  resultsTypePage: null,
  results: new Map(),
  resultErrors: new Map(),
  formState: {
    merk: '',
    model: '',
    uitvoering: '',
    brandstof: '',
    registratie: '',
    km: '',
    pabw: '',
    cc: '',
    pk: '',
    co2: '',
    batterycapacity: '',
    modelJaarMonth: '',
    modelJaarYear: '',
    modelJaar: '',
    prijs: '',
    opties: '',
    totaalPrijs: '',
    vat: '',
    herberekendNieuw: '',
    herberekendNieuwEx: '',
    nieuwRWB: '',
    BPM: '',
    transmissie: 'manual',
    aanvang: getInitialDate(),
    duur: '',
    kmAanvang: '',
    kmJaar: '',
    periode: '2',
    vin: '',
    licenseplate: '',
    exservice: ''
  },
  formChangedSinceLastResultsFetched: false,
  futureChanged: false,
  isNewVinLookup: false,
  isNewSignLookup: false,
  marketAnalysisFleetStatus: 'actual'
}

const AdHoc = () => {
  const [country] = useCountry('be')
  const previousCountry = useRef('be')
  const store = useStore(initialState)

  useEffect(() => {
    if (!store) return
    if (country !== previousCountry.current) {
      store.updateState({
        ...initialState,
        formChangedSinceLastResultsFetched: !!store.getState().results.size,
        futureChanged: !!store.getState().results.size,
        isNewVinLookup: false,
        isNewSignLookup: false
      })
      previousCountry.current = country
    }
  }, [country, store])

  if (!store) return null

  return (
    <div className='AdHoc'>
      <Header page='adhoc' />
      <div className='AdHoc-body'>
        <AdhocInput store={store} />
        <AdhocChoose store={store} />
        <AdhocResult store={store} />
      </div>
    </div>
  )
}

export default AdHoc
export { getInitialDate }
