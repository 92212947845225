/* eslint-disable no-unused-vars */
import { useState, useEffect } from 'react'
import './AdhocResult.scss'
import Actual from './Actual/Actual.js'
import Future from './Future/Future.js'
import Trend from './Trend/Trend.js'
import MarketAnalysis from './MarketAnalysis/MarketAnalysis'

const AdhocResult = ({ store }) => {
  const [showResult, setShowResult] = useState({ name: null, value: {}, error: {}, page: null })
  const [size, setSize] = useState('small')
  const [fleetStatus, setFleetStatus] = useState()

  useEffect(() => {
    if (store) {
      const unsubscribe = store.subscribe(() => {
        const state = store.getState()
        setShowResult({
          name: state.showInputOrResults === 'results' ? (state.resultsType || null) : null,
          value: state.results.get(state.resultsType) || {},
          error: state.resultErrors.get(state.resultsType) || {},
          page: state.resultsTypePage || null
        })
        setSize(state.showInputOrResults === 'results' ? 'large' : 'small')
        setFleetStatus(state.marketAnalysisFleetStatus)
      })
      return () => unsubscribe()
    }
  }, [store])

  function handleResizeClick () {
    store.updateState({
      showInputOrResults: 'results'
    })
  }

  let body = ''
  switch (showResult.name) {
    case 'actual': {
      body = <Actual results={showResult.value} error={showResult.error} />
      break
    }
    case 'future': {
      body = <Future results={showResult.value} error={showResult.error} />
      break
    }
    case 'trend': {
      body = <Trend results={showResult.value} error={showResult.error} />
      break
    }
    case 'market': {
      body = <MarketAnalysis results={showResult.value} error={showResult.error} fleetStatus={fleetStatus}/>
      break
    }
    default:
  }

  return (
    <div className={`AdhocResult AdhocResult-${size}`}>
      <div className='AdhocResult-title' onClick={size === 'small' ? handleResizeClick : () => {}}>
        <div className='AdhocResult-title-number'>3</div>
        <div className='AdhocResult-title-title'>
          <h1>Resultaat</h1>
        </div>
      </div>
      <div className='AdhocResult-body'>
        {showResult.name ? body : <Placeholder />}
      </div>
    </div>
  )
}

const Placeholder = () => {
  return (
    <p>Er zijn nog geen resultaten om te tonen.</p>
  )
}

export default AdhocResult
