import { useState, useEffect } from 'react'
import api from '../api/api.js'

function useBrands (initialValue, { country }) {
  const [brands, setBrands] = useState(initialValue)
  const [brandsSet, setBrandsSet] = useState(new Set([...(initialValue || [])]))
  const [error, setError] = useState(null)
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    setBrandsSet(new Set(brands))
  }, [brands])

  useEffect(() => {
    let unmounted = false
    async function getBrands () {
      setLoading(true)
      try {
        const brands = await api.getBrands({ country }).json()
        if (!unmounted) {
          setBrands(brands)
        }
      } catch (error) {
        if (!unmounted) {
          setError(error)
        }
      } finally {
        if (!unmounted) {
          setLoading(false)
        }
      }
    }
    getBrands()
    return () => {
      unmounted = true
    }
  }, [country])

  return [error, loading, brands, brandsSet]
}

export default useBrands
