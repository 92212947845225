import './ScalingGraph.scss'
import formatCurrency from '../../utils/formatCurrency'

const backgroundColorConverter = {
  Conservatief: '#00FF00',
  Matig: '#FFFF00',
  Regulier: '#FF8800',
  Positief: '#FF0000',
  Agressief: '#BB6666'
}

const ScalingGraph = ({ scalingData, price }) => {
  if (!scalingData) return null
  if (!Array.isArray(scalingData)) return null

  return (<div className='ScalingGraph-body'>
    {scalingData.map((item, index) => {
      return (
        <div className='ScalingGraph-column' key={index}>
          <div className='ScalingGraph-innerblock' style={{ backgroundColor: backgroundColorConverter[item.title] }}>
            {item.title !== 'Maximum' ? item.title : ''}
            <div className='ScalingGraph-nominal'>{formatCurrency(item.nominal + '' || '')}</div>
            <div className='ScalingGraph-percentage'>{item.percentage}%</div>
            {item.title === 'Regulier'
              ? (
                <>
                  <div className='ScalingGraph-price'>{formatCurrency(price + '' || '')}</div>
                  <div className='ScalingGraph-line'></div>
                </>
                )
              : ''}
          </div>
        </div>
      )
    })
    }
  </div>)
}

export default ScalingGraph
