// import './logger.js'
import './index.scss'
import './app.js'

import { registerLocale, setDefaultLocale } from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
import nl from 'date-fns/locale/nl'
registerLocale('nl', nl)
setDefaultLocale('nl')

// import reportWebVitals from './reportWebVitals.js'
// // If you want to start measuring performance in your app, pass a function
// // to log results (for example: reportWebVitals(console.log))
// // or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals()
