import Icon from '../components/Icon/Icon.js'
import './CountryOptions.scss'
import getUser from './getUser.js'

function getCountryOptions () {
  const countries = new Set((getUser().partnerManagement || []).map(item => (item.country || 'be').toLowerCase()))
  return [
    {
      value: 'be',
      label: 'België',
      disabled: !countries.has('be'),
      customLabel: <div className="Country-option"><Icon name="belgium" /><p>België</p></div>
    },
    {
      value: 'nl',
      label: 'Nederland',
      disabled: !countries.has('nl'),
      customLabel: <div className="Country-option"><Icon name="netherlands" /><p>Nederland</p></div>
    }
  ]
}

export default getCountryOptions
