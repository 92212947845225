import { useState, useEffect } from 'react'
import './BatchChoose.scss'
import Icon from '../../../components/Icon/Icon.js'
import Button from '../../../components/Button/Button.js'
import Input from '../../../components/Input/Input.js'
import InputRadio from '../../../components/InputRadio/InputRadio.js'
import api from '../../../api/api.js'
import useHasUnmounted from '../../../hooks/useHasUnmounted.js'
import useCountry from '../../../hooks/useCountry.js'
import showErrorMessage from '../../../utils/showErrorMessage.js'
import getSha from '../../../utils/getSha.js'
import getErrorMessage from '../../../utils/getErrorMessage.js'

const BatchChoose = ({ store }) => {
  const [responseType, setResponseType] = useState('email')
  const [email, setEmail] = useState('')
  const [emailError, setEmailError] = useState(false)
  const hasUnmounted = useHasUnmounted(false)
  const [country] = useCountry('be')
  const [forceEmail, setForceEmail] = useState(false)

  const [uploadResponse, setUploadResponse] = useState(store.getState().uploadResponse)
  const [processLoading, setProcessLoading] = useState(store.getState().processLoading)
  const [alreadyProcessedIds, setAlreadyProcessedIds] = useState(store.getState().alreadyProcessedIds)

  useEffect(() => {
    if (store) {
      const unsubscribe = store.subscribe(() => {
        const state = store.getState()
        setUploadResponse(state.uploadResponse)
        setProcessLoading(state.processLoading)
        setAlreadyProcessedIds(state.alreadyProcessedIds)
      })
      return () => unsubscribe()
    }
  }, [store])

  useEffect(() => {
    if (uploadResponse && uploadResponse.records >= 1000) {
      setForceEmail(true)
      setResponseType('email')
    } else if (forceEmail) {
      setForceEmail(false)
    }
  }, [uploadResponse, forceEmail])

  async function handleSubmit (e) {
    e.preventDefault()
    if (alreadyProcessedIds.has(uploadResponse.id)) return
    if (forceEmail && responseType !== 'email') return
    if (responseType === 'email' && !email) return setEmailError(true)

    const newAlreadyProcessedIds = new Set(store.getState().alreadyProcessedIds)
    newAlreadyProcessedIds.add(uploadResponse.id)
    store.updateState({
      processLoading: true,
      processType: responseType,
      alreadyProcessedIds: newAlreadyProcessedIds
    })
    try {
      let searchParameters
      if (responseType === 'email') {
        searchParameters = {
          emailaddress: email,
          sync: false
        }
      } else if (responseType === 'download') {
        searchParameters = {
          sync: true
        }
      }
      const response = await api.postResidualvalueById(
        uploadResponse.id,
        searchParameters,
        {
          sha: getSha('ACTUEEL', country.toUpperCase())
          // accept: 'application/csv' or 'application/json'
        }
      ).blob()
      if (!hasUnmounted.current) {
        store.updateState({
          processResponse: response
        })
      }
    } catch (error) {
      console.error(error)
      showErrorMessage(await getErrorMessage(error))
    } finally {
      if (!hasUnmounted.current) {
        store.updateState({
          processLoading: false
        })
      }
    }
  }

  function handleReturnTypeChange (name) {
    return () => {
      setResponseType(name)
    }
  }

  function handleEmailChange (e) {
    setEmail(e.target.value)
    setEmailError(false)
  }

  if (!uploadResponse) return null

  return (
    <div className="BatchChoose">
      <div className="BatchChoose-title">
        <div className="BatchChoose-title-circle">2</div>
        <div className="BatchChoose-title-content">
          <h1>Kies
            { processLoading && <div className="BatchChoose-title-line"></div> }
          </h1>
          <p>Hoe wil je de actuele vlootgegevens terugkrijgen?</p>
        </div>
      </div>
      <div className="BatchChoose-body">
        <form onSubmit={handleSubmit}>
          {!forceEmail &&
            <div className="BatchChoose-buttons">
              <button type="button" onClick={handleReturnTypeChange('email')} className={`BatchChoose-button ${responseType === 'email' ? 'BatchChoose-button-active' : ''}`}>
                <div className="BatchChoose-button-circle"><InputRadio name="responseType" onChange={handleReturnTypeChange('email')} value="email" checked={responseType === 'email'} /></div>
                <div className="BatchChoose-button-text">
                  <Icon fill="var(--dark-grey)" width={20} height={20} name="mail" />
                  <p>Via e-mail</p>
                </div>
              </button>
              <button type="button" onClick={handleReturnTypeChange('download')} className={`BatchChoose-button ${responseType === 'download' ? 'BatchChoose-button-active' : ''}`}>
                <div className="BatchChoose-button-circle"><InputRadio disabled={forceEmail} name="responseType" onChange={handleReturnTypeChange('download')} value="download" checked={responseType === 'download'} /></div>
                <div className="BatchChoose-button-text">
                  <Icon fill="var(--dark-grey)" width={22} height={22} name="download" />
                  <p>Download</p>
                </div>
              </button>
            </div>
          }
          {responseType === 'email' && <div className="BatchChoose-email">
            <Input name="email" onChange={handleEmailChange} value={email} type="email" label="E-mailadres" placeholder="naam@email.com" className="BatchChoose-email-input" hasError={emailError} />
          </div>}
          <div className='BatchChoose-start'>
            <Button type="submit" disabled={alreadyProcessedIds.has(uploadResponse.id)}>Start</Button>
          </div>
        </form>
      </div>
    </div>
  )
}

export default BatchChoose
