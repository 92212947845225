import { useState, useEffect, useRef } from 'react'
import Icon from '../Icon/Icon.js'
import './Notifications.scss'

const getId = (() => {
  let id = 0
  return () => {
    return id++
  }
})()

const Notifications = () => {
  const [messages, setMessages] = useState(new Set())
  const messagesRef = useRef(new Set())

  useEffect(() => {
    function handleEvent (e) {
      const newMessages = new Set([...messagesRef.current, { ...e.detail, id: getId() }])
      setMessages(newMessages)
      messagesRef.current = newMessages
    }
    window.addEventListener('showNotification', handleEvent)
    return () => window.removeEventListener('showNotification', handleEvent)
  }, [])

  function removeMessage (message) {
    return () => {
      const newMessages = new Set([...messagesRef.current])
      newMessages.delete(message)
      setMessages(newMessages)
      messagesRef.current = newMessages
    }
  }

  return (
    <div className="Notifications">
      <div className="Notifications-messages">
        {[...messages].map(message => <Message key={message.id} message={message} removeMessage={removeMessage(message)} />)}
      </div>
    </div>
  )
}

const Message = ({ message, removeMessage }) => {
  const timeout = useRef(null)

  useEffect(() => {
    if (!timeout.current) timeout.current = setTimeout(removeMessage, 5000)
  }, [removeMessage])

  const isError = message.type === 'error'

  return (
    <div className={`Notifications-message ${isError ? 'Notifications-message-error' : ''}`} onClick={removeMessage}>
      { isError && <Icon name="danger" fill="#C71A1A" width={22} height={22} /> }
      {message.message}
    </div>
  )
}

export default Notifications
