import { useState } from 'react'
import Modal from '../../../../components/Modal/Modal.js'
import Input from '../../../../components/Input/Input.js'
import Button from '../../../../components/Button/Button.js'
import Spinner from '../../../../components/Spinner/Spinner.js'
import api from '../../../../api/api.js'
import useCountry from '../../../../hooks/useCountry.js'
import getSha from '../../../../utils/getSha.js'
import showErrorMessage from '../../../../utils/showErrorMessage.js'
import canUseService from '../../../../utils/canUseService.js'
import getErrorMessage from '../../../../utils/getErrorMessage.js'
import getExservice from '../../../../utils/getExservice.js'
import { getInitialDate } from '../../AdHoc.js'

function translateTransmission (value) {
  const translations = {
    0: 'manual',
    1: 'automatic',
    M: 'manual',
    A: 'automatic'
  }
  return translations[value] || value
}

const SignModal = ({ isOpen, onCloseModal, setValues, type, signValue, setSignValue }) => {
  const [country] = useCountry('be')
  const [loading, setLoading] = useState(false)

  function handleSignChange (e) {
    setSignValue(e.target.value)
  }

  async function handleSignConfirm () {
    try {
      setLoading(true)
      const exservice = getExservice({
        licensePlate: signValue,
        type
      })
      const result = await api.getCarInformation({
        exservice,
        licensePlate: signValue
      }, {
        sha: getSha('ACTUEEL', country.toUpperCase())
      }).json()
      if (!result.code) {
        let registratie
        if (result.firstregistrationdate) {
          registratie = result.firstregistrationdate.split('-').reverse().join('-')
        }
        let splitModalYear = []
        if (result.modelyear) {
          splitModalYear = result.modelyear.split('-').slice(0, 2)
        }
        setValues({
          prijs: (result.basiccatalogueprice || '') + '',
          BPM: (result.bpm || '') + '',
          merk: result.brand,
          cc: (result.cc || '') + '',
          co2: (result.co2 || '') + '',
          // doors: result.doors,
          // energylabel: result.energylabel,
          registratie,
          brandstof: result.fuel,
          totaalPrijs: (result.fullcatalogueprice || '') + '',
          vat: result.vat || '',
          pk: (result.hp || '') + '',
          licenseplate: signValue,
          km: (result.mileage || '') + '',
          model: result.model,
          modelJaarMonth: splitModalYear[1] || '',
          modelJaarYear: splitModalYear[0] || '',
          modelJaar: splitModalYear.length ? `${splitModalYear[1]}-${splitModalYear[0]}` : '',
          nieuwRWB: (result.fullcatalogueprice || result.recalculatedcatalogueprice || '') + '',
          herberekendNieuwEx: (result.recalculatedcataloguepricevatexclusive || '') + '',
          // seats: result.seats,
          // result.technicalInformation
          transmissie: translateTransmission(result.transmission),
          // vehicletype: result.vehicletype,
          uitvoering: result.version,
          // vin: result.vin
          pabw: 'passengercar',
          aanvang: getInitialDate(),
          periode: '2',
          exs: exservice,
          exsid: result.exsid,
          _isNewSign: true
        })
        onCloseModal()
      } else {
        showErrorMessage(result.message)
      }
    } catch (error) {
      console.error(error)
      showErrorMessage(await getErrorMessage(error))
    } finally {
      setLoading(false)
    }
  }

  const servicesByType = {
    VWE: ['VWE', 'EXS01', 'EXS02', 'EXS03'],
    RDW: ['RDW', 'EXS04']
  }
  const canDoSignLookup = canUseService(servicesByType[type])

  return (
    <Modal open={isOpen} closeModal={onCloseModal} title={`Kenteken selectie (${type})`}>
      <div className="AdhocInput-modal-body">
        { canDoSignLookup
          ? <>
            <Input label="Kenteken" placeholder="Kenteken" value={signValue} onChange={handleSignChange} />
            <div className="AdhocInput-modal-buttons">
              <Button disabled={loading} ghost={true} onClick={onCloseModal}>Annuleren</Button>
              <Button disabled={loading} onClick={handleSignConfirm}>{ loading ? <Spinner color="white" /> : 'Kies' }</Button>
            </div>
          </>
          : <>
            <p>U heeft momenteel geen toegang tot deze service.</p>
            <p>Contacteer Quid Pro Quo indien u verder geholpen wenst te worden.</p>
          </>
        }
      </div>
    </Modal>
  )
}

export default SignModal
