import { useState, useEffect } from 'react'
import api from '../api/api.js'

function useVersions (initialValue, { country, brand, model }) {
  const [versions, setVersions] = useState(initialValue)
  const [error, setError] = useState(null)
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    let unmounted = false
    if (!brand || !model) return
    async function getVersions () {
      setLoading(true)
      try {
        const versions = await api.getVersions({ country, brand, model }).json()
        if (!unmounted) {
          setVersions(versions)
        }
      } catch (error) {
        if (!unmounted) {
          setError(error)
        }
      } finally {
        if (!unmounted) {
          setLoading(false)
        }
      }
    }
    getVersions()
    return () => {
      unmounted = true
    }
  }, [country, brand, model])

  return [error, loading, versions]
}

export default useVersions
