const numberFormat = new Intl.NumberFormat('nl-NL', { style: 'currency', currency: 'EUR', maximumFractionDigits: 0, minimumFractionDigits: 0 })

function format (value) {
  value = parseFloat(value)
  if (isNaN(value)) value = 0
  return numberFormat.format(value)
}

export default format
export { numberFormat }
