import ky from 'ky'
import getApiHost from '../utils/getApiHost.js'

function getBrands ({ country = 'NL', source = 'fvs', modelyear, modelmonth }) {
  const searchParams = {
    source
  }
  if (source === 'fvs') {
    searchParams.country = country
  }

  if (source === 'inmotiv') {
    searchParams.modelyear = modelyear
    searchParams.modelmonth = modelmonth
  }

  return ky.get(`${getApiHost()}/v1/brands`, {
    headers: {
      authorization: 'Basic ' + window.localStorage.getItem('token')
    },
    searchParams,
    timeout: false
  })
}

export default getBrands
