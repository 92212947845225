import ky from 'ky'
import getApiHost from '../utils/getApiHost.js'

function getMarketpricehistory (searchParams, headers = {}) {
  return ky.get(`${getApiHost()}/v1/marketpricehistory`, {
    headers: {
      authorization: 'Basic ' + window.localStorage.getItem('token'),
      ...headers
    },
    searchParams: {
      ...searchParams
    },
    timeout: false
  })
}

export default getMarketpricehistory
