import { useState, useEffect } from 'react'
import getUser from '../utils/getUser.js'

function useUser () {
  const [user, setUser] = useState({})

  useEffect(() => {
    try {
      setUser(getUser())
    } catch (error) {}
  }, [])

  return user
}

export default useUser
