import { useState, useEffect, useRef, useMemo } from 'react'
import PropTypes from 'prop-types'
import './Input.scss'
import Icon from '../Icon/Icon.js'
import formatNumber from '../../utils/formatNumber.js'
import DatePicker from 'react-datepicker'

const Input = ({
  value = '',
  onChange = () => {},
  placeholder = '',
  label,
  valid = false,
  id,
  type = 'text',
  className,
  hasError,
  disabled = false,
  min,
  max,
  enableNumberFormat = true,
  name,
  ...props
}) => {
  const inputRef = useRef(null)
  const isFocusedRef = useRef(false)
  id = useMemo(() => { return id || (Math.round(Math.random() * 10000000)).toString() }, [id])

  let focus, blur

  function handleFocusClick (e) {
    if (!inputRef.current) return
    if (e.target !== inputRef.current) {
      inputRef.current.focus()
    }
  }

  function handleClearClick (e) {
    e.stopPropagation()
    if (type === 'date') return onChange('')
    if (type === 'number' && typeof (min || max) === 'number' && blur) return blur({ target: { value: '' } })
    onChange({ target: { value: '' } })
  }

  const classNames = ['Input']
  if (valid) classNames.push('Input-valid')
  if (className) classNames.push(className)
  if (hasError) classNames.push('Input-error')
  if (disabled) classNames.push('Input-disabled')

  const [showValue, setShowValue] = useState('')
  const [showType, setShowType] = useState('')

  useEffect(() => {
    if (isFocusedRef.current === true) return
    if (value && type === 'number' && enableNumberFormat) {
      setShowValue(formatNumber(value))
      setShowType('text')
    } else {
      setShowValue('')
      setShowType('')
    }
  }, [value, type, enableNumberFormat])

  if (type === 'number' && enableNumberFormat) {
    focus = e => {
      isFocusedRef.current = true
      setShowValue('')
      setShowType('')
    }
    blur = e => {
      isFocusedRef.current = false
      if (value) {
        setShowValue(formatNumber(value))
        setShowType('text')
      }
    }
  } else if (type === 'number' && typeof (min || max) === 'number') {
    const originalChange = onChange
    onChange = event => {
      const newValue = event.target.value
      setShowValue(event.target.value)
      if (!newValue) {
        blur({ target: { value: '' } })
      }
    }
    blur = originalChange
  }

  return (
    <div className={classNames.join(' ')} {...props}>
      {label && <label className='Input-label' htmlFor={id}>{label}</label>}
      <div className='Input-container' onClick={handleFocusClick}>
        {type === 'date'
          ? <DatePicker
              selected={value ? new Date(value) : ''}
              onChange={onChange}
              dateFormat='dd-MM-yyyy'
              placeholderText={placeholder}
            />
          : <input
              id={id}
              value={showValue || value}
              onChange={onChange}
              placeholder={placeholder}
              ref={inputRef}
              type={showType || type}
              onBlur={blur}
              onFocus={focus}
              disabled={disabled}
              name={name}
              {
              ...{
                ...typeof min === 'number' && { min },
                ...typeof max === 'number' && { max }
              }
            }
            />}
        <div className='Input-status'>
          <div className='Input-clear'><button tabIndex={-1} type='button' onClick={handleClearClick}><Icon name='cross' fill='var(--super-dark-grey)' /></button></div>
        </div>
      </div>
    </div>
  )
}

Input.propTypes = {
  value: PropTypes.string,
  onChange: PropTypes.func,
  placeholder: PropTypes.string,
  label: PropTypes.string,
  valid: PropTypes.bool,
  id: PropTypes.string,
  type: PropTypes.string,
  hasError: PropTypes.bool,
  disabled: PropTypes.bool,
  min: PropTypes.number,
  max: PropTypes.number,
  enableNumberFormat: PropTypes.bool,
  name: PropTypes.string
}

export default Input
