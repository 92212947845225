import { useState, useEffect } from 'react'
import './BatchResult.scss'
import Button from '../../../components/Button/Button.js'
import Spinner from '../../../components/Spinner/Spinner.js'

const BatchResult = ({ store }) => {
  const [processLoading, setProcessLoading] = useState(store.getState().processLoading)
  const [processType, setProcessType] = useState(store.getState().processType)
  const [processResponse, setProcessResponse] = useState(store.getState().processResponse)
  const [uploadResponse, setUploadResponse] = useState(store.getState().uploadResponse)

  useEffect(() => {
    if (store) {
      const unsubscribe = store.subscribe(() => {
        const state = store.getState()
        setProcessLoading(state.processLoading)
        setProcessType(state.processType)
        setProcessResponse(state.processResponse)
        setUploadResponse(state.uploadResponse)
      })
      return () => unsubscribe()
    }
  }, [store])

  if (!processType) return null

  function handleDownload () {
    if (!processResponse || !uploadResponse) return
    const url = URL.createObjectURL(processResponse)
    const link = document.createElement('a')
    link.href = url
    link.download = `${uploadResponse.id || 'restwaarde'}.${uploadResponse.extension || 'csv'}`
    link.click()
  }

  return (
    <div className="BatchResult">
      <div className="BatchResult-title">
        <div className="BatchResult-title-circle">3</div>
        <div className="BatchResult-title-content">
          <h1>Resultaat</h1>
        </div>
      </div>
      <div className="BatchResult-body">
        {processType === 'email' &&
          <div className="BatchResult-email">
            {/* <div className="BatchResult-progress">
              <div className="BatchResult-progress-bar"></div>
              <p>Klaar!</p>
            </div> */}
            { processLoading
              ? <Spinner />
              : <p>U mag verder werken in RWB of de applicatie verlaten.<br />Wij sturen u het resultaat via e-mail.</p>
            }
          </div>
        }
        {processType === 'download' &&
          <div className="BatchResult-download">
            { processLoading
              ? <Spinner />
              : <>
                <p>Het bestand is klaar!</p>
                <Button onClick={handleDownload}>Download</Button>
              </>
            }
          </div>
        }
      </div>
    </div>
  )
}

export default BatchResult
