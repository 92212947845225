import './InputToggle.scss'
import PropTypes from 'prop-types'

const InputToggle = ({ value = null, onChange, labelLeft, labelRight, style }) => {
  function handleButtonClick () {
    onChange(!value)
  }

  function handleLabelClick (name) {
    return () => {
      onChange(name !== 'left')
    }
  }

  let currentState = 'center'
  if (value === true) {
    currentState = 'right'
  } else if (value === false) {
    currentState = 'left'
  }

  return (
    <div className='InputToggle-root' style={style}>
      {labelLeft && <span onClick={handleLabelClick('left')}>{labelLeft}</span>}
      <div className={`InputToggle-box ${currentState}`}>
        <div className='InputToggle-circle' onClick={handleButtonClick} />
      </div>
      {labelRight && <span onClick={handleLabelClick('right')}>{labelRight}</span>}
    </div>
  )
}

InputToggle.propTypes = {
  value: PropTypes.bool,
  onChange: PropTypes.func,
  labelLeft: PropTypes.string,
  labelRight: PropTypes.string,
  disabled: PropTypes.bool
}

export default InputToggle
