// import React from 'react'
// import isDev from '../../utils/isDev.js'
import Notifications from '../Notifications/Notifications.js'
import ErrorMessage from '../ErrorMessage/ErrorMessage.js'

// const DevTools = isDev && React.lazy(() => import('../DevTools/DevTools.js'))
//     { isDev && <React.Suspense fallback={null}><DevTools /></React.Suspense> }

const App = ({ children }) => (
  <div className="App">
    {children}
    <Notifications />
    <ErrorMessage />
  </div>
)

export default App
