import './Header.scss'
import Select from '../Select/Select.js'
import getCountryOptions from '../../utils/getCountryOptions.js'
import history from '../../history.js'
import useCountry from '../../hooks/useCountry.js'
import getBaseUrl from '../../utils/getBaseUrl.js'
import getApiHost from '../../utils/getApiHost.js'
import User from './User.js'
import Help from './Help.js'

const Header = ({ page = '' }) => {
  const [country, setCountry] = useCountry('be')

  function handleNavClick (name) {
    return e => {
      e.preventDefault()
      history.push(name)
    }
  }

  return (
    <div className='Header'>
      <a href={getBaseUrl() + '/'} onClick={handleNavClick(getBaseUrl() + '/')}><img src={`${getBaseUrl()}/images/QPQ.png`} alt='logo QPQ' /></a>
      <div className='Header-nav'>
        <nav>
          <a href={getBaseUrl() + '/adhoc'} onClick={handleNavClick(getBaseUrl() + '/adhoc')} className={`Header-nav-button ${page === 'adhoc' ? 'Header-nav-button-active' : ''}`}>AdHoc</a>
          <a href={getBaseUrl() + '/batch'} onClick={handleNavClick(getBaseUrl() + '/batch')} className={`Header-nav-button ${page === 'batch' ? 'Header-nav-button-active' : ''}`}>Batch</a>
          <a href={`${getApiHost()}/v1/docs`} target='_blank' rel='noreferrer noopener' className={`Header-nav-button ${page === 'api' ? 'Header-nav-button-active' : ''}`}>API</a>
        </nav>
      </div>
      <div className='Header-fill' />
      <div className='Header-extra-nav'>
        <a href={getBaseUrl() + '/faq'} onClick={handleNavClick(getBaseUrl() + '/faq')} className={`Header-nav-button ${page === 'faq' ? 'Header-nav-button-active' : ''}`}>faq</a>
      </div>
      <Select className='Header-country-select' value={country} options={getCountryOptions()} onChange={setCountry} />
      <Help />
      <User />
    </div>
  )
}

export default Header
