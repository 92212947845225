import convertStringToDate from './convertStringToDate.js'
import getAge from './getAge.js'

function prepareCsvContent (results) {
  const headers = [...Object.keys(results.result[0]), 'brand', 'model', 'version', 'firstregistrationdate', 'fuel', 'age']
  const content = results.result.map(item => {
    return {
      ...item,
      brand: results.userinput.brand,
      model: results.userinput.model,
      version: results.userinput.version,
      firstregistrationdate: results.userinput.firstregistrationdate,
      fuel: results.userinput.fuel,
      age: getAge(convertStringToDate(results.userinput.firstregistrationdate), convertStringToDate(item.date))
    }
  })
  return [headers, content]
}

export default prepareCsvContent
