import PropTypes from 'prop-types'
import './Button.scss'

const Button = ({ children, onClick, ghost = false, className = '', ...props }) => {
  const classNames = ['Button']
  if (ghost) classNames.push('Button-ghost')
  if (className) classNames.push(className)
  return (
    <button onClick={onClick} className={classNames.join(' ')} {...props}>
      {children}
    </button>
  )
}

Button.propTypes = {
  onClick: PropTypes.func,
  ghost: PropTypes.bool
}

export default Button
