import { useState, useEffect } from 'react'

function useCountry (initialValue) {
  const [selectedCountry, setSelectedCountry] = useState(window.localStorage.getItem('selectedCountry') || initialValue || '')

  useEffect(() => {
    function handleEvent (e) {
      setSelectedCountry(e.detail)
    }
    window.addEventListener('updateCountry', handleEvent)
    return () => window.removeEventListener('updateCountry', handleEvent)
  }, [])

  function changeCountry (newValue) {
    window.localStorage.setItem('selectedCountry', newValue)
    window.dispatchEvent(new window.CustomEvent('updateCountry', { detail: newValue }))
  }

  return [selectedCountry, changeCountry]
}

export default useCountry
