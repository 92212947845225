import { useState, useEffect, useRef } from 'react'
import './RangePicker.scss'

const RangePicker = ({ min = 0, max = 10, average = 5, onChange = () => {}, valueMin = 0, valueMax = 1 }) => {
  const dragRef = useRef({ name: null, active: false })
  const [positionState, setPositionState] = useState({
    percentMin: 0,
    percentMax: 1
  })
  const rootRef = useRef()
  const barRef = useRef()
  const [dragging, setDragging] = useState(false)

  const posOfAverage = (average - min) / ((max - min) / 100) - 0.3

  function handleMouseDown (name) {
    return () => {
      setDragging(true)
      dragRef.current = {
        name,
        active: true
      }
    }
  }

  useEffect(() => {
    const percent100 = (max - min) / 100
    const minPercent = (valueMin - min) / percent100
    const maxPercent = (valueMax - min) / percent100
    setPositionState({
      percentMin: minPercent,
      percentMax: maxPercent
    })
  }, [valueMin, valueMax, min, max])

  useEffect(() => {
    if (!dragging) {
      return
    }

    function handleMove (event) {
      if (!barRef.current || !dragRef.current.name) return
      const box = barRef.current.getBoundingClientRect()
      let newPos = event.clientX - box.left
      newPos = Math.min(box.width, newPos)
      newPos = Math.max(0, newPos)
      const newPositionPercent = newPos / (box.width / 100)
      setPositionState(state => {
        if (dragRef.current.name === 'percentMin') {
          newPos = Math.min(state.percentMax, newPositionPercent)
        } else {
          newPos = Math.max(state.percentMin, newPositionPercent)
        }
        return {
          ...state,
          [dragRef.current.name]: newPos
        }
      })
    }

    function handleMouseUp () {
      setDragging(false)
      dragRef.current = {
        name: null,
        active: false
      }
    }

    window.addEventListener('mousemove', handleMove)
    window.addEventListener('mouseup', handleMouseUp)
    return () => {
      window.removeEventListener('mousemove', handleMove)
      window.removeEventListener('mouseup', handleMouseUp)
    }
  }, [dragging])

  useEffect(() => {
    const percentMin = positionState.percentMin / 100
    const percentMax = positionState.percentMax / 100
    const totalMin = Math.round(min + ((max - min) * percentMin))
    const totalMax = Math.round(min + ((max - min) * percentMax))
    onChange({ valueMin: totalMin, valueMax: totalMax })
  }, [onChange, positionState, min, max])

  return (
    <div className='RangePicker-root' ref={rootRef}>
      <div className='RangePicker-bar' ref={barRef}>
        <div className='RangePicker-dot' onMouseDown={handleMouseDown('percentMin')} style={{ left: `${positionState.percentMin}%` }} />
        <div className='RangePicker-dot' onMouseDown={handleMouseDown('percentMax')} style={{ left: `${positionState.percentMax}%` }} />
      </div>
      <div className='RangePicker-marker RangePicker-marker-min' />
      <div className='RangePicker-marker RangePicker-marker-average' style={{ left: `${posOfAverage}%` }}><div><span>gemiddelde</span></div></div>
      <div className='RangePicker-marker RangePicker-marker-max' />
    </div>
  )
}

export default RangePicker
