function getSvgForExport (chartContainer) {
  const theSvg = chartContainer.querySelector('svg')
  const legendItems = chartContainer.querySelectorAll('.recharts-legend-wrapper li')
  const newLegendGroup = document.createElementNS('http://www.w3.org/2000/svg', 'g')
  theSvg.append(newLegendGroup)

  for (const legendItem of legendItems) {
    const itemGroup = document.createElementNS('http://www.w3.org/2000/svg', 'g')
    const previousTexts = [...newLegendGroup.querySelectorAll('text')]
    const offset = (previousTexts.length * 28) + previousTexts.reduce((sum, text) => sum + text.getBoundingClientRect().width, 0)

    // the circle
    const circle = legendItem.querySelector('svg').cloneNode(true)
    circle.setAttribute('x', 337 + offset)
    circle.setAttribute('y', 269)

    // the text
    const originalSpan = legendItem.querySelector('span')
    const text = document.createElementNS('http://www.w3.org/2000/svg', 'text')
    text.setAttribute('fill', circle.querySelector('path').getAttribute('stroke'))
    text.setAttribute('x', 355 + offset)
    text.setAttribute('y', 280)
    text.innerHTML = originalSpan.innerText

    itemGroup.append(text)
    itemGroup.append(circle)
    newLegendGroup.append(itemGroup)
  }

  const svgForExport = theSvg.outerHTML.replace('<svg', '<svg xmlns:xlink="http://www.w3.org/1999/xlink" xmlns="http://www.w3.org/2000/svg"').replaceAll('&nbsp;', ' ')
  newLegendGroup.remove()

  return svgForExport
}

export default getSvgForExport
