/* eslint-disable no-unused-vars */
import { useState, useEffect } from 'react'
import './AdhocChoose.scss'
import api from '../../../api/api.js'
import Icon from '../../../components/Icon/Icon.js'
import Spinner from '../../../components/Spinner/Spinner.js'
import useCountry from '../../../hooks/useCountry.js'
import useHasUnmounted from '../../../hooks/useHasUnmounted.js'
import showErrorMessage from '../../../utils/showErrorMessage.js'
import getSha from '../../../utils/getSha.js'
import getErrorMessage from '../../../utils/getErrorMessage.js'
import InputToggle from '../../../components/InputToggle/InputToggle.js'
import { format } from 'date-fns'
import {
  setDefaults,
  fromAddress
} from 'react-geocode'

setDefaults({
  key: 'AIzaSyB6J6WaT1ZzuC5MWnphKcNZ-M_ZEyrf6QQ', // Your API key here.
  language: 'en', // Default language for responses.
  region: 'be' // Default region for responses.
})

function translateFuel (fuel) {
  const fuels = {
    B: 'Benzine',
    D: 'Diesel',
    H: 'Hybrid',
    E: 'Electric',
    G: 'Gas'
  }
  return fuels[fuel] || fuel
}

const AdhocChoose = ({ store }) => {
  const [values, setFormValues] = useState(store.getState().formState)
  const [country] = useCountry('be')
  const [loading, setLoading] = useState({
    actual: false,
    future: false,
    trend: false,
    market: false
  })
  const hasUnmounted = useHasUnmounted(false)
  const [showResult, setShowResult] = useState(false)
  const [formChanged, setFormChanged] = useState(false)
  const [futureChanged, setFutureChanged] = useState(false)
  const [marketAnalysisFleetStatus, setmarketAnalysisFleetStatus] = useState(store.getState().marketAnalysisFleetStatus)

  useEffect(() => {
    if (store) {
      const unsubscribe = store.subscribe(() => {
        const state = store.getState()
        setFormValues(state.formState)
        setShowResult(state.resultsType || null)
        setFormChanged(state.formChangedSinceLastResultsFetched)
        setFutureChanged(state.futureChanged)
      })
      return () => unsubscribe()
    }
  }, [store])

  function handleResultChange (name, value) {
    const state = store.getState()
    const newResults = state.formChangedSinceLastResultsFetched ? new Map() : new Map(state.results)
    newResults.set(name, value)
    const newErrors = state.formChangedSinceLastResultsFetched ? new Map() : new Map(state.resultErrors)
    newErrors.delete(name)
    store.updateState({
      showInputOrResults: 'results',
      resultsType: name,
      resultsTypePage: null,
      results: newResults,
      resultErrors: newErrors,
      formChangedSinceLastResultsFetched: false,
      futureChanged: false,
      isNewVinLookup: false,
      isNewSignLookup: false
    })
  }

  function setResultError (name, message, error) {
    const state = store.getState()
    const newResults = state.formChangedSinceLastResultsFetched ? new Map() : new Map(state.results)
    newResults.set(name, { loading: false })
    const newErrors = state.formChangedSinceLastResultsFetched ? new Map() : new Map(state.resultErrors)
    newErrors.set(name, { message, error })
    store.updateState({
      showInputOrResults: 'results',
      resultsType: name,
      results: newResults,
      resultErrors: newErrors,
      formChangedSinceLastResultsFetched: false,
      futureChanged: false,
      isNewVinLookup: false,
      isNewSignLookup: false
    })
  }

  async function getActualValue () {
    try {
      const state = store.getState()
      if (!state.formChangedSinceLastResultsFetched && state.results.get('actual')) {
        return handleResultChange('actual', state.results.get('actual'))
      }

      const firstRegistration = (values.registratie || '').split('-');
      [firstRegistration[0], firstRegistration[2]] = [firstRegistration[2], firstRegistration[0]]
      handleResultChange('actual', { loading: true })
      setLoading({
        ...loading,
        actual: true
      })
      const result = await api.getResidualvalue({
        service: 'ACTUAL',
        country,
        ...values.merk && { brand: (values.merk || '').trim() },
        ...values.model && { model: (values.model || '').trim() },
        ...values.uitvoering && { version: (values.uitvoering || '').trim() },
        ...values.brandstof && { fuel: (values.brandstof || '').trim() },
        ...values.registratie && { firstregistrationdate: firstRegistration.join('-') },
        ...values.km && { mileage: values.km },
        ...country === 'nl' && values.pabw && { vehicletype: values.pabw },
        ...values.prijs && { basiccatalogueprice: values.prijs },
        ...country === 'be' && values.totaalPrijs && { fullcatalogueprice: values.totaalPrijs },
        ...country === 'be' && values.vat && { vat: values.vat },
        ...country === 'nl' && values.nieuwRWB && { fullcatalogueprice: values.nieuwRWB },
        ...country === 'nl' && values.herberekendNieuw && { recalculatedcatalogueprice: values.herberekendNieuw },
        ...country === 'nl' && values.herberekendNieuwEx && { recalculatedcataloguepricevatexclusive: values.herberekendNieuwEx },
        ...country === 'nl' && values.BPM && { bpm: values.BPM },
        ...values.cc && { cc: values.cc },
        ...values.pk && { hp: values.pk },
        ...values.co2 && { co2: values.co2 },
        ...values.batterycapacity && { batterycapacity: values.batterycapacity },
        ...values.transmissie && { transmission: values.transmissie },
        ...values.modelJaar && { modelyear: values.modelJaar },
        // doors
        // seats
        ...country === 'be' && values.vin && { vin: values.vin },
        ...country === 'nl' && values.licenseplate && { licenseplate: values.licenseplate },
        ...values.exservice && { exservice: values.exservice },
        ...values.exsid && { vehicleid: values.exsid },
        ...values.exs && { freetext: values.exs }
      }, {
        sha: getSha('ACTUEEL', country.toUpperCase())
      }).json()
      handleResultChange('actual', result)
    } catch (error) {
      console.error(error)
      const message = await getErrorMessage(error)
      showErrorMessage(message)
      setResultError('actual', message, error)
    } finally {
      if (!hasUnmounted.current) {
        setLoading({
          ...loading,
          actual: false
        })
      }
    }
  }

  async function getFutureValue () {
    try {
      const state = store.getState()
      if (!state.formChangedSinceLastResultsFetched && !state.futureChanged && state.results.get('future')) {
        return handleResultChange('future', state.results.get('future'))
      }

      const firstRegistration = (values.registratie || '').split('-');
      [firstRegistration[0], firstRegistration[2]] = [firstRegistration[2], firstRegistration[0]]
      const contractstartdate = (values.aanvang || '').split('-');
      [contractstartdate[0], contractstartdate[2]] = [contractstartdate[2], contractstartdate[0]]
      handleResultChange('future', { loading: true })
      setLoading({
        ...loading,
        future: true
      })
      const result = await api.getResidualvalue({
        service: 'FUTURE',
        country,
        ...values.merk && { brand: (values.merk || '').trim() },
        ...values.model && { model: (values.model || '').trim() },
        ...values.uitvoering && { version: (values.uitvoering || '').trim() },
        ...values.brandstof && { fuel: (values.brandstof || '').trim() },
        ...values.registratie && { firstregistrationdate: firstRegistration.join('-') },
        ...values.km && { mileage: values.km },
        ...country === 'nl' && values.pabw && { vehicletype: values.pabw },
        ...values.prijs && { basiccatalogueprice: values.prijs },
        ...country === 'be' && values.totaalPrijs && { fullcatalogueprice: values.totaalPrijs },
        ...country === 'be' && values.vat && { vat: values.vat },
        ...country === 'nl' && values.nieuwRWB && { fullcatalogueprice: values.nieuwRWB },
        ...country === 'nl' && values.herberekendNieuw && { recalculatedcatalogueprice: values.herberekendNieuw },
        ...country === 'nl' && values.herberekendNieuwEx && { recalculatedcataloguepricevatexclusive: values.herberekendNieuwEx },
        ...country === 'nl' && values.BPM && { bpm: values.BPM },
        ...values.cc && { cc: values.cc },
        ...values.pk && { hp: values.pk },
        ...values.co2 && { co2: values.co2 },
        ...values.batterycapacity && { batterycapacity: values.batterycapacity },
        ...values.transmissie && { transmission: values.transmissie },
        ...values.modelJaar && { modelyear: values.modelJaar },
        // doors
        // seats
        ...values.aanvang && { contractstartdate: contractstartdate.join('-') },
        ...values.duur && { contractlength: values.duur },
        ...values.kmAanvang && { mileagestartcontract: values.kmAanvang },
        ...values.kmJaar && { contractmileageyear: values.kmJaar },
        ...values.periode && { curveperiodicity: values.periode },
        ...country === 'be' && values.vin && { vin: values.vin },
        ...country === 'nl' && values.licenseplate && { licenseplate: values.licenseplate },
        ...values.exservice && { exservice: values.exservice },
        ...values.exsid && { vehicleid: values.exsid },
        ...values.exs && { freetext: values.exs }
      }, {
        sha: getSha('TOEKOMST', country.toUpperCase())
      }).json()
      handleResultChange('future', result)
    } catch (error) {
      console.error(error)
      const message = await getErrorMessage(error)
      showErrorMessage(message)
      setResultError('future', message, error)
    } finally {
      if (!hasUnmounted.current) {
        setLoading({
          ...loading,
          future: false
        })
      }
    }
  }

  async function getTrendValue () {
    try {
      const state = store.getState()
      if (!state.formChangedSinceLastResultsFetched && state.results.get('trend')) {
        return handleResultChange('trend', state.results.get('trend'))
      }

      const firstRegistration = (values.registratie || '').split('-');
      [firstRegistration[0], firstRegistration[2]] = [firstRegistration[2], firstRegistration[0]]
      const contractstartdate = (values.aanvang || '').split('-');
      [contractstartdate[0], contractstartdate[2]] = [contractstartdate[2], contractstartdate[0]]
      handleResultChange('trend', { loading: true })
      setLoading({
        ...loading,
        trend: true
      })
      const result = await api.getResidualvalue({
        service: 'TREND',
        country,
        ...values.merk && { brand: (values.merk || '').trim() },
        ...values.model && { model: (values.model || '').trim() },
        ...values.uitvoering && { version: (values.uitvoering || '').trim() },
        ...values.brandstof && { fuel: (values.brandstof || '').trim() },
        ...values.registratie && { firstregistrationdate: firstRegistration.join('-') },
        ...values.km && { mileage: values.km },
        ...country === 'nl' && values.pabw && { vehicletype: values.pabw },
        ...values.prijs && { basiccatalogueprice: values.prijs },
        ...country === 'be' && values.totaalPrijs && { fullcatalogueprice: values.totaalPrijs },
        ...country === 'be' && values.vat && { vat: values.vat },
        ...country === 'nl' && values.nieuwRWB && { fullcatalogueprice: values.nieuwRWB },
        ...country === 'nl' && values.herberekendNieuw && { recalculatedcatalogueprice: values.herberekendNieuw },
        ...country === 'nl' && values.herberekendNieuwEx && { recalculatedcataloguepricevatexclusive: values.herberekendNieuwEx },
        ...country === 'nl' && values.BPM && { bpm: values.BPM },
        ...values.cc && { cc: values.cc },
        ...values.pk && { hp: values.pk },
        ...values.co2 && { co2: values.co2 },
        ...values.batterycapacity && { batterycapacity: values.batterycapacity },
        ...values.transmissie && { transmission: values.transmissie },
        ...values.modelJaar && { modelyear: values.modelJaar },
        // doors
        // seats
        ...values.aanvang && { contractstartdate: contractstartdate.join('-') },
        ...values.duur && { contractlength: values.duur },
        ...values.kmAanvang && { mileagestartcontract: values.kmAanvang },
        ...values.kmJaar && { contractmileageyear: values.kmJaar },
        ...values.periode && { curveperiodicity: values.periode },
        ...country === 'be' && values.vin && { vin: values.vin },
        ...country === 'nl' && values.licenseplate && { licenseplate: values.licenseplate },
        ...values.exservice && { exservice: values.exservice },
        ...values.exsid && { vehicleid: values.exsid },
        ...values.exs && { freetext: values.exs }
      }, {
        sha: getSha('TOEKOMST', country.toUpperCase())
      }).json()
      handleResultChange('trend', result)
    } catch (error) {
      console.error(error)
      const message = await getErrorMessage(error)
      showErrorMessage(message)
      setResultError('trend', message, error)
    } finally {
      if (!hasUnmounted.current) {
        setLoading({
          ...loading,
          trend: false
        })
      }
    }
  }

  async function getMarketValue () {
    try {
      const state = store.getState()
      if (!state.formChangedSinceLastResultsFetched && state.results.get('market')) {
        return handleResultChange('market', state.results.get('market'))
      }

      const firstRegistration = (values.registratie || '').split('-');
      [firstRegistration[0], firstRegistration[2]] = [firstRegistration[2], firstRegistration[0]]
      const contractstartdate = (values.aanvang || '').split('-');
      [contractstartdate[0], contractstartdate[2]] = [contractstartdate[2], contractstartdate[0]]
      handleResultChange('market', { loading: true })
      setLoading({
        ...loading,
        market: true
      })
      const modelyear = firstRegistration[2] // values.modelJaar ? values.modelJaar.split('-')[1] : firstRegistration[2]
      const body = {
        country: country.toUpperCase(),
        pricehistory: false,
        ...values.merk && { brand: (values.merk || '').trim() },
        ...values.model && { model: (values.model || '').trim() },
        ...values.uitvoering && { version: (values.uitvoering || '').trim() },
        ...values.brandstof && { fuel: (translateFuel(values.brandstof) || '').trim() },
        ...values.registratie && { firstregistrationdate: firstRegistration.join('-') },
        ...values.km && { mileage: values.km },
        ...values.prijs && { basiccatalogueprice: values.prijs },
        ...country === 'be' && values.totaalPrijs && { fullcatalogueprice: values.totaalPrijs },
        ...country === 'nl' && values.nieuwRWB && { fullcatalogueprice: values.nieuwRWB },
        ...values.cc && { cc: values.cc },
        ...values.pk && { hp: values.pk },
        ...values.co2 && { co2: values.co2 },
        ...values.batterycapacity && { batterycapacity: values.batterycapacity },
        ...values.transmissie && { transmission: values.transmissie },
        modelyear,
        fleetstatus: store.getState().marketAnalysisFleetStatus
      }
      const result = await api.getMarketAnalysis(body, {
        sha: getSha('', country.toUpperCase())
      }).json()
      const today = new Date()
      result.userinput = body
      result.market = result.market.map(car => {
        car.pricehistory = null
        return car
      })
      result.priceHistoryLoaded = false
      handleResultChange('market', result)
    } catch (error) {
      console.error(error)
      const message = await getErrorMessage(error)
      showErrorMessage(message)
      setResultError('market', message, error)
    } finally {
      if (!hasUnmounted.current) {
        setLoading({
          ...loading,
          market: false
        })
      }
      getMarketPriceHistory()
    }
  }

  const getMarketPriceHistory = async () => {
    const colours = [
      '#9e0142',
      '#d53e4f',
      '#f46d43',
      '#fdae61',
      '#fee08b',
      '#e6f598',
      '#abdda4',
      '#66c2a5',
      '#3288bd',
      '#5e4fa2'
    ]
    const marketResults = store.getState().results.get('market')
    const today = new Date()
    const { market } = marketResults
    if (market && Array.isArray(market)) {
      const internalids = market.map(car => car.internalid).join(',')
      const searchParam = {
        country: country.toUpperCase(),
        internalids
      }
      const result = await api.getMarketpricehistory(searchParam, {
        sha: getSha('', country.toUpperCase())
      }).json()
      for (const car of marketResults.market) {
        const resultCar = result.market.find(c => c.internalid === car.internalid)
        if (car.city) {
          try {
            const response = await fromAddress(car.city)
            if (response.status === 'OK') {
              const { results: [{ geometry }] } = response
              car.location = geometry
            }
          } catch (error) {
            console.log(error)
          }
        }
        if (resultCar && resultCar.pricehistory) {
          const { pricehistory } = resultCar
          car.pricehistory = [...pricehistory, {
            date: format(today, 'dd-MM-yyyy'),
            price: pricehistory[pricehistory.length - 1].price
          }]
        } else {
          car.pricehistory = null
        }
      }
      marketResults.market = marketResults.market.map((car, index) => {
        car.colour = colours[index]
        return car
      })
      marketResults.priceHistoryLoaded = true
      handleResultChange('market', marketResults)
    }
  }

  const handleFleetStatusChange = () => {
    store.updateState({
      formChangedSinceLastResultsFetched: true,
      marketAnalysisFleetStatus: marketAnalysisFleetStatus === 'actual' ? '90days' : 'actual'
    })
    setmarketAnalysisFleetStatus(marketAnalysisFleetStatus === 'actual' ? '90days' : 'actual')
    getMarketValue()
  }

  const actualIsAvailable = !!values.registratie
  const futureIsAvailable = ['registratie', 'aanvang', 'duur', 'kmAanvang', 'kmJaar', 'periode'].reduce((accumulator, currentValue) => !accumulator ? false : !!values[currentValue], true)
  const trendIsAvailable = ['registratie', 'aanvang', ['prijs', 'opties', 'totaalPrijs', 'herberekendNieuw', 'herberekendNieuwEx', 'nieuwRWB', 'BPM']].reduce((accumulator, currentValue) => {
    let hasValue
    if (Array.isArray(currentValue)) {
      hasValue = currentValue.some(key => values[key])
    } else {
      hasValue = !!values[currentValue]
    }
    return !accumulator ? false : hasValue
  }, true)
  const marketIsAvailable = !!values.registratie

  return (
    <div className='AdhocChoose'>
      <div className='AdhocChoose-title'>
        <div className='AdhocChoose-title-number'>2</div>
        <div className='AdhocChoose-title-title'>
          <h1>Kies
            <div className='AdhocChoose-title-line' />
          </h1>
          <div>Wat wil je berekenen?</div>
        </div>
      </div>
      <div className='AdhocChoose-list'>
        <button onClick={getActualValue} disabled={!actualIsAvailable} className={showResult === 'actual' ? 'AdhocChoose-button-active' : ''}>
          <div className={`AdhocChoose-button-text ${loading.actual ? 'AdhocChoose-button-loading' : ''}`}>
            {loading.actual && <div className='AdhocChoose-button-spinner'><Spinner /></div>}
            <span>Actuele restwaarde</span>
            {formChanged && showResult === 'actual' && <div className='AdhocChoose-recalc-message'>Opnieuw berekenen</div>}
          </div>
          <Icon fill='var(--super-dark-grey)' name='caretRight' />
        </button>
        <button onClick={getFutureValue} disabled={!futureIsAvailable} className={showResult === 'future' ? 'AdhocChoose-button-active' : ''}>
          <div className={`AdhocChoose-button-text ${loading.future ? 'AdhocChoose-button-loading' : ''}`}>
            {loading.future && <div className='AdhocChoose-button-spinner'><Spinner /></div>}
            <span>Toekomstige restwaarde</span>
            {(formChanged || futureChanged) && showResult === 'future' && <div className='AdhocChoose-recalc-message'>Opnieuw berekenen</div>}
          </div>
          <Icon fill='var(--super-dark-grey)' name='caretRight' />
        </button>
        <button onClick={getTrendValue} disabled={!trendIsAvailable} className={showResult === 'trend' ? 'AdhocChoose-button-active' : ''}>
          <div className={`AdhocChoose-button-text ${loading.trend ? 'AdhocChoose-button-loading' : ''}`}>
            {loading.trend && <div className='AdhocChoose-button-spinner'><Spinner /></div>}
            <span>Trend</span>
            {formChanged && showResult === 'trend' && <div className='AdhocChoose-recalc-message'>Opnieuw berekenen</div>}
          </div>
          <Icon fill='var(--super-dark-grey)' name='caretRight' />
        </button>
        <button onClick={getMarketValue} disabled={!marketIsAvailable} className={showResult === 'market' ? 'AdhocChoose-button-active' : ''}>
          <div className={`AdhocChoose-button-text ${loading.market ? 'AdhocChoose-button-loading' : ''}`}>
            {loading.market && <div className='AdhocChoose-button-spinner'><Spinner /></div>}
            <span>Marktanalyse</span>
            {formChanged && showResult === 'market' && <div className='AdhocChoose-recalc-message'>Opnieuw berekenen</div>}
          </div>
          <Icon fill='var(--super-dark-grey)' name='caretRight' />
        </button>
        {showResult === 'market'
          ? (
            <div className='AdhocChoose-sub-item'>
              <InputToggle
                value={marketAnalysisFleetStatus === '90days'}
                onChange={handleFleetStatusChange}
                labelLeft='Actueel'
                labelRight='Verkocht'
                style={{ fontSize: '12px', color: 'var(--blue)', fontWeight: 'normal' }}
              />
            </div>
            )
          : null}
      </div>
    </div>
  )
}

export default AdhocChoose
