import { useState, useEffect } from 'react'
import './Select.scss'
import PropTypes from 'prop-types'
import Icon from '../Icon/Icon.js'

const Select = ({ label, value, onChange, options = [], placeholder = 'Kies een optie', className = '' }) => {
  const [isOpen, setIsOpen] = useState(false)
  const [focussed, setFocussed] = useState(options.findIndex(option => option.value === value))

  useEffect(() => {
    const y = window.scrollY

    function handleScrollEvent (e) {
      window.scrollTo(0, y)
    }

    function handleWheelEvent () {
      setIsOpen(false)
    }

    if (isOpen) {
      window.addEventListener('scroll', handleScrollEvent)
      window.addEventListener('wheel', handleWheelEvent)
      window.addEventListener('touchmove', handleWheelEvent)
    }
    return () => {
      window.removeEventListener('scroll', handleScrollEvent)
      window.removeEventListener('wheel', handleWheelEvent)
      window.removeEventListener('touchmove', handleWheelEvent)
    }
  }, [isOpen])

  useEffect(() => {
    function handleWindowClick (e) {
      if (!e.target.closest('.Select')) {
        setIsOpen(false)
      }
    }

    if (isOpen) {
      window.addEventListener('click', handleWindowClick)
    }

    return () => {
      window.removeEventListener('click', handleWindowClick)
    }
  }, [isOpen])

  useEffect(() => {
    function handleKeyPress (e) {
      switch (e.code) {
        case 'ArrowDown': {
          if (!options.some(option => !option.disabled)) return // make sure at least one option is available

          let newFocussed = (focussed + 1 + options.length) % options.length
          while (true) {
            const option = options[newFocussed]
            if (!option.disabled) {
              setFocussed(newFocussed)
              break
            } else {
              newFocussed = (newFocussed + 1 + options.length) % options.length
            }
          }
          break
        }
        case 'ArrowUp': {
          if (!options.some(option => !option.disabled)) return // make sure at least one option is available

          let newFocussed = (focussed - 1 + options.length) % options.length
          while (true) {
            const option = options[newFocussed]
            if (!option.disabled) {
              setFocussed(newFocussed)
              break
            } else {
              newFocussed = (newFocussed - 1 + options.length) % options.length
            }
          }
          break
        }
        case 'Escape': {
          setIsOpen(false)
          break
        }
        // falls through
        case 'Space':
        case 'Enter': {
          onChange(options[focussed].value)
          setIsOpen(false)
          break
        }
        default:
      }
    }

    if (isOpen) {
      window.addEventListener('keydown', handleKeyPress)
    }

    return () => {
      window.removeEventListener('keydown', handleKeyPress)
    }
  }, [isOpen, focussed, onChange, options])

  function handleOptionClick (option) {
    return () => {
      onChange(option.value)
      setIsOpen(false)
    }
  }

  const selectedOption = value && options.find(option => option.value === value)

  return (
    <div className={`Select ${className}`}>
      {label && <label className='Select-label'>{label}</label>}
      <select value={value} onChange={e => onChange(e.target.value)}>{options.map(option => <option key={option.value} value={option.value}>{option.label}</option>)}</select>
      <div className={`Select-current ${isOpen ? 'Select-current-active' : ''} ${label ? 'Select-small' : ''}`} onClick={() => setIsOpen(!isOpen)}>{selectedOption.customLabel || <p>{selectedOption.label || placeholder}</p>}<Icon className='Select-current-caret' name='caretDown' width={7} height={7} fill='var(--dark-grey)' /></div>
      {isOpen &&
        <div className='Select-options'>
          {options.map((option, i) => <Option key={option.value} disabled={option.disabled} label={option.label} onSelect={handleOptionClick(option)} selected={value === option.value} focussed={focussed === i} setFocussed={() => setFocussed(i)} customLabel={option.customLabel} />)}
        </div>}
    </div>
  )
}

const Option = ({ disabled, label, onSelect, selected, customLabel, focussed, setFocussed }) => {
  const myClasses = ['Select-option']
  if (focussed) myClasses.push('Select-option-focussed')
  if (disabled) myClasses.push('Select-option-disabled')
  return (
    <div className={myClasses.join(' ')} onClick={disabled ? () => {} : onSelect} onMouseEnter={disabled ? () => {} : () => setFocussed()}>
      {customLabel || <p>{label}</p>}
      {selected && <Icon className='Select-option-check' name='check' fill='var(--green)' />}
    </div>
  )
}

Select.propTypes = {
  label: PropTypes.string,
  value: PropTypes.any,
  onChange: PropTypes.func,
  options: PropTypes.arrayOf(PropTypes.shape({
    value: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    customLabel: PropTypes.node
  })),
  placeholder: PropTypes.string
}

export default Select
