import history from '../../history.js'
import getBaseUrl from '../../utils/getBaseUrl.js'
import useUser from '../../hooks/useUser.js'
import Dropdown, { DropdownButton, DropdownOptions, DropdownOptionButton } from '../Dropdown/Dropdown.js'

const User = () => {
  const user = useUser()

  function handleLogoutClick () {
    window.localStorage.removeItem('token')
    window.localStorage.removeItem('user')
    history.push(getBaseUrl() + '/')
  }

  return (
    <div className='Header-user'>
      <Dropdown>
        <DropdownButton>
          <div className='Header-user-logo'>
            <div className='Header-user-image'>
              {user.logo
                ? <img src={`data:;base64,${user.logo}`} alt='Profile icon' />
                : <div className='Header-user-image-placeholder' />}
            </div>
            <p>{user.partnerName}</p>
          </div>
        </DropdownButton>
        <DropdownOptions>
          <DropdownOptionButton onClick={handleLogoutClick}><p>Uitloggen</p></DropdownOptionButton>
        </DropdownOptions>
      </Dropdown>
    </div>
  )
}

export default User
