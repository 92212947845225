import ky from 'ky'
import getApiHost from '../utils/getApiHost.js'

function getFuels ({ username, password }) {
  const string = `${username}:${password}`
  const base = window.btoa(string)
  return ky.get(`${getApiHost()}/admin/v1/login`, {
    headers: {
      authorization: 'Basic ' + base
    },
    timeout: 20000
  })
}

export default getFuels
