import { useState, useEffect, useCallback } from 'react'
import './GetPriceModal.scss'
import Modal from '../../../../components/Modal/Modal.js'
import Input from '../../../../components/Input/Input.js'
import Button from '../../../../components/Button/Button.js'
import RangePicker from '../../../../components/RangePicker/RangePicker.js'
import Spinner from '../../../../components/Spinner/Spinner.js'
import api from '../../../../api/api.js'
import useCountry from '../../../../hooks/useCountry.js'
import getSha from '../../../../utils/getSha.js'
import showErrorMessage from '../../../../utils/showErrorMessage.js'
import getErrorMessage from '../../../../utils/getErrorMessage.js'

function translateFuel (fuel) {
  const fuels = {
    B: 'Benzine',
    D: 'Diesel',
    H: 'Hybride',
    E: 'Electrisch',
    G: 'Gas'
  }
  return fuels[fuel] || fuel
}

function translateTransmission (transmission) {
  const transmissions = {
    manual: 'Manueel',
    automatic: 'Automaat'
  }
  return transmissions[transmission] || transmission
}

const GetPriceModal = ({ isOpen, onCloseModal, setValues, currentValues = {} }) => {
  const [loading, setLoading] = useState(false)
  const [country] = useCountry('be')
  const [cataloguePrice, setCataloguePrice] = useState(null)
  const [selectedPrice, setSelectedPrice] = useState({
    valueMin: 0,
    valueMax: 0
  })

  useEffect(() => {
    if (!isOpen) {
      setSelectedPrice({
        valueMin: 0,
        valueMax: 0
      })
    }
  }, [isOpen])

  useEffect(() => {
    if (!isOpen) {
      return
    }

    async function getCataloguePrice () {
      try {
        setLoading(true)
        const result = await api.getCataloguePrice({
          country,
          ...!!currentValues.merk && { brand: currentValues.merk },
          ...!!currentValues.model && { model: currentValues.model },
          ...!!currentValues.uitvoering && { version: currentValues.uitvoering },
          ...!!currentValues.brandstof && { fuel: currentValues.brandstof },
          // ...!!currentValues.registratie && { firstregistrationdate: currentValues.registratie },
          ...!!currentValues.cc && { cc: currentValues.cc },
          ...!!currentValues.pk && { hp: currentValues.pk },
          ...!!currentValues.transmissie && { transmission: currentValues.transmissie }
        }, {
          sha: getSha('ACTUEEL', country.toUpperCase())
        }).json()
        setCataloguePrice(result)
        setSelectedPrice({
          valueMin: result.result.min,
          valueMax: result.result.average
        })
      } catch (error) {
        console.error(error)
        const message = await getErrorMessage(error)
        showErrorMessage(message)
      } finally {
        setLoading(false)
      }
    }
    getCataloguePrice()
  }, [isOpen, currentValues, country])

  function handleConfirm () {
    setValues({
      ...currentValues,
      prijs: selectedPrice.valueMin,
      opties: selectedPrice.valueMax - selectedPrice.valueMin,
      totaalPrijs: selectedPrice.valueMax,
      vat: 'in'
    })
    onCloseModal()
  }

  const handleRangeChange = useCallback(({ valueMin, valueMax }) => {
    setSelectedPrice({ valueMin, valueMax })
  }, [])

  function getResultValue (name) {
    let value
    try {
      value = cataloguePrice.result[name]
    } catch {}
    return value
  }

  return (
    <Modal open={isOpen} closeModal={onCloseModal} title="Prijs opzoeking">
      <div className="AdhocInput-modal-body">
        <dl className="GetPriceModal-info">
            <dt>Merk:</dt>
            <dd>{currentValues.merk || ''}</dd>
            <dt>Model:</dt>
            <dd>{currentValues.model || ''}</dd>
            <dt>Uitvoering:</dt>
            <dd>{currentValues.uitvoering || ''}</dd>
            <dt>Brandstof:</dt>
            <dd>{translateFuel(currentValues.brandstof || '')}</dd>
            <dt>Eerste&nbsp;registratie:</dt>
            <dd>{currentValues.merk || ''}</dd>
            <dt>Cc:</dt>
            <dd>{currentValues.cc || ''}</dd>
            <dt>pk:</dt>
            <dd>{currentValues.pk || ''}</dd>
            <dt>Transmissie:</dt>
            <dd>{translateTransmission(currentValues.transmissie || '')}</dd>
        </dl>

        <div className="GetPriceModal-input-body">
          { loading &&
            <div className="GetPriceModal-spinner">
              <Spinner width={24} height={24} />
            </div>
          }

          <RangePicker
            min={getResultValue('min')}
            max={getResultValue('max')}
            average={getResultValue('average')}
            onChange={handleRangeChange}
            valueMin={getResultValue('min')}
            valueMax={getResultValue('average')}
          />

          <div className="GetPriceModal-input-row">
            <Input disabled={true} label="Consumentenprijs" type="number" value={String(selectedPrice.valueMin)} />
            <Input disabled={true} label="Opties & accessoires" type="number" value={String(selectedPrice.valueMax - selectedPrice.valueMin)} />
            <Input disabled={true} label="Totaal consumentenprijs" type="number" value={String(selectedPrice.valueMax)} />
          </div>
        </div>

        <div className="AdhocInput-modal-buttons">
          <Button disabled={loading} ghost={true} onClick={onCloseModal}>Annuleren</Button>
          <Button disabled={loading} onClick={handleConfirm}>Toepassen</Button>
        </div>
      </div>
    </Modal>
  )
}

export default GetPriceModal
