import { useState, useEffect, useRef, cloneElement, Children } from 'react'
import './Dropdown.scss'

const Dropdown = ({ children }) => {
  const [isOpen, setIsOpen] = useState(false)
  const myId = useRef('Dropdown-' + Math.round(Math.random() * 100000))

  useEffect(() => {
    const y = window.scrollY

    function handleScrollEvent () {
      window.scrollTo(0, y)
    }

    function handleWheelEvent () {
      setIsOpen(false)
    }

    if (isOpen) {
      window.addEventListener('scroll', handleScrollEvent)
      window.addEventListener('wheel', handleWheelEvent)
      window.addEventListener('touchmove', handleWheelEvent)
    }
    return () => {
      window.removeEventListener('scroll', handleScrollEvent)
      window.removeEventListener('wheel', handleWheelEvent)
      window.removeEventListener('touchmove', handleWheelEvent)
    }
  }, [isOpen])

  useEffect(() => {
    function handleWindowClick (e) {
      if (!e.target.closest(`#${myId.current}`)) {
        setIsOpen(false)
      }
    }

    if (isOpen) {
      window.addEventListener('click', handleWindowClick)
    }

    return () => {
      window.removeEventListener('click', handleWindowClick)
    }
  }, [isOpen])

  function handleButtonClick () {
    setIsOpen(!isOpen)
  }

  const elements = Children.map(children, child => {
    if (child.type.name === DropdownButton.name) return cloneElement(child, { onClick: handleButtonClick })
    if (child.type.name === DropdownOptions.name) return cloneElement(child, { isOpen })
    return child
  })
  return (
    <div className='Dropdown' id={myId.current}>
      {elements}
    </div>
  )
}

const DropdownButton = ({ children, onClick }) => {
  return (
    <button className='Dropdown-button' onClick={onClick}>
      {children}
    </button>
  )
}

const DropdownOptions = ({ children, isOpen }) => {
  return (
    <div className={`Dropdown-options ${isOpen ? 'Dropdown-options-active' : ''}`}>
      {children}
    </div>
  )
}

const DropdownOptionButton = ({ children, onClick }) => {
  return (
    <button onClick={onClick}>{children}</button>
  )
}

const DropdownOptionText = ({ children }) => {
  return (
    <p className='Dropdown-option-text'>{children}</p>
  )
}

export {
  DropdownButton,
  DropdownOptions,
  DropdownOptionButton,
  DropdownOptionText
}
export default Dropdown
