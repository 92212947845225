function createCsv (headers, array = []) {
  const rows = []
  rows.push(headers.join(';'))
  for (const item of array) {
    const row = headers.map(header => {
      let value = (item[header] || '') + ''
      if (~value.indexOf('"')) {
        value = value.replace(/"/g, '""')
      }
      if (~value.indexOf(';') || ~value.indexOf('\n')) {
        value = `"${value}"`
      }
      return value
    })
    rows.push(row.join(';'))
  }
  return rows.join('\n')
}

export default createCsv
