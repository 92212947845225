import { useState, useEffect } from 'react'
import api from '../api/api.js'

function useModels (initialValue, { country, brand }) {
  const [models, setModels] = useState(initialValue)
  const [modelsSet, setModelsSet] = useState(new Set([...(initialValue || [])]))
  const [error, setError] = useState(null)
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    setModelsSet(new Set(models))
  }, [models])

  useEffect(() => {
    let unmounted = false
    if (!brand) return
    async function getModels () {
      setLoading(true)
      try {
        const models = await api.getModels({ country, brand }).json()
        if (!unmounted) {
          setModels(models)
        }
      } catch (error) {
        if (!unmounted) {
          setError(error)
        }
      } finally {
        if (!unmounted) {
          setLoading(false)
        }
      }
    }
    getModels()
    return () => {
      unmounted = true
    }
  }, [country, brand])

  return [error, loading, models, modelsSet]
}

export default useModels
