import { useState, useEffect, useRef } from 'react'
import './Home.scss'
import history from '../../history.js'
import api from '../../api/api.js'
import Icon from '../../components/Icon/Icon.js'
import Input from '../../components/Input/Input.js'
import Button from '../../components/Button/Button.js'
// import Select from '../../components/Select/Select.js'
import Spinner from '../../components/Spinner/Spinner.js'
// import getCountryOptions from '../../utils/getCountryOptions.js'
import getBaseUrl from '../../utils/getBaseUrl.js'
import showErrorMessage from '../../utils/showErrorMessage.js'
import getErrorMessage from '../../utils/getErrorMessage.js'
import useHasUnmounted from '../../hooks/useHasUnmounted.js'
import useCountry from '../../hooks/useCountry.js'

const Home = () => {
  const [loginValues, setLoginValues] = useState({ username: '', password: '' })
  const [activeAnchor, setActiveAnchor] = useState('about')
  const aboutRef = useRef(null)
  const servicesRef = useRef(null)
  const referencesRef = useRef(null)
  const [loading, setLoading] = useState(false)
  const hasUnmounted = useHasUnmounted(false)
  const [country, setCountry] = useCountry()
  // const [evaluateValues, setEvaluateValues] = useState({
  //   country: 'be',
  //   brand: '',
  //   model: '',
  //   fuel: '',
  //   registration: '',
  //   km: '',
  //   price: '',
  //   options: '',
  //   totalPrice: ''
  // })

  useEffect(() => {
    function handleScroll (e) {
      if (!aboutRef.current) return
      let active = 'references'
      if (aboutRef.current.getBoundingClientRect().y < 210) {
        active = 'about'
      }
      if (servicesRef.current.getBoundingClientRect().y < 210) {
        active = 'services'
      }
      if (referencesRef.current.getBoundingClientRect().y < 250) {
        active = 'references'
      }
      setActiveAnchor(active)
    }

    window.addEventListener('scroll', handleScroll)
    return () => window.removeEventListener('scroll', handleScroll)
  }, [])

  function handleLoginChange (name) {
    return e => {
      setLoginValues({
        ...loginValues,
        [name]: e.target.value
      })
    }
  }

  async function handleLoginSubmit (e) {
    e.preventDefault()
    if (loading) return
    let response
    try {
      setLoading(true)
      response = await api.login({
        username: loginValues.username,
        password: loginValues.password
      }).json()
    } catch (error) {
      console.error(error)
      showErrorMessage(await getErrorMessage(error))
      return console.error(error)
    } finally {
      if (!hasUnmounted.current) {
        setLoading(false)
      }
    }
    const string = `${loginValues.username}:${loginValues.password}`
    const base = window.btoa(string)
    window.localStorage.setItem('token', base)
    window.localStorage.setItem('user', JSON.stringify(response))

    // if not set yet -> set country to first country in partnerManagement list
    try {
      if (!country) {
        setCountry((response.partnerManagement[0].country || 'be').toLowerCase())
      }
    } catch (error) {}

    history.push({
      pathname: getBaseUrl() + '/adhoc',
      hash: ''
    })
  }

  // function handleEvaluateFormChange (name) {
  //   return e => {
  //     setEvaluateValues({
  //       ...evaluateValues,
  //       [name]: e.target.value
  //     })
  //   }
  // }

  // function handleCountryChange (value) {
  //   setEvaluateValues({
  //     ...evaluateValues,
  //     country: value
  //   })
  // }

  // function handleEvaluationSubmit (e) {
  //   e.preventDefault()
  //   console.log('handleEvaluationSubmit')
  // }

  function handleServicesClick (e) {
    e.preventDefault()
    history.push({
      pathname: getBaseUrl() + '/adhoc',
      hash: ''
    })
  }

  const isLoggedIn = !!localStorage.getItem('token')

  return (
    <div className='Home'>
      <div className='Home-header'>
        <div className='Home-header-info'>
          <div className='Home-info'>
            <a href='tel:+32473524565' className='Home-info-group'>
              <Icon name='phone' fill='var(--dark-grey)' width={16} height={16} />
              <p>+32 473 52 45 65</p>
            </a>
            <a href='mailto:info@quidproquo.be' className='Home-info-group'>
              <Icon name='mail' fill='var(--dark-grey)' width={16} height={16} />
              <p>info@quidproquo.be</p>
            </a>
          </div>
          <div>
            {!isLoggedIn
              ? <form className='Home-login' onSubmit={handleLoginSubmit}>
                <Input name='username' placeholder='Username' value={loginValues.username} onChange={handleLoginChange('username')} />
                <Input placeholder='Password' value={loginValues.password} onChange={handleLoginChange('password')} type='password' />
                <Button type='submit' disabled={loading}>{loading ? <Spinner color='white' /> : 'Login'}</Button>
                </form>
              : <div className='Home-services-button'>
                <a onClick={handleServicesClick} href={getBaseUrl() + '/adhoc'}>
                  <Button>Restwaardebox services</Button>
                </a>
                </div>}
          </div>
        </div>
        <div className='Home-header-nav'>
          <img src={`${getBaseUrl()}/images/QPQ.png`} alt='logo QPQ' />
          <div className='Home-nav-buttons'>
            <a href='#about' className={activeAnchor === 'about' ? 'Home-nav-buttons-active' : ''}>About</a>
            <a href='#services' className={activeAnchor === 'services' ? 'Home-nav-buttons-active' : ''}>Services</a>
            <a href='#references' className={activeAnchor === 'references' ? 'Home-nav-buttons-active' : ''}>References</a>
          </div>
        </div>
      </div>
      <div className='Home-body'>
        <div className='Home-body-section'>
          <div className='Home-anchor-target' id='about' />
          <div className='Home-body-center'>
            <h1 ref={aboutRef}>About Quid Pro Quo BV</h1>
            <p>For more than 10 years Quid Pro Quo is providing Residual Value services in the Benelux. Actual and future values for all vehicles are derived from the used car market, based upon statistical analysis and data science to guarantee exact and reliable market prices.<br />Quid Pro Quo is a healthy, flexible and customer focused company to provide a state-of-the-art service.</p>
          </div>
        </div>
        <div className='Home-body-section'>
          <div className='Home-body-center Home-body-certified'>
            <img alt='iso certificate' height='240' src={`${getBaseUrl()}/images/iso_certificate.jpg`} />
            <p className='Home-body-certified-text'>Since 2020 Quid Pro Quo has obtained the ISO27001 certification, guaranteeing the management of information security. In 2023 the ISO27001:2022 has been renewed successfully.</p>
            <img alt='certified icon' src={`${getBaseUrl()}/images/certified.png`} />
          </div>
        </div>
        <div className='Home-body-section'>
          <div className='Home-anchor-target' id='services' />
          <div className='Home-body-center Home-body-services'>
            <h1 ref={servicesRef}>Services</h1>
            <p>All QPQ services are based upon the reliable residual values for now and future and for the different segments in the market: B2C, B2B and C2C. The structure of building the Residual Values for the markets is described in the overview.</p>
            <img className='Home-img-resize' alt='services diagram' src={`${getBaseUrl()}/images/services.png`} />
            <p>QPQ reports residual values towards dealers, holdings, OEM’s, leasing companies, banks, insurances. Every investor has an interest in an accurate estimate of the value of his property. This can serve multiple purposes: Sales, Remarketing, Purchase, Reporting, Benchmarking, Analytics, Bookkeeping.</p>
          </div>
        </div>
        <div className='Home-body-section'>
          <div className='Home-anchor-target' id='references' />
          <div className='Home-body-center'>
            <h1 ref={referencesRef}>References</h1>
            <img className='Home-img-resize' alt='icons of referenced companies' src={`${getBaseUrl()}/images/references.png`} />
          </div>
        </div>
        {/* <div className="Home-body-section">
          <div className="Home-body-center">
            <h1>Free Evaluation</h1>
            <form onSubmit={handleEvaluationSubmit}>
              <div className="Home-form-center">
                <div className="Home-form-select"><Select options={getCountryOptions()} value={evaluateValues.country} onChange={handleCountryChange} /></div>
              </div>
              <div className="Home-form-grid">
                <Input value={evaluateValues.brand} onChange={handleEvaluateFormChange('brand')} label="Brand" placeholder="Brand" />
                <Input value={evaluateValues.model} onChange={handleEvaluateFormChange('model')} label="Model" placeholder="Model" />
                <div className="Home-form-blank"></div>
                <Input value={evaluateValues.fuel} onChange={handleEvaluateFormChange('fuel')} label="Fuel" placeholder="Choose fuel" />
                <Input value={evaluateValues.registration} onChange={handleEvaluateFormChange('registration')} label="First registration" placeholder="dd/mm/yyyy" />
                <Input value={evaluateValues.km} onChange={handleEvaluateFormChange('km')} label="Kilometers" placeholder="100 000" />
                <Input value={evaluateValues.price} onChange={handleEvaluateFormChange('price')} label="Consumer price" placeholder="100 000" />
                <Input value={evaluateValues.options} onChange={handleEvaluateFormChange('options')} label="Options & accessories" placeholder="100 000" />
                <Input value={evaluateValues.totalPrice} onChange={handleEvaluateFormChange('totalPrice')} label="Total consumer price" placeholder="200 000" />
              </div>
              <div className="Home-form-center"><Button className="Home-form-button" type="submit">Calculate</Button></div>
            </form>
          </div>
        </div> */}
      </div>
      <div className='Home-footer'>
        <div className='Home-footer-center'>
          <p>Mark Quatacker</p>
          <a href='tel:+32473524565' className='Home-info-group'>
            <Icon name='phone' fill='white' width={16} height={16} />
            <p>+32 473 52 45 65</p>
          </a>
          <a href='mailto:info@quidproquo.be' className='Home-info-group'>
            <Icon name='mail' fill='white' width={16} height={16} />
            <p>info@quidproquo.be</p>
          </a>
        </div>
      </div>
    </div>
  )
}

export default Home
