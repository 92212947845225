/* eslint-disable no-unused-vars */
import formatCurrency from '../../../../utils/formatCurrency.js'
import formatNumber from '../../../../utils/formatNumber.js'
import Icon from '../../../../components/Icon/Icon.js'
import { useState } from 'react'
import { parse, compareAsc, compareDesc } from 'date-fns'
import PriceDifferenceInPercentage from '../PriceDifferenceInPercentage.js'

const MarketList = ({ data, fleetStatus }) => {
  const [sortColumn, setSortColumn] = useState()
  const [sortDirection, setSortDirection] = useState(0)
  const openNewTab = (url) => {
    if (!url) return
    window.open(url, '_blank')
  }

  const setSortState = (column) => {
    if (sortColumn === column) {
      if (sortDirection > 1) {
        setSortColumn()
        setSortDirection(0)
      } else {
        setSortDirection(sortDirection + 1)
      }
    } else {
      setSortColumn(column)
      setSortDirection(1)
    }
  }

  const getIcon = (column) => {
    if (column !== sortColumn) return 'sort'
    switch (sortDirection) {
      case 0:
        return 'sort'
      case 1:
        return 'sortDown'
      case 2:
        return 'sortUp'
      default:
        return 'sort'
    }
  }

  let list = []
  if (sortColumn) { // sort list
    if (sortColumn === 'firstregistrationdate') {
      console.log(sortDirection)
      if (sortDirection === 1) {
        list = [...data].sort((a, b) => compareAsc(b.firstregistrationdate, a.firstregistrationdate))
      } else if (sortDirection === 2) {
        list = [...data].sort((a, b) => compareDesc(b.firstregistrationdate, a.firstregistrationdate))
      }
    } else {
      if (sortDirection === 1) {
        list = [...data].sort((a, b) => a[sortColumn] - b[sortColumn])
      } else if (sortDirection === 2) {
        list = [...data].sort((a, b) => b[sortColumn] - a[sortColumn])
      }
    }
  } else {
    list = [...data]
  }
  const mileageSortIcon = getIcon('mileage')
  const priceSortIcon = getIcon('price')
  const registrationIcon = getIcon('firstregistrationdate')
  const title = fleetStatus === 'actual' ? 'Actuele markt' : 'Verkocht de afgelopen 90 dagen'
  return (
    <div className='AdhocResult-Results-row'>
      <div className='AdhocResult-Results-header'>
        <h2>{title}</h2>
      </div>
      <table>
        <thead>
          <tr>
            <th colSpan='2'>Merk, model, uitvoering</th>
            <th onClick={() => setSortState('firstregistrationdate')}>Registratie <Icon name={registrationIcon} width={15} height={15} fill='#087CC4' /></th>
            <th onClick={() => setSortState('mileage')}>Km-stand <Icon name={mileageSortIcon} width={15} height={15} fill='#087CC4' /></th>
            <th onClick={() => setSortState('price')}>Vraagprijs <Icon name={priceSortIcon} width={15} height={15} fill='#087CC4' /></th>
          </tr>
        </thead>
        <tbody>
          {list.map((car, index) => {
            return (
              <tr key={'c' + index} className={car.url ? 'MarketAnalysis-Car-Clickable' : ''} onClick={() => openNewTab(car.url)}>
                <td className='picture'>
                  {car.picurl
                    ? (<img src={car.picurl} alt='ad car' />)
                    : (<img src='/images/car_placeholder.svg' alt='ad car placeholder' />)}
                </td>
                <td>
                  <div className='MarketAnalysis-Car-InfoColumn'>
                    <div>{car.brand} - {car.model} - {car.modelyear} - {car.version}</div>
                    <div className='MarketAnalysis-Car-InfoColumn-Sub'>{car.transmission ? `${car.transmission} - ` : ''}{car.fuel} - {car.hp} Hp{car.co2 ? ` - ${car.co2} CO2` : ''}{car.city ? ` - ${car.city}` : ''} - {car.firstregistrationdate}</div>
                  </div>
                </td>
                <td>
                  <div>{car.firstregistrationdate.split('/').slice(1).join('-')}</div>
                </td>
                <td>
                  <div>{formatNumber(car.mileage)} km</div>
                </td>
                <td>
                  <div>{formatCurrency(car.price)} <PriceDifferenceInPercentage className='MarketAnalysis-Car-InfoColumn-Sub' price={car.price} advicedPrice={car.b2cnow} /><br />
                    {car.b2cnow && car.b2cnow !== 0 ? <span className='MarketAnalysis-Car-InfoColumn-Sub grey'>Adviesprijs: {formatCurrency(car.b2cnow)}</span> : null}
                  </div>
                </td>
              </tr>
            )
          })}
        </tbody>
      </table>
    </div>
  )
}

export default MarketList
