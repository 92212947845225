import { useState, useRef, useEffect } from 'react'
import './BatchInput.scss'
import Icon from '../../../components/Icon/Icon.js'
import Button from '../../../components/Button/Button.js'
import Spinner from '../../../components/Spinner/Spinner.js'
import useCountry from '../../../hooks/useCountry.js'
import useHasUnmounted from '../../../hooks/useHasUnmounted.js'
import showErrorMessage from '../../../utils/showErrorMessage.js'
import getErrorMessage from '../../../utils/getErrorMessage.js'
import getSha from '../../../utils/getSha.js'
import api from '../../../api/api.js'

const csvTemplates = {
  be: {
    actual: {
      template: '"klantid";"klantid2";"gebruikerid";"qpqexservice";"land";"vrijveld";"voertuigid";"nummerplaat";"vin";"merk";"model";"uitvoering";"brandstof";"pa_bw";"datum_eerste_registratie";"kilometerstand";"pk";"cc";"co2";"automaat";"energielabel";"doors";"seats";"modeljaar";"consumentenprijs";"totaalconsumentenprijs";"btw";\n"1";"";"operations@";"";"BE";"eigen tekst";"1";"1RXB486";"TMBLD9NEXJ0031834";"SKODA";"Octavia";"Combi Style 1.8TSi GreenTec 180pk/cv 5p 4x4 DSG";"Benzine";"Personenvoertuig";"16/03/2020";"60000";"220";"2400";"145";"1";"A";"5";"5";"1/05/2019";"29557";"31454";"in";\n"1";"";"operations@";"";"BE";"eigen tekst";"2";"1RXS312";"WDD1179081N456561";"MERCEDES";"CLA-Klasse";"CLA200d Shooting Brake AMG-Line 2.1d 136pk/cv 5p";"Diesel";"Personenvoertuig";"21/03/2021";"15000";"180";"3000";"245";"0";"B";"5";"5";"1/01/2018";"28141";"32909";"ex";\n"1";"";"operations@";"";"BE";"eigen tekst";"3";"1SJT922";"W0LBD8EJ1H8077467";"OPEL";"Astra";"Sports Tourer Edition 1.6CDTi 95pk/cv 5p";"Diesel";"Personenvoertuig";"24/05/2019";"77082";"195";"1600";19"5;"0";"C";"5";"5";"1/12/2018";"30390";"32012";"in";\n',
      name: 'RWB_BATCH_ACTUEEL_BE.csv'
    },
    future: {
      template: '"klantid";"klantid2";"gebruikerid";"qpqexservice";"land";"vrijveld";"voertuigid";"nummerplaat";"vin";"merk";"model";"uitvoering";"brandstof";"pa_bw";"datum_eerste_registratie";"kilometerstand";"pk";"cc";"co2";"automaat";"energielabel";"doors";"seats";"modeljaar";"consumentenprijs";"totaalconsumentenprijs";"btw";"contractdatumaanvang";"contractduur";"contractkmaanvang";"contractkmjaar";"curveperiodiciteit";\n"1";"";"operations@";"";"BE";"eigen tekst";"1";"1RXB486";"TMBLD9NEXJ0031834";"SKODA";"Octavia";"Combi Style 1.8TSi GreenTec 180pk/cv 5p 4x4 DSG";"Benzine";"Personenvoertuig";"16/03/2020";"60000";"220";"2400";"145";"1";"A";"5";"5";"1/05/2019";"29557";"31454";"in";"4/03/2022";"48";"60000";"15000";"1";\n"1";"";"operations@";"";"BE";"eigen tekst";"2";"1RXS312";"WDD1179081N456561";"MERCEDES";"CLA-Klasse";"CLA200d Shooting Brake AMG-Line 2.1d 136pk/cv 5p";"Diesel";"Personenvoertuig";"21/03/2021";"15000";"180";"3000";"245";"0";"B";"5";"5";"1/01/2018";"28141";"32909";"ex";"4/03/2022";"72";"15000";"30000";"1";\n"1";"";"operations@";"";"BE";"eigen tekst";"3";"1SJT922";"W0LBD8EJ1H8077467";"OPEL";"Astra";"Sports Tourer Edition 1.6CDTi 95pk/cv 5p";"Diesel";"Personenvoertuig";"24/05/2019";"77082";"195";"1600";"195";"0";"C";"5";"5";"1/12/2018";"30390";"32012";"in";"4/03/2022";"60";"77082";"20000";"1";\n',
      name: 'RWB_BATCH_TOEKOMST_BE.csv'
    }
  },
  nl: {
    actual: {
      template: '"klantid";"klantid2";"gebruikerid";"qpqexservice";"land";"vrijveld";"voertuigid";"kenteken";"vin";"merk";"model";"uitvoering";"brandstof";"pa_bw";"datum_eerste_registratie";"kilometerstand";"pk";"cc";"co2";"automaat";"energielabel";"modeljaar";"bouwjaar";"consumentenprijs";"herberekende_nieuwprijs";"herberekende_nieuwprijs_ex";"nieuwprijs_rdw";"bpm";\n"7";"";"toon";"";"NL";"eigen tekst";"1";"49RZFK";"";"VOLVO";"V70";"2.4 EDITION I";"Benzine";"Personenvoertuig";"13/10/2005";"59684";"220";"2400";"145";"1";"A";"1/05/2003";"";"40330";"44287";"";"45000";"5000";\n"7";"";"toon";"";"NL";"eigen tekst";"1";"6VKS28";"";"CITROEN";"JUMPER";"2.2 HDI 300/3000 L1H1 30";"Diesel";"Bedrijfswagen";"18/12/2010";"14182";"180";"3000";"245";"0";"B";"1/01/2008";"";"31298";"44287";"31792";"26274";"3000";\n"7";"";"toon";"";"NL";"eigen tekst";"1";"73NRNR";"";"TOYOTA";"COROLLA";"1.6 16V VVT-I LINEA LUNA";"Benzine";"Personenvoertuig";"17/01/2004";"77082";"195";"1600";"195";"0";"C";"1/12/2000";"";"22890";"23218";"";"";"";\n',
      name: 'RWB_BATCH_ACTUEEL_NL.csv'
    },
    future: {
      template: '"klantid";"klantid2";"gebruikerid";"qpqexservice";"land";"vrijveld";"voertuigid";"kenteken";"vin";"merk";"model";"uitvoering";"brandstof";"pa_bw";"datum_eerste_registratie";"kilometerstand";"pk";"cc";"co2";"automaat";"energielabel";"modeljaar";"bouwjaar";"consumentenprijs";"herberekende_nieuwprijs";"herberekende_nieuwprijs_ex";"nieuwprijs_rdw";"bpm";"contractdatumaanvang";"contractduur";"contractkmaanvang";"contractkmjaar";"curveperiodiciteit";\n"7";"";"toon";"";"NL";"eigen tekst";"1";"49RZFK";"";"VOLVO";"V70";"2.4 EDITION I";"Benzine";"Personenvoertuig";"13/10/2005";"59684";"220";"2400";"145";"1";"A";"1/05/2003";"";"40330";"44287";"";"45000";"5000";"09/03/2021";"72";"100000";"20000";"1";\n"7";"";"toon";"";"NL";"eigen tekst";"1";"6VKS28";"";"CITROEN";"JUMPER";"2.2 HDI 300/3000 L1H1 30";"Diesel";"Bedrijfswagen";"18/12/2010";"14182";"180";"3000";"245";"0";"B";"1/01/2008";"";"31298";"44287";"31792";"26274";"3000";"09/03/2021";"72";"100000";"20000";"1";\n"7";"";"toon";"";"NL";"eigen tekst";"1";"73NRNR";"";"TOYOTA";"COROLLA";"1.6 16V VVT-I LINEA LUNA";"Benzine";"Personenvoertuig";"17/01/2004";"77082";"195";"1600";"195";"0";"C";"1/12/2000";"";"22890";"23218";"";"";"";"09/03/2021";"72";"100000";"20000";"1";\n',
      name: 'RWB_BATCH_TOEKOMST_NL.csv'
    }
  }
}

const BatchInput = ({ store }) => {
  const [file, setFile] = useState(null)
  const [country] = useCountry('be')
  const inputRef = useRef(null)
  const hasUnmounted = useHasUnmounted(false)

  const [uploadResponse, setUploadResponse] = useState(store.getState().uploadResponse)
  const [loading, setLoading] = useState(store.getState().uploadLoading)

  useEffect(() => {
    if (store) {
      const unsubscribe = store.subscribe(() => {
        const state = store.getState()
        setUploadResponse(state.uploadResponse)
        setLoading(state.uploadLoading)
      })
      return () => unsubscribe()
    }
  }, [store])

  async function handleUpload (file) {
    if (!file) return
    store.updateState({
      uploadLoading: true
    })
    try {
      const response = await api.postResidualvalueAnalyse(
        file,
        {
          sha: getSha('ACTUEEL', country.toUpperCase())
          // accept: 'application/csv' or 'application/json'
        }
      ).json()
      if (!hasUnmounted.current) {
        store.updateState({
          uploadResponse: response
        })
      }
    } catch (error) {
      console.error(error)
      showErrorMessage(await getErrorMessage(error))
    } finally {
      if (!hasUnmounted.current) {
        store.updateState({
          uploadLoading: false
        })
      }
    }
  }

  function handleUploadClick () {
    if (loading) return
    inputRef.current && inputRef.current.click()
  }

  function handleResetClick () {
    setFile(null)
    store.updateState({
      uploadLoading: false,
      uploadResponse: null,
      processLoading: false,
      processType: '',
      processResponse: null
    })
  }

  function handleDownloadTemplateClick (name) {
    return () => {
      try {
        const a = document.createElement('a')
        const csvString = (csvTemplates[country][name] || {}).template || ''
        a.href = 'data:text/csvcharset=utf-8,' + encodeURI(csvString)
        a.download = (csvTemplates[country][name] || {}).name
        if (!a.download) {
          if (name === 'actual') {
            a.download = 'template_actuele_restwaarde.csv'
          } else if (name === 'future') {
            a.download = 'template_toekomstige_restwaarde.csv'
          }
        }
        a.click()
      } catch (error) {
        console.error(error)
        showErrorMessage(error.message)
      }
    }
  }

  function handleFileChange (e) {
    const file = e.target.files[0]
    if (file) {
      setFile(file)
      handleUpload(file)
    } else {
      setFile(null)
    }
    e.target.value = ''
  }

  function handleDragOver (e) {
    e.preventDefault()
    e.dataTransfer.dropEffect = 'copy'
  }

  function handleDrop (e) {
    e.preventDefault()
    const file = e.dataTransfer.files[0]
    if (!file) return
    if (/\.csv$/.test(file.name)) {
      setFile(file)
      handleUpload(file)
    }
  }

  return (
    <div className='BatchInput'>
      <input ref={inputRef} id='file' type='file' onChange={handleFileChange} accept='.csv' />
      <div className='BatchInput-title'>
        <div className='BatchInput-title-circle'>1</div>
        <div className='BatchInput-title-content'>
          <h1>Input
            {!!uploadResponse && <div className='BatchInput-title-line' />}
          </h1>
          <p>Upload de vlootgegevens</p>
        </div>
      </div>
      <div className='BatchInput-body'>
        {!uploadResponse && <>
          <div className='BatchInput-upload' onDragOver={handleDragOver} onDrop={handleDrop}>
            {loading
              ? (
                <div>
                  <p>{file.name} ({file.size / 1000}KB) uploaden en analyseren…</p>
                  <Spinner color='var(--blue)' width={20} height={20} />
                </div>
                )
              : <>
                <Icon name='upload' width={38} height={38} fill='var(--blue)' />
                <p>Sleep het csv-bestand hier om het te uploaden</p>
                <Button onClick={handleUploadClick}>Bestand kiezen</Button>
                </>}
          </div>
          <div className='BatchInput-templates'>
            <p>Het formaat van het csv-bestand kan je als template verkrijgen</p>
            <div className='BatchInput-buttons'>
              <button onClick={handleDownloadTemplateClick('actual')}>Template actuele restwaarde <Icon width={18} height={18} fill='var(--blue)' name='download' /></button>
              <button onClick={handleDownloadTemplateClick('future')}>Template toekomstige restwaarde <Icon width={18} height={18} fill='var(--blue)' name='download' /></button>
            </div>
          </div>
        </>}
        {!!uploadResponse && <div className='BatchInput-complete'>
          <h2>Jouw upload</h2>
          <div className='BatchInput-info'>
            <div className='BatchInput-info-left'>
              <p className='BatchInput-info-text'>{file ? file.name : ''} OK</p>
              <p className='BatchInput-info-text-extra'>{file.size / 1000} KB - {uploadResponse.records} record{uploadResponse.records === 1 ? '' : 's'}</p>
            </div>
            <Icon name='check' fill='var(--light-green)' width={16} height={16} />
          </div>
          {/* <p>De geschatte verwerkingsduur is 193 minuten.</p> */}
          {/* <p>email?</p> */}
          <div className='BatchInput-fill' />
          <button onClick={handleResetClick} className=''>Opnieuw uploaden</button>
        </div>}
      </div>
    </div>
  )
}

export default BatchInput
