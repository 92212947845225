import ky from 'ky'
import getApiHost from '../utils/getApiHost.js'

function getCarInformation ({ licensePlate, vin, exservice }, headers = {}) {
  return ky.get(`${getApiHost()}/v1/vehicleinformation`, {
    headers: {
      authorization: 'Basic ' + window.localStorage.getItem('token'),
      ...headers
    },
    searchParams: {
      ...!!licensePlate && { licenseplate: licensePlate },
      ...!!vin && { vin },
      ...!!exservice && { exservice }
    },
    timeout: false
  })
}

export default getCarInformation
