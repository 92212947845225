import ky from 'ky'
import getApiHost from '../utils/getApiHost.js'

function postResidualvalue (searchParams, file, headers = {}) {
  const formData = new FormData()
  formData.append('file', file)
  return ky.post(`${getApiHost()}/v1/residualvalue`, {
    headers: {
      authorization: 'Basic ' + window.localStorage.getItem('token'),
      ...headers
    },
    searchParams,
    body: formData,
    timeout: false
  })
}

export default postResidualvalue
