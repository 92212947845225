import { useState, useEffect, useRef } from 'react'
import { LineChart, Line, CartesianGrid, XAxis, YAxis, Tooltip, Legend, ResponsiveContainer } from 'recharts'
import Spinner from '../../../../components/Spinner/Spinner.js'
import formatCurrency from '../../../../utils/formatCurrency.js'
import formatNumber from '../../../../utils/formatNumber.js'
import triggerDownload from '../../../../utils/triggerDownload.js'
import showErrorMessage from '../../../../utils/showErrorMessage.js'
import createCsv from '../../../../utils/createCsv.js'
import getErrorMessage from '../../../../utils/getErrorMessage.js'
import useCountry from '../../../../hooks/useCountry.js'
import StatusMessage from '../StatusMessage/StatusMessage.js'
import getSvgForExport from '../getSvgForExport.js'
import getExport from '../getExport.js'
import prepareCsvContent from '../prepareCsvContent.js'
import convertStringToDate from '../convertStringToDate.js'
import getAge from '../getAge.js'
import ScalingGraph from '../../../../components/ScalingGraph/ScalingGraph.js'

const dateFormatter = new Intl.DateTimeFormat('nl-BE', {
  month: 'short',
  year: 'numeric'
})

const Future = ({ results, error }) => {
  const [country] = useCountry('be')
  const result = results.result || {}
  const data = result.future || []
  const userInput = results.userinput || {}
  const [hiddenLines, setHiddenLines] = useState(new Set(['c2c']))
  const graphEl = useRef(null)
  const [scaling, setScaling] = useState('')

  const age = getAge(convertStringToDate(userInput.firstregistrationdate), new Date())

  useEffect(() => {
    const resizeObserver = new ResizeObserver(entries => {
      if (graphEl.current) {
        graphEl.current.style.display = 'none'
        setTimeout(() => {
          graphEl.current.style.display = 'block'
        }, 1)
      }
    })

    const divElem = document.querySelector('div.AdhocInput')

    resizeObserver.observe(divElem)
    return () => resizeObserver.unobserve(divElem)
  }, [])

  function handleShowScaling (market) {
    if (scaling === market) {
      setScaling('')
    } else {
      setScaling(market)
    }
  }

  function getYMin (dataMin) {
    return dataMin - dataMin % 1000
  }

  function getYMax (dataMax) {
    return dataMax - dataMax % 1000 + 1000
  }

  function getTicks () {
    const allValues = []
    data.forEach(item => {
      allValues.push(
        item.b2b,
        item.b2c,
        item.c2c
      )
    })
    const lowest = getYMin(Math.min(...allValues))
    const highest = getYMax(Math.max(...allValues))
    const ticks = [lowest]
    while (ticks.slice(-1)[0] < highest) {
      ticks.push(ticks.slice(-1)[0] + 1000)
      if (ticks.length > 1000) break
    }
    const interval = Math.ceil(ticks.length / 10) || 1
    return ticks.reduce((accumulator, currentValue, index) => {
      return !(index % interval) ? [...accumulator, currentValue] : accumulator
    }, [])
  }

  function formatXAxisTick (tick) {
    return dateFormatter.format(new Date(tick.split('-').reverse().join('-')))
  }

  function handleMouseUp ({ dataKey }) {
    const newSet = new Set(hiddenLines)
    if (newSet.has(dataKey)) {
      newSet.delete(dataKey)
    } else {
      newSet.add(dataKey)
    }
    setHiddenLines(newSet)
  }

  function formatLegend (value) {
    return {
      b2b: 'B2B: Handelsprijs',
      b2c: 'B2C: Consumentenprijs',
      c2c: 'C2C'
    }[value]
  }

  function formatTooltip (value, name, props) {
    function getName (name) {
      return {
        b2b: 'B2B',
        b2c: 'B2C',
        c2c: 'C2C'
      }[name] || name
    }
    if (Object.prototype.toString.apply(value).slice(8, -1) === 'Number') {
      return [formatCurrency(value), getName(name)]
    }
    return [value, getName(name)]
  }

  function handleExportClick (name) {
    return async () => {
      if (name === 'print') {
        window.print()
      }
      if (name === 'csv') {
        triggerDownload({
          hrefValue: 'data:text/csvcharset=utf-8,' + createCsv(...prepareCsvContent(results)),
          fileName: 'restwaarden_per_periode.csv'
        })
      }
      if (name === 'png') {
        if (!graphEl.current) return
        try {
          const response = await getExport({ type: 'image/png', svg: getSvgForExport(graphEl.current) })
          triggerDownload({
            hrefValue: URL.createObjectURL(await response.blob()),
            fileName: 'chart.png'
          })
        } catch (error) {
          console.error(error)
          showErrorMessage(await getErrorMessage(error))
        }
      }
    }
  }

  if (results.loading) return <Spinner />
  if (error.message || error.error) return <p className='AdhocResult-Results-error'>{error.message}</p>

  const now = data[0]
  const end = data.slice(-1)[0]
  const scalingData = end[scaling] || undefined
  const scalingPrice = end[scaling.slice(0, 3)] || undefined
  const scalingBottomBorder = scaling !== '' ? { borderBottom: '2px solid var(--medium-grey)' } : {}

  return (
    <div className='AdhocResult-Results'>
      <div className='AdhocResult-Results-3grid'>
        <div className='AdhocResult-Results-details AdhocResult-Results-3grid-Col'>
          <h5>{userInput.brand || ''} {userInput.model || ''} {userInput.version || ''}</h5>
          <p>Datum eerste registratie: {userInput.firstregistrationdate || ''}{country === 'be' && userInput.vin && ` - VIN: ${userInput.vin}`}{country === 'nl' && userInput.licenseplate && ` - Kenteken: ${userInput.licenseplate}`}</p>
          <p>Leeftijd voertuig: {formatNumber(age)} jaar - Brandstof: {userInput.fuel || ''} - Kilometers: {formatNumber(userInput.mileage) || ''}</p>
        </div>
        <div className='AdhocResult-Results-3grid-Col' />
        <div className='AdhocResult-Results-3grid-Col'>
          <button className='print-Button' onClick={handleExportClick('print')}>print</button>
        </div>
      </div>
      <div className='AdhocResult-Results-info-future'>
        <div className='AdhocResult-Results-info'>
          <p>Pnow: {now.date}</p>
          <p>kmnow: {formatNumber(now.mileage)}</p>
          {country === 'nl' && <p>BPMnow: {formatNumber(now.restbpm)}</p>}
        </div>
        <div className='AdhocResult-Results-info'>
          <p>Pend: {end.date}</p>
          <p>kmend: {formatNumber(end.mileage)}</p>
          {country === 'nl' && <p>BPMend: {formatNumber(end.restbpm)}</p>}
        </div>
      </div>
      <div className='AdhocResult-Results-row'>
        <div className='AdhocResult-Results-header'>
          <h2>Afschrijvingscurve</h2>
          <button onClick={handleExportClick('png')}>png</button>
        </div>
        <div ref={graphEl} className='AdhocResult-Results-graph'>
          <ResponsiveContainer>
            <LineChart data={data} margin={{ left: 20 }}>
              <CartesianGrid stroke='#ccc' />
              <Line
                opacity={hiddenLines.has('b2b') ? 0 : 1}
                activeDot={!hiddenLines.has('b2b')}
                isAnimationActive={false}
                dataKey='b2b'
                stroke='red'
                strokeWidth={2}
              />
              <Line
                opacity={hiddenLines.has('b2c') ? 0 : 1}
                activeDot={!hiddenLines.has('b2c')}
                isAnimationActive={false}
                dataKey='b2c'
                stroke='#087CC4'
                strokeWidth={2}
              />
              <Line
                opacity={hiddenLines.has('c2c') ? 0 : 1}
                activeDot={!hiddenLines.has('c2c')}
                isAnimationActive={false}
                dataKey='c2c'
                stroke='#4CA232'
                strokeWidth={2}
              />
              <XAxis
                dataKey='date'
                tickFormatter={formatXAxisTick}
                padding={{ left: 20, right: 20 }}
                fontSize='13px'
              />
              <YAxis
                width={80}
                fontSize='13px'
                padding={{ bottom: 10, top: 4 }}
                domain={[getYMin, getYMax]}
                label={{
                  value: 'Restwaarde',
                  angle: -90,
                  position: 'insideLeft',
                  offset: -14,
                  fontSize: '13px'
                }}
                ticks={getTicks()}
                interval={0}
                tickFormatter={value => formatCurrency(value)}
              />
              <Tooltip
                separator=': '
                formatter={formatTooltip}
              />
              <Legend
                verticalAlign='bottom'
                height={36}
                onMouseUp={handleMouseUp}
                formatter={formatLegend}
              />
            </LineChart>
          </ResponsiveContainer>
        </div>
      </div>
      <div className='AdhocResult-Results-row'>
        <div className='AdhocResult-Results-header'>
          <h2>Restwaarden per periode</h2>
          <button onClick={handleExportClick('csv')}>csv</button>
        </div>
        <div className='AdhocResult-Results-prices'>
          <div className='AdhocResult-Results-price' style={scaling === 'b2cscaling' ? {} : scalingBottomBorder}>
            <p>B2C</p>
            <div className='AdhocResult-Results-price-list'>{data.map(({ date, b2c, mileage }, i) => <p key={i}>{date} - {formatNumber(mileage)} km - {formatCurrency(b2c)}</p>)}</div>
            {end.b2cscaling
              ? (<button onClick={() => { handleShowScaling('b2cscaling') }}>
                {scaling === 'b2cscaling' ? 'Hide' : ''} RV scaling
                 </button>)
              : null}
          </div>
          <div className='AdhocResult-Results-price' style={scaling === 'b2bscaling' ? {} : scalingBottomBorder}>
            <p>B2B</p>
            <div className='AdhocResult-Results-price-list'>{data.map(({ date, b2b, mileage }, i) => <p key={i}>{date} - {formatNumber(mileage)} km - {formatCurrency(b2b)}</p>)}</div>
            {end.b2bscaling
              ? (<button onClick={() => { handleShowScaling('b2bscaling') }}>
                {scaling === 'b2bscaling' ? 'Hide' : ''} RV scaling
                 </button>)
              : null}
          </div>
          <div className='AdhocResult-Results-price' style={scalingBottomBorder}>
            <p>C2C</p>
            <div className='AdhocResult-Results-price-list'>{data.map(({ date, c2c, mileage }, i) => <p key={i}>{date} - {formatNumber(mileage)} km - {formatCurrency(c2c)}</p>)}</div>
          </div>
        </div>
        <ScalingGraph scalingData={scalingData} price={scalingPrice} />
      </div>
      <StatusMessage results={results} />
    </div>
  )
}

export default Future
