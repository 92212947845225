import { useRef } from 'react'
import './InputRadio.scss'
import PropTypes from 'prop-types'

const InputRadio = ({ name, value, onChange = () => {}, label = '', checked, disabled = false }) => {
  const inputRef = useRef(null)

  function handleChange (e) {
    if (inputRef.current && inputRef.current !== e.target) {
      inputRef.current.click()
    }
  }

  return (
    <div className={`InputRadio ${disabled ? 'InputRadio-disabled' : ''}`} onClick={handleChange}>
      <input ref={inputRef} type="radio" onChange={onChange} value={value} name={name} checked={checked} disabled={disabled} />
      <div className={`InputRadio-circle ${checked ? 'InputRadio-circle-checked' : ''}`}></div>
      <label>{label}</label>
    </div>
  )
}

InputRadio.propTypes = {
  name: PropTypes.string,
  value: PropTypes.string,
  onChange: PropTypes.func,
  checked: PropTypes.bool,
  label: PropTypes.string,
  disabled: PropTypes.bool
}

export default InputRadio
