function getUser () {
  let user = {}
  try {
    user = JSON.parse(window.localStorage.getItem('user'))
    if (Object.prototype.toString.apply(user).slice(8, -1) !== 'Object') {
      user = {}
    }
  } catch (error) {}
  return user
}

export default getUser
