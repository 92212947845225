import React, { useState } from 'react'
import { APIProvider, Map, InfoWindow, useAdvancedMarkerRef, AdvancedMarker, Pin } from '@vis.gl/react-google-maps'
import formatCurrency from '../../../../../utils/formatCurrency.js'
import PriceDifferenceInPercentage from '../../PriceDifferenceInPercentage.js'

const numberFormat = (price) => {
  return formatCurrency(price).replace('.000', 'K')
}

const MarkerWithInfoWindow = ({ car }) => {
  const [showInfoWindow, setShowInfoWindow] = useState(false)
  const [markerRef, marker] = useAdvancedMarkerRef()
  return (
    <div key={car.internalId}>
      <AdvancedMarker ref={markerRef} position={car.location.location} onClick={() => setShowInfoWindow(!showInfoWindow)}>
        <Pin background={car.colour} glyphColor='#000' borderColor='#000' />
      </AdvancedMarker>
      {showInfoWindow
        ? (
          <InfoWindow anchor={marker} onClose={() => setShowInfoWindow(false)}>
            <div className='tooltip'>
              <div className='picture'>
                {car.picurl
                  ? (<img src={car.picurl} alt='ad car' />)
                  : (<img src='/images/car_placeholder.svg' alt='ad car placeholder' />)}
              </div>
              <div className='MarketAnalysis-Car-InfoColumn'>
                <div>{car.brand} - {car.model} - {car.modelyear} - {car.version}</div>
                <div className='MarketAnalysis-Car-InfoColumn-Sub'>{car.transmission ? `${car.transmission} - ` : ''}{car.fuel} - {car.hp} Hp{car.co2 ? ` - ${car.co2} CO2` : ''}{car.city ? ` - ${car.city}` : ''} - {car.firstregistrationdate}</div>
              </div>
              <div>
                Vraagprijs: {numberFormat(car.price)} <PriceDifferenceInPercentage className='MarketAnalysis-Car-InfoColumn-Sub' price={car.price} advicedPrice={car.b2cnow} />
              </div>
              <div>
                Adviesprijs: {numberFormat(car.b2cnow)}
              </div>
            </div>
          </InfoWindow>
          )
        : null}
    </div>
  )
}

const MarketLocation = ({ results }) => {
  const marketCarsWithLocation = results.market.filter(car => car.location)
  const { northeast, southwest } = marketCarsWithLocation.reduce((acc, car) => {
    if (!car.location) return acc
    acc.northeast.lat = car.location.viewport.northeast.lat > acc.northeast.lat ? car.location.viewport.northeast.lat : acc.northeast.lat
    acc.northeast.lng = car.location.viewport.northeast.lng > acc.northeast.lng ? car.location.viewport.northeast.lng : acc.northeast.lng
    acc.southwest.lat = car.location.viewport.southwest.lat < acc.southwest.lat ? car.location.viewport.southwest.lat : acc.southwest.lat
    acc.southwest.lng = car.location.viewport.southwest.lng < acc.southwest.lng ? car.location.viewport.southwest.lng : acc.southwest.lng
    return acc
  }, { northeast: { lat: 0, lng: 0 }, southwest: { lat: 90, lng: 90 } })
  const center = {
    lat: southwest.lat + ((northeast.lat - southwest.lat) / 2),
    lng: southwest.lng + ((northeast.lng - southwest.lng) / 2)
  }
  console.log(marketCarsWithLocation)

  return (
    <div className='AdhocResult-Results-row'>
      <div className='AdhocResult-Results-header'>
        <h2>Geografisch</h2>
      </div>
      <div style={{ height: '550px', width: '100%' }}>
        <APIProvider apiKey='AIzaSyB6J6WaT1ZzuC5MWnphKcNZ-M_ZEyrf6QQ'>
          <Map
            style={{ height: '550px', width: '100%' }}
            defaultCenter={center}
            defaultZoom={8}
            gestureHandling='greedy'
            disableDefaultUI
            mapId='c610705a836ed789'
          >
            {marketCarsWithLocation.map((car, i) => {
              return (
                <MarkerWithInfoWindow key={car.internalId} car={car} />
              )
            })}
          </Map>
        </APIProvider>
      </div>
    </div>
  )
}

export default MarketLocation
