import ky from 'ky'
import getApiHost from '../utils/getApiHost.js'

function getJatoModels ({ brand, exsid }) {
  const searchParams = {
    source: 'jato',
    faceted: true,
    exsid
  }

  return ky.get(`${getApiHost()}/v1/${brand}/models`, {
    headers: {
      authorization: 'Basic ' + window.localStorage.getItem('token')
    },
    searchParams,
    timeout: false
  })
}

export default getJatoModels
