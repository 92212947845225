/* eslint-disable no-unused-vars */
import formatCurrency from '../../../../../utils/formatCurrency.js'
import './PriceHistory.scss'
import { XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer, Scatter, ScatterChart } from 'recharts'
import { parse, format, startOfMonth, eachMonthOfInterval, addMonths, startOfWeek, eachWeekOfInterval, addWeeks } from 'date-fns'
import PriceDifferenceInPercentage from '../../PriceDifferenceInPercentage.js'

const timeFormat = (utcNr) => {
  const date = parse(utcNr, 'T', new Date())
  return format(date, 'd LLL')
}

const numberFormat = (price) => {
  return formatCurrency(price).replace('.000', 'K')
}

const getTicks = (lowest, highest) => {
  let tickSpacing = 5000
  if ((highest - lowest) / tickSpacing < 8) {
    tickSpacing = 2000
  }
  if ((highest - lowest) / tickSpacing < 8) {
    tickSpacing = 1000
  }
  lowest = Math.floor(lowest / tickSpacing) * tickSpacing
  const ticks = [lowest]
  while (ticks.slice(-1)[0] < highest) {
    ticks.push(ticks.slice(-1)[0] + tickSpacing)
    if (ticks.length > tickSpacing) break
  }
  const interval = Math.ceil(ticks.length / 10) || 1
  return ticks.reduce((accumulator, currentValue, index) => {
    return !(index % interval) ? [...accumulator, currentValue] : accumulator
  }, [])
}

const getMonthTicks = (firstUTCDate, lastUTCDate) => {
  const firstDate = new Date(firstUTCDate)
  const lastDate = new Date(lastUTCDate)
  const firstMonth = startOfMonth(firstDate)
  const lastMonth = startOfMonth(addMonths(lastDate, 1))
  const listOfMonths = eachMonthOfInterval({ start: firstMonth, end: lastMonth })
  const firstWeek = startOfWeek(firstDate, { weekStartsOn: 1 })
  const lastWeek = startOfWeek(addWeeks(lastDate, 1))
  const listOfWeeks = eachWeekOfInterval({ start: firstWeek, end: lastWeek }, { weekStartsOn: 1 })
  let list = listOfMonths
  if (list.length < 6) {
    list = listOfWeeks
  }
  // replace first date and replace with firstDate
  list[0] = firstDate
  // replace last date and replace with lastDate
  list[list.length - 1] = lastDate
  return list.map(date => format(date, 'T'))
}

const colours = [
  '#9e0142',
  '#d53e4f',
  '#f46d43',
  '#fdae61',
  '#fee08b',
  '#e6f598',
  '#abdda4',
  '#66c2a5',
  '#3288bd',
  '#5e4fa2'
]

const MarketAnalysis = ({ results, error }) => {
  const priceArray = []
  const dateArray = []
  const data = results.market.filter(car => Array.isArray(car.pricehistory)).map(car => {
    car.pricehistory = car.pricehistory.map(({ date, price }) => {
      priceArray.push(price)
      const utc = format(parse(date, 'dd-MM-yyyy', new Date()), 'T')
      dateArray.push(utc)
      return {
        date, price, utc
      }
    })
    return car
  })
  const maxPrice = (Math.ceil(Math.max(...priceArray) / 1000) * 1000)
  const minPrice = (Math.floor(Math.min(...priceArray) / 1000) * 1000)
  const firstUTCDate = Math.min(...dateArray)
  const lastUTCDate = Math.max(...dateArray)
  const lastDate = new Date(lastUTCDate)
  const [firstMonth] = getMonthTicks(firstUTCDate, lastUTCDate)
  const lastMonth = format(startOfMonth(addMonths(lastDate, 1)), 'T')

  const Customtooltip = ({ active, payload, label }) => {
    if (active && payload && payload.length) {
      console.log(payload, label)
      const vehicleId = payload[0].payload.vehicleid
      const car = data.find(car => car.vehicleid === vehicleId)
      return (
        <div className='tooltip'>
          <div className='picture'>
            {car.picurl
              ? (<img src={car.picurl} alt='ad car' />)
              : (<img src='/images/car_placeholder.svg' alt='ad car placeholder' />)}
          </div>
          <div className='MarketAnalysis-Car-InfoColumn'>
            <div>{car.brand} - {car.model} - {car.modelyear} - {car.version}</div>
            <div className='MarketAnalysis-Car-InfoColumn-Sub'>{car.transmission ? `${car.transmission} - ` : ''}{car.fuel} - {car.hp} Hp{car.co2 ? ` - ${car.co2} CO2` : ''}{car.city ? ` - ${car.city}` : ''} - {car.firstregistrationdate}</div>
          </div>
          <div>
            Vraagprijs: {numberFormat(car.price)} <PriceDifferenceInPercentage price={car.price} advicedPrice={car.b2cnow} />
          </div>
          {car.b2cnow && car.b2cnow !== 0
            ? <div>
              Adviesprijs: {numberFormat(car.b2cnow)}
              </div>
            : null}
        </div>
      )
    }
    return null
  }

  if (data.length === 0) {
    return (
      <div className='AdhocResult-Results-row'>
        <div className='AdhocResult-Results-header'>
          <h2>Prijshistorie</h2>
          Geen historie beschikbaar.
        </div>
      </div>
    )
  }

  return (
    <div className='AdhocResult-Results-row'>
      <div className='AdhocResult-Results-header'>
        <h2>Prijshistorie</h2>
      </div>
      <div className='AdhocResult-Results-PriceHistory-graph'>
        <ResponsiveContainer>
          <ScatterChart height={500} margin={{ left: 20 }}>
            <CartesianGrid strokeDasharray='3 3' />
            <XAxis
              type='number'
              dataKey='utc'
              name='date'
              domain={[firstMonth, lastMonth]}
              ticks={getMonthTicks(firstUTCDate, lastUTCDate)}
              tickFormatter={timeFormat}
              interval='preserveStartEnd'
              padding={{ left: 20, right: 20 }}
              fontSize='13px'
            />
            <YAxis
              type='number'
              dataKey='price'
              name='price'
              domain={[minPrice, maxPrice]}
              label={{
                value: 'Vraagprijs',
                angle: -90,
                position: 'insideLeft',
                offset: -14,
                fontSize: '13px'
              }}
              tickFormatter={numberFormat}
              interval={0}
              ticks={getTicks(minPrice, maxPrice)}
              padding={{ bottom: 10, top: 15 }}
              fontSize='13px'
            />
            {data.map((s, i) => {
              const priceHistory = s.pricehistory.map(p => {
                p.vehicleid = s.vehicleid
                return p
              })
              return (
                <Scatter
                  dataKey='price'
                  name={s.vehicleid}
                  data={priceHistory}
                  fill={s.colour}
                  line
                  strokeWidth={2}
                  key={s.vehicleid}
                />
              )
            })}
            <Tooltip content={<Customtooltip />} />
          </ScatterChart>
        </ResponsiveContainer>
      </div>
    </div>

  )
}

export default MarketAnalysis
