import { useState, useEffect, useMemo } from 'react'
import './SearchSelect.scss'
import PropTypes from 'prop-types'
import Icon from '../Icon/Icon.js'
import Input from '../Input/Input.js'

const SearchSelect = ({ label, value, onChange, options = [], placeholder = 'Kies een optie', className = '', id, valid, disabled = false }) => {
  const [isOpen, setIsOpen] = useState(false)
  const [focussed, setFocussed] = useState(options.findIndex(option => option === value))
  const [inputValue, setInputValue] = useState(value)
  id = useMemo(() => { return id || 'SearchSelect-' + (Math.round(Math.random() * 10000000)).toString() }, [id])

  const filteredOptions = options.filter(option => ~(option || '').toLowerCase().indexOf((inputValue || '').toLowerCase()))

  useEffect(() => {
    setInputValue(value)
  }, [value])

  useEffect(() => {
    function handleWindowClick (e) {
      if (!e.target.closest('#' + id)) {
        setIsOpen(false)
      }
    }

    if (isOpen) {
      window.addEventListener('click', handleWindowClick)
    }

    return () => {
      window.removeEventListener('click', handleWindowClick)
    }
  }, [isOpen, id])

  useEffect(() => {
    function handleKeyPress (e) {
      switch (e.code) {
        case 'ArrowDown': {
          setFocussed((focussed + 1 + filteredOptions.length) % filteredOptions.length)
          break
        }
        case 'ArrowUp': {
          setFocussed((focussed - 1 + filteredOptions.length) % filteredOptions.length)
          break
        }
        case 'Escape': {
          setIsOpen(false)
          break
        }
        case 'Enter': {
          onChange(filteredOptions[focussed])
          setIsOpen(false)
          break
        }
        default:
      }
    }

    if (isOpen) {
      window.addEventListener('keydown', handleKeyPress)
    }

    return () => {
      window.removeEventListener('keydown', handleKeyPress)
    }
  }, [isOpen, focussed, onChange, filteredOptions])

  function handleOptionClick (option) {
    return () => {
      onChange(option)
      setIsOpen(false)
    }
  }

  function handleChange (e) {
    setInputValue(e.target.value)
    onChange(e.target.value)
  }

  return (
    <div className={`SearchSelect ${className}`} id={id}>
      <Input placeholder={placeholder} label={label} value={inputValue} onChange={handleChange} onClick={() => setIsOpen(true)} valid={valid || new Set(options).has(inputValue)} disabled={disabled} />
      {isOpen &&
        <div className='SearchSelect-options'>
          {filteredOptions.map((option, i) => <Option key={i} label={option} onSearchSelect={handleOptionClick(option)} selected={value === option} focussed={focussed === i} setFocussed={() => setFocussed(i)} />)}
        </div>}
    </div>
  )
}

const Option = ({ label, onSearchSelect, selected, customLabel, focussed, setFocussed }) => {
  return (
    <div className={`SearchSelect-option ${focussed ? 'SearchSelect-option-focussed' : ''}`} onClick={onSearchSelect} onMouseEnter={() => setFocussed()}>
      {customLabel || <p>{label}</p>}
      {selected && <Icon className='SearchSelect-option-check' name='check' fill='var(--green)' />}
    </div>
  )
}

SearchSelect.propTypes = {
  label: PropTypes.string,
  value: PropTypes.any,
  id: PropTypes.string,
  onChange: PropTypes.func,
  options: PropTypes.arrayOf(PropTypes.string),
  placeholder: PropTypes.string
}

export default SearchSelect
