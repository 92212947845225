import ky from 'ky'

function getExport ({ type, svg }) {
  return ky.post('https://export.highcharts.com/', {
    json: {
      filename: 'chart',
      type,
      width: 800,
      svg
    }
  })
}

export default getExport
