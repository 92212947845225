import UniversalRouter from 'universal-router'
import routes from './routes.js'
import history from './history.js'
import React from 'react'
import { createRoot } from 'react-dom/client'
import App from './components/App/App.js'
import NotFound from './pages/NotFound/NotFound.js'
import ErrorPage from './pages/ErrorPage/ErrorPage.js'
import getBaseUrl from './utils/getBaseUrl.js'

const baseUrl = getBaseUrl()
const container = document.getElementById('root')
const root = createRoot(container)

const options = {
  baseUrl,
  errorHandler (error, context) {
    console.error(error)
    console.info(context)
    if (error.status === 404) {
      return <NotFound />
    } else {
      return <ErrorPage />
    }
  }
}
const router = new UniversalRouter(routes, options)

function renderLocation (location) {
  const destination = location.pathname

  const hasToken = !!window.localStorage.getItem('token')

  const noAuthDestinations = new Set([baseUrl, baseUrl + '/'])
  if (!hasToken && (!noAuthDestinations.has(destination))) {
    return history.push(baseUrl + '/')
  // } else if (hasToken && noAuthDestinations.has(destination)) {
  //   return history.push(baseUrl + '/adhoc')
  } else if (destination === baseUrl) {
    return history.push(baseUrl + '/')
  }

  router.resolve({
    pathname: destination,
    context: {}
  }).then(component => {
    if (component.redirect) {
      history.push(component.redirect)
    } else {
      root.render(
        <React.StrictMode>
          <App>{component}</App>
        </React.StrictMode>
      )
    }
  })
}

// Listen for changes to the current location.
// const unlisten =
history.listen(({ action, location }) => {
  renderLocation(location)
})

// To stop listening, call the function returned from listen().
// unlisten()

// Render the app for the first time
async function initialize () {
  renderLocation(history.location)
}

initialize()
