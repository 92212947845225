import { useEffect, useRef } from 'react'
import Icon from '../Icon/Icon.js'
import './Modal.scss'

let modals = []

const Modal = ({ children, open, closeModal, title }) => {
  const myId = useRef(`Modal-${title}-${Math.round(Math.random() * 100000)}`)
  const mousedownOutsideBox = useRef(null)

  useEffect(() => {
    mousedownOutsideBox.current = false
    if (open) {
      modals.push(myId.current)
    } else {
      modals = modals.filter(id => myId.current !== id)
    }
  }, [open])

  useEffect(() => {
    if (!open) return

    function handleDownClick (e) {
      if (e.target.id === myId.current) {
        mousedownOutsideBox.current = true
      }
    }

    function handleClick (e) {
      if (e.target.classList.contains('Modal') && e.target.id === myId.current && mousedownOutsideBox.current) {
        closeModal()
      }
      mousedownOutsideBox.current = false
    }
    function handleKey (e) {
      if (e.key === 'Escape' && modals[modals.length - 1] === myId.current) {
        closeModal()
      }
    }

    window.addEventListener('mousedown', handleDownClick)
    window.addEventListener('click', handleClick)
    window.addEventListener('keyup', handleKey)

    return () => {
      window.removeEventListener('mousedown', handleDownClick)
      window.removeEventListener('click', handleClick)
      window.removeEventListener('keyup', handleKey)
    }
  }, [open, closeModal])

  return (
    <div className={`Modal ${open ? 'Modal-open' : ''}`} id={myId.current}>
      <div className="Modal-body">
        <div className="Modal-title">
          <h1>{title}</h1>
          <button className="Modal-close" onClick={closeModal}><Icon name="cross" width={32} height={32} fill="var(--super-dark-grey)" /></button>
        </div>
        {children}
      </div>
    </div>
  )
}

export default Modal
