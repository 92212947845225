import { useState } from 'react'
import Spinner from '../../../../components/Spinner/Spinner.js'
import formatCurrency from '../../../../utils/formatCurrency.js'
import formatNumber from '../../../../utils/formatNumber.js'
import StatusMessage from '../StatusMessage/StatusMessage.js'
import useCountry from '../../../../hooks/useCountry.js'
import ScalingGraph from '../../../../components/ScalingGraph/ScalingGraph.js'
import getAge from '../getAge.js'

const Actual = ({ results, error }) => {
  const [country] = useCountry('be')
  const [scaling, setScaling] = useState('')

  function handleExportClick (name) {
    return async () => {
      if (name === 'print') {
        window.print()
      }
    }
  }

  function handleShowScaling (market) {
    if (scaling === market) {
      setScaling('')
    } else {
      setScaling(market)
    }
  }

  if (results.loading) return <Spinner />
  if (error.message || error.error) return <p className='AdhocResult-Results-error'>{error.message}</p>
  const result = results.result || {}
  const data = result.actual || {}
  const userInput = results.userinput || {}
  const scalingData = data[scaling] || undefined
  const scalingPrice = data[scaling.slice(0, 3)] || undefined
  const scalingBottomBorder = scaling !== '' ? { borderBottom: '2px solid var(--medium-grey)' } : {}

  const startDate = (userInput.firstregistrationdate || '').split('-').reverse()
  startDate[1]--
  const age = getAge(new Date(...startDate), new Date(), 1)// Math.round(((new Date().getTime() - new Date(...startDate).getTime()) / 1000 / 60 / 60 / 24 / 365) * 1000) / 1000

  return (
    <div className='AdhocResult-Results'>
      <div className='AdhocResult-Results-3grid'>
        <div className='AdhocResult-Results-details AdhocResult-Results-3grid-Col'>
          <h5>{userInput.brand || ''} {userInput.model || ''} {userInput.version || ''}</h5>
          <p>Datum eerste registratie: {userInput.firstregistrationdate || ''}{country === 'be' && userInput.vin && ` - VIN: ${userInput.vin}`}{country === 'nl' && userInput.licenseplate && ` - Kenteken: ${userInput.licenseplate}`}</p>
          <p>Leeftijd voertuig: {formatNumber(age)} jaar - Brandstof: {userInput.fuel || ''} - Kilometers: {formatNumber(userInput.mileage) || ''}</p>
        </div>
        <div className='AdhocResult-Results-3grid-Col' />
        <div className='AdhocResult-Results-3grid-Col'>
          <button className='print-Button' onClick={handleExportClick('print')}>print</button>
        </div>
      </div>

      <div className='AdhocResult-Results-info'>
        <p>Pnow: {data.date || ''}</p>
        <p>kmnow: {formatNumber(data.mileage || '')}</p>
        {country === 'nl' && <p>BPMnow: {formatNumber(data.restbpm || '')}</p>}
      </div>
      <div className='AdhocResult-Results-prices'>
        <div className='AdhocResult-Results-price' style={scaling === 'b2cscaling' ? {} : scalingBottomBorder}>
          <p>B2C</p>
          <p className='AdhocResult-Results-price-value'>{formatCurrency(data.b2c + '' || '')}</p>
          {data.b2cscaling
            ? (<button onClick={() => { handleShowScaling('b2cscaling') }}>
              {scaling === 'b2cscaling' ? 'Hide' : ''} RV scaling
            </button>)
            : null}
        </div>
        <div className='AdhocResult-Results-price' style={scaling === 'b2bscaling' ? {} : scalingBottomBorder}>
          <p>B2B</p>
          <p className='AdhocResult-Results-price-value'>{formatCurrency(data.b2b + '' || '')}</p>
          {data.b2bscaling
            ? (
              <button onClick={() => { handleShowScaling('b2bscaling') }}>
                {scaling === 'b2bscaling' ? 'Hide' : ''} RV scaling
              </button>
              )
            : null}
        </div>
        {data.tradein
          ? (
            <div className='AdhocResult-Results-price' style={scalingBottomBorder}>
              <p>Inruilwaarde</p>
              <p className='AdhocResult-Results-price-value'>{formatCurrency(data.tradein + '' || '')}</p>
            </div>
            )
          : (
            <div className='AdhocResult-Results-price' style={scalingBottomBorder}>
              <p>C2C</p>
              <p className='AdhocResult-Results-price-value'>{formatCurrency(data.c2c + '' || '')}</p>
            </div>
            )}
      </div>
      <ScalingGraph scalingData={scalingData} price={scalingPrice} />
      <StatusMessage results={results} />
    </div>
  )
}

export default Actual
