import getUser from './getUser.js'

function canUseService (services) {
  const user = getUser()
  const myServices = new Set(user.exservices || [])

  for (const service of services) {
    if (myServices.has(service)) return true
  }

  return false
}

export default canUseService
