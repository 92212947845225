import getUser from './getUser.js'

function getSha (service, country) {
  let user = {}
  try {
    user = getUser()
  } catch (error) {}
  if (user.magicSha) return user.magicSha
  const settings = (user.partnerManagement || []).find((management = {}) => management.service === service && management.country === country) || {}
  return settings.fixedsha || ''
}

export default getSha
