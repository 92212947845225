/* eslint-disable no-unused-vars */
import './BrandModelModal.scss'
import { useState, useEffect } from 'react'
import Button from '../../../../components/Button/Button.js'
import Modal from '../../../../components/Modal/Modal.js'
import { getInitialDate } from '../../AdHoc.js'
import api from '../../../../api/api.js'
import FacetedSearchSelect from '../../../../components/FacetedSearchSelect/FacetedSearchSelect.js'
import FacetedSelect from '../../../../components/FacetedSelect.js/FacetedSelect.js'
import formatCurrency from '../../../../utils/formatCurrency.js'
import formatNumber from '../../../../utils/formatNumber.js'
import Spinner from '../../../../components/Spinner/Spinner.js'

function translateFuel (name) {
  switch (name) {
    case 'B': {
      return 'Benzine'
    }
    case 'D': {
      return 'Diesel'
    }
    case 'E': {
      return 'Electrisch'
    }
    case 'H': {
      return 'Hybride'
    }
    case 'G': {
      return 'Gas'
    }
    case 'BE': {
      return 'Benzine/Electrisch'
    }
    case 'DE': {
      return 'Diesel/Electrisch'
    }
    case 'BG': {
      return 'Benzine/Gas'
    }
    default:
      return name
  }
}

function reTranslateFuel (fuel) {
  switch (fuel) {
    case 'Benzine': {
      return 'B'
    }
    case 'Diesel': {
      return 'D'
    }
    case 'Electrisch': {
      return 'E'
    }
    case 'Hybride': {
      return 'H'
    }
    case 'Gas': {
      return 'G'
    }
    case 'Benzine/Electrisch': {
      return 'BE'
    }
    case 'Diesel/Electrisch': {
      return 'DE'
    }
    case 'Benzine/Gas': {
      return 'BG'
    }
    default:
      return fuel
  }
}

const BrandModelModal = ({ isOpen, onCloseModal, setValues }) => {
  const [make, setMake] = useState('')
  const [model, setModel] = useState('')
  const [fuel, setFuel] = useState('')
  const [modelyear, setModelyear] = useState('')
  const [version, setVersion] = useState('')
  const [gearbox, setGearbox] = useState('')
  const [cc, setCc] = useState('')
  const [kw, setKw] = useState('')
  const [co2, setCo2] = useState('')
  const [selectedItem, setSelectedItem] = useState({})
  const [facets, setFacets] = useState({})
  const [brandsFacets, setBrandsFacets] = useState({})
  const [modelsFacets, setModelsFacets] = useState({})
  const [totalCarsInJato, setTotalCarsInJato] = useState(0)
  const [total, setTotal] = useState(0)
  const [cars, setCars] = useState([])
  const [loadingCars, setLoadingCars] = useState(false)

  // load Brands
  useEffect(() => {
    const fetchBrands = async () => {
      const results = await api.getJatoBrands().json()
      results.brands = []
      for (const brand of results.facets) {
        const [key, value] = Object.entries(brand)[0]
        results.brands.push({ label: key, count: value })
      }
      setBrandsFacets(results)
      setTotal(results.total)
      setTotalCarsInJato(results.total)
    }
    fetchBrands()
  }, [])

  // load Models
  useEffect(() => {
    const fetchModels = async () => {
      const results = await api.getJatoModels({ brand: make, exsid: brandsFacets.exsid }).json()
      results.models = []
      for (const model of results.facets) {
        const [key, value] = Object.entries(model)[0]
        results.models.push({ label: key, count: value })
      }
      setModelsFacets(results)
      setTotal(results.total)
    }
    if (make !== '' && brandsFacets.brands.findIndex(({ label }) => label === make) >= 0) {
      setCars([])
      setTotal(brandsFacets.brands.find(({ label }) => label === make)?.count)
      setModelsFacets({})
      fetchModels()
    } else {
      setModel('')
      setModelsFacets({})
      setFacets({})
      setFuel('')
      setModelyear('')
      setVersion('')
      setGearbox('')
      setCc('')
      setKw('')
      setCo2('')
      setTotal(totalCarsInJato)
      setCars([])
      setSelectedItem({})
    }
  }, [make])

  // load Facets
  useEffect(() => {
    const fetchFacets = async () => {
      const results = await api.getJatoFacets({
        brand: make,
        exsid: brandsFacets.exsid,
        model,
        modelyear,
        fuel: reTranslateFuel(fuel),
        version,
        gearbox,
        kw,
        cc,
        co2
      }).json()
      console.log(results)
      const uniqueByValues = [...results.data.reduce((accumulator, car) => {
        const carString = '' + car.fuel + car.gearbox + car.modelyear + car.version + car.cc + car.kw + car.co2 + car.cataloguePrice
        car._id = car._id[0] // use first
        accumulator.set(carString, car)
        return accumulator
      }, new Map()).values()]
      const uniqueById = [...new Map(uniqueByValues.map(item => [item._id, item])).values()]
      const sortedData = uniqueById.sort((a, b) => b._id > a._id)
      const facets = {}
      for (const key in results.facets) {
        if (Object.hasOwnProperty.call(results.facets, key)) {
          const facet = results.facets[key].count
          facets[key] = []
          for (const [label, value] of Object.entries(facet)) {
            if (key === 'fuel') {
              facets[key].push({ label: translateFuel(label), count: value })
            } else {
              facets[key].push({ label, count: value })
            }
          }
        }
      }
      setCars(sortedData)
      setFacets(facets)
      setTotal(results.total)
      setLoadingCars(false)
    }

    if (model !== '' &&
    modelsFacets.models.findIndex(({ label }) => label === model) >= 0) {
      setLoadingCars(true)
      setTotal(modelsFacets.models.find(({ label }) => label === model)?.count)
      fetchFacets()
    } else if (model !== '') {
      // when typing in the search select
    } else {
      setModelsFacets({})
      setFacets({})
      setFuel('')
      setModelyear('')
      setVersion('')
      setGearbox('')
      setCc('')
      setKw('')
      setCo2('')
      setTotal(totalCarsInJato)
      setCars([])
      setSelectedItem({})
    }
  }, [model, modelyear, fuel, gearbox, version, kw, co2, cc])

  // reset to default values
  useEffect(() => {
    if (!isOpen) {
      setMake('')
      setModel('')
      setFuel('')
      setModelyear('')
      setVersion('')
      setGearbox('')
      setCc('')
      setKw('')
      setCo2('')
      setSelectedItem({})
    }
  }, [isOpen])

  function handleItemClick (item) {
    setSelectedItem(item)
  }

  function handleConfirmClick () {
    if (!selectedItem) return
    if (selectedItem.kw) {
      selectedItem.pk = Math.round(selectedItem.kw * 1.362)
    }
    setValues({
      merk: make || '',
      model: model || '',
      uitvoering: selectedItem.version || '',
      brandstof: selectedItem.fuel || '',
      registratie: '',
      km: '',
      cc: (selectedItem.cc || '') + '',
      pk: (selectedItem.pk || '') + '',
      co2: (selectedItem.co2 || '') + '',
      batterycapacity: (selectedItem.batterycapacity || '') + '',
      modelJaarMonth: selectedItem.modelyear ? '7' : '',
      modelJaarYear: selectedItem.modelyear,
      modelJaar: selectedItem.modelyear ? `7-${selectedItem.modelyear}` : '',
      prijs: (selectedItem.cataloguePrice || '') + '',
      opties: '',
      totaalPrijs: '',
      vat: 'in',
      transmissie: selectedItem.gearbox === 'M' ? 'manual' : selectedItem.gearbox === 'A' ? 'automatic' : 'manual',
      aanvang: getInitialDate(),
      duur: '',
      kmAanvang: '',
      kmJaar: '',
      periode: '2',
      pabw: 'passengercar',
      exs: 'MMJATO'
    })
    onCloseModal()
  }

  return (
    <Modal open={isOpen} closeModal={onCloseModal} title='Merk/Model selectie'>
      <div className='ModelModal'>
        <div className='ModelModal-form'>
          <div className='ModelModal-form-content'>
            {model !== '' && modelsFacets.models && modelsFacets.models.findIndex(({ label }) => label === model) >= 0
              ? <>
                <FacetedSearchSelect label='Version' placeholder='version' options={facets.version} value={version} onChange={setVersion} disabled={loadingCars} />
                <FacetedSelect label='Brandstof' placeholder='fuel' options={facets.fuel} value={fuel} onChange={setFuel} disabled={loadingCars} />
                <FacetedSelect label='Modeljaar' placeholder='modelyear' options={facets.modelyear} value={modelyear} onChange={setModelyear} disabled={loadingCars} />
                <FacetedSelect label='Transmissie' placeholder='gearbox' options={facets.gearbox} value={gearbox} onChange={setGearbox} disabled={loadingCars} />
                <FacetedSelect label='CC' placeholder='cc' options={facets.cc} value={cc} onChange={setCc} disabled={loadingCars} />
                <FacetedSelect label='Kw' placeholder='kw' options={facets.kw} value={kw} onChange={setKw} disabled={loadingCars} />
                <FacetedSelect label='Co2' placeholder='co2' options={facets.co2} value={co2} onChange={setCo2} disabled={loadingCars} />
              </>
              : null}

          </div>
        </div>
        <div className='ModelModal-results'>
          <div className='ModelModal-results-top'>
            <div className='ModelModal-results-col ModelModal-results-column1'>
              <FacetedSearchSelect label='Merk' placeholder='Merk' options={brandsFacets.brands} value={make} onChange={setMake} />
            </div>
            <div className='ModelModal-results-col ModelModal-results-column2'>
              <FacetedSearchSelect label='Model' placeholder='Model' options={modelsFacets.models} value={model} onChange={setModel} />
            </div>
            <div className='ModelModal-results-col ModelModal-results-column3'>
              <div className='ModelModal-form-title'>Aantal resultaten</div>
              <div className='ModelModal-form-totalResults'>{total}</div>
            </div>
          </div>
          <div id='list-scroll'>
            {loadingCars
              ? <div className='ModelModal-results-list-loading'><Spinner /> Loading</div>
              : <div className='ModelModal-results-list'>
                {cars.map((item, i) => (
                  <div key={i} className={`ModelModal-result-item ${item._id === selectedItem._id ? 'ModelModal-result-item-selected' : ''}`} onClick={() => { handleItemClick(item) }}>
                    <div>{item.version} - {item.modelyear} - {item.gearbox} - {translateFuel(item.fuel)} - {item.kw} Kw{item.co2 ? ` - ${item.co2} CO2` : ''} - {formatCurrency(item.cataloguePrice)}</div>
                  </div>
                ))}
                </div>}
            <div className='ModelModal-results-buttons'>
              {make !== '' && model !== ''
                ? <><Button ghost onClick={onCloseModal}>Annuleren</Button>
                  <Button onClick={handleConfirmClick} disabled={!Object.hasOwn(selectedItem, '_id') || loadingCars}>Kies</Button>
                </>
                : null}
            </div>
          </div>
        </div>
      </div>
    </Modal>
  )
}

export default BrandModelModal
