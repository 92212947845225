import { useState } from 'react'
import Modal from '../../../../components/Modal/Modal.js'
import Input from '../../../../components/Input/Input.js'
import Button from '../../../../components/Button/Button.js'
import Spinner from '../../../../components/Spinner/Spinner.js'
import api from '../../../../api/api.js'
import useCountry from '../../../../hooks/useCountry.js'
import getSha from '../../../../utils/getSha.js'
import showErrorMessage from '../../../../utils/showErrorMessage.js'
import canUseService from '../../../../utils/canUseService.js'
import getErrorMessage from '../../../../utils/getErrorMessage.js'
import getExservice from '../../../../utils/getExservice.js'
import { getInitialDate } from '../../AdHoc.js'

function translateTransmission (value) {
  const translations = {
    0: 'manual',
    1: 'automatic',
    M: 'manual',
    A: 'automatic'
  }
  return translations[value] || value
}

const VinModal = ({ isOpen, onCloseModal, setValues, vinValue, setVinValue }) => {
  const [country] = useCountry('be')
  const [loading, setLoading] = useState(false)

  function handleVinChange (e) {
    if (loading) return
    setVinValue(e.target.value)
  }

  async function handleVinConfirm () {
    if (loading) return
    try {
      setLoading(true)
      const exservice = getExservice({
        vin: vinValue
      })
      const result = await api.getCarInformation({
        exservice,
        vin: vinValue
      }, {
        sha: getSha('ACTUEEL', country.toUpperCase())
      }).json()
      if (!result.code) {
        let registratie
        if (result.firstregistrationdate) {
          registratie = result.firstregistrationdate.split('-').reverse().join('-')
        }
        let opties
        if (result.basiccatalogueprice && result.fullcatalogueprice) {
          opties = result.fullcatalogueprice - result.basiccatalogueprice
        }
        if (!result.basiccatalogueprice && result.fullcatalogueprice) {
          result.basiccatalogueprice = result.fullcatalogueprice
        }
        setValues({
          prijs: (result.basiccatalogueprice || '') + '',
          opties,
          // BPM: result.bpm,
          merk: result.brand,
          cc: (result.cc || '') + '',
          co2: (result.co2 || '') + '',
          // doors: result.doors,
          // energylabel: result.energylabel,
          registratie, // result.firstregistrationdate
          brandstof: result.fuel,
          totaalPrijs: (result.fullcatalogueprice || '') + '',
          vat: result.vat || '',
          pk: (result.hp || '') + '',
          // licenseplate: result.licenseplate,
          km: (result.mileage || '') + '',
          model: result.model,
          modelJaarMonth: result.modelyear ? '7' : '',
          modelJaarYear: result.modelyear || '',
          modelJaar: result.modelyear ? `7-${result.modelyear}` : '',
          herberekendNieuw: result.recalculatedcatalogueprice,
          herberekendNieuwEx: result.recalculatedcataloguepricevatexclusive,
          // seats: result.seats,
          // result.technicalInformation
          transmissie: translateTransmission(result.transmission),
          // vehicletype: result.vehicletype,
          uitvoering: result.version,
          vin: vinValue,
          pabw: 'passengercar',
          aanvang: getInitialDate(),
          periode: '2',
          exs: exservice,
          exsid: result.exsid,
          _isNewVin: true
        })
        onCloseModal()
      } else {
        showErrorMessage(result.message)
      }
    } catch (error) {
      console.error(error)
      showErrorMessage(await getErrorMessage(error))
    } finally {
      setLoading(false)
    }
  }

  const canDoVinLookup = canUseService(['VIN-BMW-ALPHABET', 'EXS05', 'INMOTIV'])

  return (
    <Modal open={isOpen} closeModal={onCloseModal} title="VIN selectie">
      <div className="AdhocInput-modal-body">
        { canDoVinLookup
          ? <>
            <Input label="VIN Nummer" placeholder="VIN Nummer" value={vinValue} onChange={handleVinChange} />
            <div className="AdhocInput-modal-buttons">
              <Button disabled={loading} ghost={true} onClick={onCloseModal}>Annuleren</Button>
              <Button disabled={loading} onClick={handleVinConfirm}>{ loading ? <Spinner color="white" /> : 'Kies' }</Button>
            </div>
          </>
          : <>
            <p>U heeft momenteel geen toegang tot deze service.</p>
            <p>Contacteer Quid Pro Quo indien u verder geholpen wenst te worden.</p>
          </>
        }
      </div>
    </Modal>
  )
}

export default VinModal
