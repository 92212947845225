import './Batch.scss'
import Header from '../../components/Header/Header.js'
import BatchInput from './BatchInput/BatchInput.js'
import BatchChoose from './BatchChoose/BatchChoose.js'
import BatchResult from './BatchResult/BatchResult.js'
import useStore from '../../hooks/useStore.js'

const initialState = {
  uploadLoading: false,
  uploadResponse: null,
  processLoading: false,
  processType: '',
  processResponse: null,
  alreadyProcessedIds: new Set()
}
// const initialState = {
//   uploadLoading: false,
//   uploadResponse: {
//     code: 1,
//     extension: 'csv',
//     id: '123456798',
//     message: 'file analysed',
//     records: 1,
//     size: '379 bytes'
//   },
//   processLoading: false,
//   processType: 'download',
//   processResponse: 'blob',
//   alreadyProcessedIds: new Set()
// }

const Batch = () => {
  const store = useStore(initialState)

  if (!store) return null

  return (
    <div className="Batch">
      <Header page="batch" />
      <div className="Batch-body">
        <BatchInput store={store} />
        <BatchChoose store={store} />
        <BatchResult store={store} />
      </div>
    </div>
  )
}

export default Batch
