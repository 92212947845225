import { useState, useEffect } from 'react'
import Store from '../utils/store.js'

function useStore (initialState) {
  const [store, setStore] = useState(null)

  useEffect(() => {
    setStore(new Store(initialState))
  }, [initialState])

  return store
}

export default useStore
