import getUser from './getUser.js'

const nlServices = new Set(['VWE', 'EXS01', 'EXS02', 'EXS03', 'RDW', 'EXS04'])
const beServices = new Set(['VIN-BMW-ALPHABET', 'EXS05', 'INMOTIV'])

function getExservice ({ licensePlate, vin, type }) {
  const userServices = getUser().exservices
  const userNLServices = new Set(userServices.filter(service => nlServices.has(service)))
  const userBEServices = new Set(userServices.filter(service => beServices.has(service)))

  let service
  switch (true) {
    case !!licensePlate: {
      if (type === 'RDW') {
        service = service || (userNLServices.has('RDW') && 'RDW')
        service = service || (userNLServices.has('EXS04') && 'EXS04')
      }
      if (type === 'VWE') {
        service = service || (userNLServices.has('VWE') && 'VWE')
        service = service || (userNLServices.has('EXS01') && 'EXS01')
        service = service || (userNLServices.has('EXS02') && 'EXS02')
        service = service || (userNLServices.has('EXS03') && 'EXS03')
      }
      break
    }
    case !!vin: {
      service = service || (userBEServices.has('VIN-BMW-ALPHABET') && 'VIN-BMW-ALPHABET')
      service = service || (userBEServices.has('EXS05') && 'EXS05')
      service = service || (userBEServices.has('INMOTIV') && 'INMOTIV')
      break
    }
    default: {
      service = null
    }
  }

  return service
}

export default getExservice
